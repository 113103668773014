import React from 'react';
import ReactDOM from 'react-dom';

export default class LineLocaVente extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            localisation:this.props.localisation,
            config:this.props.config,
            achatVente:this.props.type,
            locaConfigAchat:null,
            locaConfigVente:null,
            locavente:null,
            locaachat:null,
            regions:null,
            region:"",
            depart:null,
            departement:"",
            villes:null,
            ville:"",
            quartiers:null,
            quartier:"",
            departOpen:false,
            villeOpen:false,
            quartierOpen:false,
            id:null,
        }
    };
    componentDidMount() {
        const {config} = this.state;
            this.setState({locaConfigVente:JSON.parse(config.locaConfigVente)},() => {
                if(this.state.locaConfigVente.region){
                    axios.post('/loca-vente-region').then(res => {
                        this.setState({regions: res.data});
                    }).catch(error => {

                    })
                }else{
                    if(this.state.locaConfigVente.departement){
                        axios.post('/loca-vente-depart').then(res => {
                            this.setState({depart: res.data,departOpen:true});
                        }).catch(error => {

                        })
                    }else{
                        axios.post('/loca-vente-ville').then(res => {
                            let autre = res.data.filter(d => d.ville === "Autre");
                            if(autre[0]){
                                let loca = res.data.filter(d => d.ville !== "Autre");
                                let newAutre = {ville: "Autre"};
                                loca.push(newAutre)
                                this.setState({villes: loca,villeOpen:true});
                            }else{
                                this.setState({villes: res.data,villeOpen:true});
                            }
                        }).catch(error => {

                        })
                    }
                }

            });
            if(this.state.localisation){
            if(this.state.localisation[0] !== undefined){
                this.setState({
                    region:this.state.localisation[0].region,
                    departement:this.state.localisation[0].departement,
                    ville:this.state.localisation[0].ville,
                    quartier:this.state.localisation[0].quartier,
                    departOpen:true,
                    villeOpen:true,
                    quartierOpen:true,
                },() => {
                    axios.post('/loca-vente-depart-select',{
                        region:this.state.region
                    }).then(res => {
                        this.setState({depart: res.data,departOpen:true},() => {
                            axios.post('/loca-vente-ville-select',{
                                depart:this.state.departement
                            }).then(res => {
                                this.setState({villes: res.data,villeOpen:true},() => {
                                    axios.post('/loca-quartier',{
                                        ville:this.state.ville,
                                        type:this.state.achatVente,
                                    }).then(res => {
                                        this.setState({quartiers: res.data[0] ? res.data : null ,quartierOpen:true});
                                    }).catch(error => {

                                    })
                                });
                            }).catch(error => {

                            })
                        });
                    }).catch(error => {

                    })
                })
            }
        }
    };
    handleDepart = (value) => {
        this.setState({
            region:value
        },() => {
            axios.post('/loca-vente-depart-select',{
                region:value
            }).then(res => {
                this.setState({depart: res.data,departOpen:true});
            }).catch(error => {

            })
        });
    };
    handleVille = (value) =>{
        this.setState({
            departement:value
        },() => {
            axios.post('/loca-vente-ville-select',{
                depart:value
            }).then(res => {
                let autre = res.data.filter(d => d.ville === "Autre");
                if(autre[0]){
                    let loca = res.data.filter(d => d.ville !== "Autre");
                    let newAutre = {ville: "Autre"};
                    loca.push(newAutre)
                    this.setState({villes: loca,villeOpen:true});
                }else{
                    this.setState({villes: res.data,villeOpen:true});
                }
            }).catch(error => {

            })
        });
    };
    handleQuartier = (value) =>{
        this.setState({
            ville:value,
            quartierOpen:false
        },() => {
            if(this.state.locaConfigVente.quartier ){
                axios.post('/loca-quartier',{
                    ville:value,
                    type:this.state.achatVente,
                }).then(res => {
                    this.setState({quartiers: res.data[0] ? res.data : null ,quartierOpen:true},() => {
                        this.handleSave();
                    });
                }).catch(error => {

                })
            }else{
                this.handleSave();
            }
        });
    };
    handleSelectFinal = (value) => {
        this.setState({
            quartier:value,
        },() => {
            this.handleSave();
        })
    };
    handleSave = () => {
        let vente = {
            region: this.state.region,
            departement: this.state.departement,
            ville: this.state.ville,
            quartier: this.state.quartier
        };
        this.setState({
            localisation:vente
        },() => {
            this.props.handleOnTab(this.state.localisation);
        })
    };
    render(){
        return(
            <>
                {
                    this.state.locaConfigVente !== null &&
                    this.state.locaConfigVente.mini ?
                        <div className="form ">
                            <h2>Où se situe votre bien ?</h2>
                            <div className='block full'>
                            {
                                this.state.locaConfigVente !== null &&
                                this.state.locaConfigVente.region &&
                                <div className="selectBlock">
                                <select onChange={(e) => this.handleDepart(e.target.value)}>
                                    <option>Region</option>
                                    {
                                        this.state.regions !== null &&
                                        this.state.regions.map((value,index) => (
                                            <option key={index} value={value.region} selected={this.state.region === value.region ? "selected" : false}>{value.region}</option>
                                        ))
                                    }
                                </select>
                                </div>
                            }
                            {
                                this.state.locaConfigVente !== null &&
                                this.state.locaConfigVente.departement &&
                                this.state.departOpen &&
                                <div className="selectBlock">
                                <select onChange={(e) => this.handleVille(e.target.value)}>
                                    <option value="">Département</option>
                                    {
                                        this.state.depart !== null &&
                                        this.state.depart.map((value,index) => (
                                            <option key={index} value={value.departement} selected={this.state.departement === value.departement ? "selected" : false}>{value.departement}</option>
                                        ))
                                    }
                                </select>
                                </div>
                            }
                            {
                                this.state.villeOpen &&
                                <div className="selectBlock">
                                <select name="" id="" onChange={(e) => this.handleQuartier(e.target.value)}>
                                        <option value="">Ville</option>
                                        {
                                            this.state.villes !== null &&
                                            this.state.villes.map((value,index) => (
                                                <option key={index} value={value.ville} selected={this.state.ville === value.ville ? "selected" : false} >{value.ville}</option>
                                            ))
                                        }
                                    </select>
                                    </div>
                            }

                            {
                                this.state.locaConfigVente !== null &&
                                this.state.locaConfigVente.quartier &&
                                this.state.quartierOpen &&
                                this.state.quartiers !== null &&
                                this.state.quartiers.length > 1 &&
                                <div className="selectBlock">
                                    <select onChange={(e) => this.handleSelectFinal(e.target.value)}>
                                        <option value="">Quartier</option>
                                        {

                                            this.state.quartiers.map((value,index) => (
                                                <option key={index} value={value.quartier} selected={this.state.quartier === value.quartier ? "selected" : false}>{value.quartier}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            }
                            </div>
                        </div>
                        :
                        <div className="form">
                            <h2>Où se situe votre bien ?</h2>
                            <div className="block">
                        {
                            this.state.villeOpen &&
                            this.state.locaConfigVente &&
                            this.state.locaConfigVente.choix &&
                            <>
                                {
                                    this.state.locaConfigVente.choix.choix1 !== null &&

                                    <div
                                    className={this.state.ville !== null ? this.state.ville === this.state.locaConfigVente.choix.choix1 ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}
                                    onClick={() => this.handleQuartier(this.state.locaConfigVente.choix.choix1)}>
                                        {this.state.locaConfigVente.choix.choix1}
                                    </div>
                                }
                                {
                                    this.state.locaConfigVente.choix.choix2 !== null &&
                                    <div className={this.state.ville !== null ? this.state.ville === this.state.locaConfigVente.choix.choix2 ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}
                                    onClick={() => this.handleQuartier(this.state.locaConfigVente.choix.choix2)}>
                                        {this.state.locaConfigVente.choix.choix2}
                                    </div>
                                }
                                {
                                    this.state.locaConfigVente.choix.choix3 !== null &&
                                    <div
                                    className={this.state.ville !== null ? this.state.ville === this.state.locaConfigVente.choix.choix3 ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}
                                    onClick={() => this.handleQuartier(this.state.locaConfigVente.choix.choix3)}>
                                        {this.state.locaConfigVente.choix.choix3}
                                    </div>
                                }

                            </>

                        }
                        </div>
                    </div>
                }
            </>
        )
    }
}
