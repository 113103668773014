import React from "react";
import ReactDOM from 'react-dom';

import axios from "axios";
import LineLocaAchat from "./localisation/LineLocaAchat";

export default class FormProjetAchat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: JSON.parse(this.props.config),
            caracs: JSON.parse(this.props.caracs),
            delais: JSON.parse(this.props.delais),
            types: JSON.parse(this.props.types),
            travaux: JSON.parse(this.props.travaux),
            lead:JSON.parse(this.props.lead),
            chemin:this.props.chemin,
            part1: 1,
            part2: 0,
            part3: 0,
            part4: 0,
            part5: 0,
            part6: 0,
            titre: "",
            description: "",
            delai: null,
            regions: null,
            departements: null,
            villes: null,
            quartiers: null,
            region: null,
            departement: null,
            ville: null,
            quartier: null,
            type: null,
            carac: [],
            appartCheckSurfaceExt: false,
            surfaceBienMin: "",
            surfaceBienMax: "",
            errorSurfBienMax: false,
            surfaceExtMin: "",
            surfaceExtMax: "",
            errorSurfExtMax: false,
            nbPiece: "",
            nbCham: "",
            tabCham: [],
            messageFlashChambre: null,
            nbSdb: "",
            prixMin: "",
            prixMax: "",
            errorPrixMax: false,
            email: "",
            password: "",
            messageFlash: "",
            openPrix: true,
            presentation: "",
            countArea: 0,
            etage: "",
            etageTotal: "",
            contextProjet: null,
            tab: [],
            i: 1,
            nb: [1],
            etat: null,
            travau: null,
            emailNotValide:false,
            id:0,
            localisation:[],
            line:[1],
            firstHandleOntab:false,
            phone:"",
        }
        this.handleSelectTiming = this.handleSelectTiming.bind(this);
        this.handleGotoLocal = this.handleGotoLocal.bind(this);
        this.handleSelectRegion = this.handleSelectRegion.bind(this);
        this.handleSelectDepartement = this.handleSelectDepartement.bind(this);
        this.handleSelectVille = this.handleSelectVille.bind(this);
        this.handleSelectQuartier = this.handleSelectQuartier.bind(this);
        this.handleSelectType = this.handleSelectType.bind(this);
        this.handleSelectCarac = this.handleSelectCarac.bind(this);
        this.handleSurfaceBienMin = this.handleSurfaceBienMin.bind(this);
        this.handleSurfaceBienMax = this.handleSurfaceBienMax.bind(this);
        this.handleSelectNbPiece = this.handleSelectNbPiece.bind(this);
        this.handleSelectNbChambre = this.handleSelectNbChambre.bind(this);
        this.handleSelectNbSdb = this.handleSelectNbSdb.bind(this);
        this.handlePrixMin = this.handlePrixMin.bind(this);
        this.handlePrixMax = this.handlePrixMax.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChangePresentation = this.handleChangePresentation.bind(this);
        this.handleAddContextProjet = this.handleAddContextProjet.bind(this);
        this.addLineSelectAchat = this.addLineSelectAchat.bind(this);
        this.handleAddEtatBien = this.handleAddEtatBien.bind(this);
        this.handleAddTravaux = this.handleAddTravaux.bind(this);
    }
    componentDidMount(){
        const {config} = this.state;
        if(config.achatAchat === 1 && config.location === 0){
            this.setState({
                contextProjet:"Acheter"
            })
        }else if(config.achatAchat === 0 && config.location === 1){
            this.setState({
                contextProjet:"Louer"
            })
        }
    }
    /*
    handleOnTab(value,index) {
        let {tab} = this.state;
        tab[index] = value;
        this.setState({tab});
    }
    */

    handleSelectTiming(value) {
        this.setState({
            delai: value
        });
    }

    handleGotoLocal(e, value) {
        e.preventDefault();
        switch (value) {
            case "timing":
                this.setState({part1: 1, part2: 0, part3: 0, part4: 0, part5: 0, part6: 0}, () => {
                    window.scrollTo(0, 0)
                })
                break;
            case "local":
                if (this.state.delai !== null && this.state.contextProjet !== null) {
                    axios.post('/location/region', {
                        value: "region",
                    }).then(res => {
                        this.setState({regions: res.data}, () => {
                            this.setState({
                                part1: 2,
                                part2: 1,
                                part3: 0,
                                part4: 0,
                                part5: 0,
                                part6: 0,
                                messageFlash: ""
                            }, () => {
                                window.scrollTo(0, 0)
                            });
                        });
                    }).catch(error => {

                    })
                } else {
                    this.setState({messageFlash: "Veuillez faire un choix."}, () => {
                        window.scrollTo(0, 0)
                    })
                }
                break;
            case "type":
                if (this.state.localisation[0]) {
                    this.setState({
                        part1: 2,
                        part2: 2,
                        part3: 1,
                        part4: 0,
                        part5: 0,
                        part6: 0,
                        messageFlash: ""
                    }, () => {
                        window.scrollTo(0, 0)
                    })
                } else {
                    this.setState({messageFlash: "Veuillez sélectionner une ville."}, () => {
                        window.scrollTo(0, 0)
                    })
                }
                break;
            case "carac":
                if (this.state.type !== null) {
                    this.setState({
                        part1: 2,
                        part2: 2,
                        part3: 2,
                        part4: 1,
                        part5: 0,
                        part6: 0,
                        messageFlash: ""
                    }, () => {
                        window.scrollTo(0, 0)
                    })
                } else {
                    this.setState({messageFlash: "Veuillez faire un choix."}, () => {
                        window.scrollTo(0, 0)
                    })
                }
                break;
            case "sup":
                this.setState({
                    part1: 2,
                    part2: 2,
                    part3: 2,
                    part4: 2,
                    part5: 1,
                    part6: 0,
                    messageFlash: ""
                }, () => {
                    if (this.state.type === "Appartement") {
                        if (this.state.carac.indexOf("Terrasse") !== -1 || this.state.carac.indexOf("Balcon") !== -1 || this.state.carac.indexOf("Jardin") !== -1) {
                            this.setState({appartCheckSurfaceExt: true})
                        } else {
                            this.setState({appartCheckSurfaceExt: false})
                        }
                    }
                    window.scrollTo(0, 0)
                })
                break;
            case "recap":
                if(this.state.chemin === "entreprise"){
                    if (this.state.surfaceBienMin !== ""){
                        this.setState({
                            part1: 2,
                            part2: 2,
                            part3: 2,
                            part4: 2,
                            part5: 2,
                            part6: 1,
                            messageFlash: ""
                        }, () => {
                            window.scrollTo(0, 0)
                        })
                    }else{
                        this.setState({messageFlash: "Veuillez remplir la surface du bien."}, () => {
                            window.scrollTo(0, 0)
                        });
                    }
                }else{
                    if (this.state.type !== "Terrain") {
                        if (this.state.etage <= this.state.etageTotal) {
                            if (this.state.surfaceBienMin !== "" && this.state.nbPiece !== "") {
                                this.setState({
                                    part1: 2,
                                    part2: 2,
                                    part3: 2,
                                    part4: 2,
                                    part5: 2,
                                    part6: 1,
                                    messageFlash: ""
                                }, () => {
                                    window.scrollTo(0, 0)
                                })

                            } else {
                                this.setState({messageFlash: "Veuillez remplir la surface et le nombre de pièces du bien."}, () => {
                                    window.scrollTo(0, 0)
                                });
                            }
                        } else {
                            this.setState({messageFlash: "L'étage doit être inférieur à l'étage total."}, () => {
                                window.scrollTo(0, 0)
                            });
                        }
                    } else {
                        this.setState({
                            part1: 2,
                            part2: 2,
                            part3: 2,
                            part4: 2,
                            part5: 2,
                            part6: 1,
                            messageFlash: "",
                            surfaceBienMin: "",
                            surfaceBienMax: "",
                            nbPiece: "",
                            nbCham: "",
                            nbSdb: "",
                        }, () => {
                            window.scrollTo(0, 0)
                        })
                    }
                }
                break;
        }
    }

    handleSelectRegion(value) {
        axios.post('/location/departement', {
            region: value,
        }).then(res => {
            this.setState({departements: res.data, region: value});
        }).catch(error => {

        })
    }

    handleSelectDepartement(value) {
        axios.post('/location/ville', {
            departement: value,
        }).then(res => {
            this.setState({villes: res.data, departement: value});
        }).catch(error => {

        })
    }

    handleSelectVille(value) {
        axios.post('/location/quartier', {
            ville: value,
        }).then(res => {
            this.setState({quartiers: res.data, ville: value});
        }).catch(error => {

        })
    }

    handleSelectQuartier(value) {
        this.setState({quartier: value});
    }

    handleSelectType(value) {
        this.setState({type: value});
    }

    handleSelectCarac(value) {
        let {carac} = this.state;
        if (carac.indexOf(value) !== -1) {
            const i = carac.indexOf(value);
            carac.splice(i, 1);
            if (carac.indexOf('Balcon') === -1 && carac.indexOf('Térrasse') === -1 && carac.indexOf('Jardin') === -1) {
                this.setState({
                    surfaceExtMin: "",
                    surfaceExtMax: ""
                });
            }
        } else {
            carac.push(value)

        }
        this.setState({carac: carac});
    }

    handleSurfaceBienMin(value) {
        this.setState({
            surfaceBienMin: value,
            surfaceBienMax: value,
        })
    }

    handleSurfaceBienMax(value) {
        const {surfaceBienMin} = this.state;
        if (parseInt(surfaceBienMin) < parseInt(value)) {
            this.setState({errorSurfBienMax: false});
        } else {
            this.setState({
                errorSurfBienMax: true
            })
        }
        this.setState({surfaceBienMax: value})
    }

    handleSurfaceExtMin(value) {
        this.setState({
            surfaceExtMin: value,
            surfaceExtMax: value,
        })
    }

    handleSurfaceExtMax(value) {
        const {surfaceExtMin} = this.state;
        if (parseInt(surfaceExtMin) < parseInt(value)) {
            this.setState({errorSurfExtMax: false});
        } else {
            this.setState({
                errorSurfExtMax: true
            })
        }
        this.setState({surfaceExtMax: value})
    }

    handleSelectNbPiece(value) {
        if (value !== "Studio" && value !== "+5") {
            let tabCham = [];
            for (let i = 1; i < parseInt(value); i++) {
                tabCham.push(i);
            }
            this.setState({nbPiece: value, tabCham: tabCham});
        } else {
            let tabCham = [];
            this.setState({nbPiece: value, tabCham: tabCham});
        }
    }

    handleSelectNbChambre(value) {
        const {nbPiece} = this.state;
        if (nbPiece == 1) {
            this.setState({nbCham: value, messageFlashChambre: null});
        } else if (nbPiece === "+5") {
            this.setState({nbCham: value, messageFlashChambre: null});
        } else {
            if (nbPiece > value) {
                this.setState({nbCham: value, messageFlashChambre: null});
            } else {
                this.setState({messageFlashChambre: "Le nombre de chambre doit être inferieur au nombre de pièce."});
            }
        }
    }

    handleSelectNbSdb(value) {
        this.setState({nbSdb: value});
    }

    handlePrixMin(value) {
        this.setState({
            prixMin: value,
            prixMax: value,
        })
    }

    handlePrixMax(value) {
        this.setState({prixMax: value})
    }

    handleOpenPrix(value) {
        if (value === "oui") {
            this.setState({openPrix: true})
        } else {
            this.setState({
                openPrix: true, prixMin: "",
                prixMax: ""
            })
        }
    }

    handleChangePresentation(value) {
        if (value.length <= 400) {
            this.setState({presentation: value});
        }
    }

    async handleSave(e) {
        e.preventDefault();
        let btn = document.querySelector("#addProjet");
        btn.textContent = "Validation en cours";
        btn.style.transition = '0ms';
        btn.style.width = '185px';
        btn.style.cursor = 'not-allowed';
        let {
            email,
            password,
            delai,
            carac,
            type,
            region,
            departement,
            ville,
            quartier,
            nbCham,
            nbPiece,
            nbSdb,
            prixMax,
            prixMin,
            surfaceBienMin,
            surfaceBienMax,
            surfaceExtMin,
            surfaceExtMax,
            presentation,
            contextProjet,
            etat,
            travau,
            localisation,
            chemin,
            lead,
            phone,
        } = this.state;
        let titre =  type+ " "+ localisation[0].ville;
        if(password.length < 8){
            this.setState({messageFlash: "Le mot de passe doit contenir 8 charactéres minium."});
        }else{
        if (this.validateEmail(email)) {
            let piece = 0;
            if(nbPiece === "Studio"){
                piece = 0;
            }else if(nbPiece !== "+5"){
                piece = parseInt(nbPiece);
            }else{
                piece = 5;
            }
            if(ville !== null){
            axios.post('/annoncer-votre-projet-d-achat-de-bien-immobillier/valider/achat', {
                chemin:chemin,
                email: email,
                tr: password,
                delai: delai,
                caracteristique: carac,
                type: type,
                localisation:localisation,
                chambre: nbCham,
                piece: piece,
                sdb: nbSdb,
                budgetMin: prixMin,
                budgetMax: prixMax,
                surfaceMin: surfaceBienMin,
                surfaceMax: surfaceBienMax,
                terrainMin: surfaceExtMin,
                terrainMax: surfaceExtMax,
                presentation: presentation,
                titre: titre,
                contextProjet: contextProjet,
                travaux: travau,
                etat: etat,
                region:region,
                departement:departement,
                ville:ville,
                quartier:quartier,
                lead_user_id:lead.id?parseInt(lead.id):0,
                lead_type:lead.type? lead.type : "classique",
                typeProjet:"achat",
                telephone:phone,
            }).then(res => {
                if(res.data === "email deja utiliser"){
                    this.setState({emailNotValide:true});
                }else{
                if (res.data === 1) {
                    this.setState({messageFlash: ""}, () => {
                        document.location.replace('/');
                    })
                } else {
                    this.setState({messageFlash: "Un probléme est survenu lors de l'enregistrement."})
                }
            }
            }).catch(error => {
                console.log(error);
            })
        }else{
            this.setState({messageFlash: "Veuillez selectionner une ville."});
        }
        } else {
            this.setState({messageFlash: "Le format de votre adresse e-mail n'est pas valide."})
        }
    }
    }

    validateEmail(email) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/
        );
    };

    handleAddContextProjet(value) {
        this.setState({contextProjet: value});
    }

    addLineSelectAchat(e) {
        e.preventDefault()
        let {nb, i} = this.state;
        nb.push(1);
        i++;
        this.setState({nb, i})
    }

    handleAddEtatBien(value) {
        if(value === "Neuf"){
            this.setState({etat: value,travau:null});
        }else{
            this.setState({etat: value});
        }
    }

    handleAddTravaux(value) {
        this.setState({travau: value});
    }

    handleAddLine = (e) => {
        e.preventDefault();
        let {line,id} = this.state;
        line.push(1);
        id++;
        this.setState({line,id});
    }
    handleOnTab = (value,id) => {
        let {localisation} = this.state;
        localisation = value;
        this.setState({
            localisation,
            ville:value.ville,
        });
    }
    handleRetourSelect = () => {
        const {config} = this.state;
        if(config.vente === 1){
            window.location.assign('/annoncer-votre-projet-de-vente-de-bien-immobilier');
        }else{
            window.location.assign('/');
        }
    }
    render() {
        let {part1, part2, part3, part4, part5, part6} = this.state;
        return (
            <div className="formulaire">
                <div className="tunnel">
                    <ul>
                        <li className={part1 === 1 ? "selected tunel" : part1 === 2 ? "finish tunel" : "tunel"}>
                            <div className="blockNb">
                                <p>1</p>
                            </div>
                            <p>Timing</p>
                        </li>
                        <li className={part1 === 2 ? "hrline finish" : "hrline"}>
                            <hr/>
                        </li>
                        <li className={part2 === 1 ? "selected tunel" : part2 === 2 ? "finish tunel" : "tunel"}>
                            <div className="blockNb">
                                <p>2</p>
                            </div>
                            <p>Localisation</p>
                        </li>
                        <li className={part2 === 2 ? "hrline finish" : "hrline"}>
                            <hr/>
                        </li>
                        <li className={part3 === 1 ? "selected tunel" : part3 === 2 ? "finish tunel" : "tunel"}>
                            <div className="blockNb">
                                <p>3</p>
                            </div>
                            <p>Type de bien</p>
                        </li>
                        <li className={part3 === 2 ? "hrline finish" : "hrline"}>
                            <hr/>
                        </li>
                        <li className={part4 === 1 ? "selected tunel" : part4 === 2 ? "finish tunel" : "tunel"}>
                            <div className="blockNb">
                                <p>4</p>
                            </div>
                            <p>Caractéristiques</p>
                        </li>
                        <li className={part4 === 2 ? "hrline finish" : "hrline"}>
                            <hr/>
                        </li>
                        <li className={part5 === 1 ? "selected tunel" : part5 === 2 ? "finish tunel" : "tunel"}>
                            <div className="blockNb">
                                <p>5</p>
                            </div>
                            <p>Superficie et prix</p>
                        </li>
                        <li className={part5 === 2 ? "hrline finish" : "hrline"}>
                            <hr/>
                        </li>
                        <li className={part6 === 1 ? "selected tunel" : part6 === 2 ? "finish tunel" : "tunel"}>
                            <div className="blockNb">
                                <p>6</p>
                            </div>
                            <p>Récapitulatif</p>
                        </li>
                    </ul>
                </div>
                {
                    this.state.messageFlash !== "" &&
                    <div className="alert alert-warning">
                        <p>{this.state.messageFlash}</p>
                    </div>
                }
                <div className="ecran">
                    {
                        part1 === 1 &&
                        <>
                        {
                            this.state.config.achatAchat === 1 && this.state.config.location === 1 ?
                            <div className="form">
                                <h2>Souhaitez-vous Acheter ou Louer ?</h2>
                                <div className="block">
                                    <div onClick={() => this.handleAddContextProjet('Acheter')}
                                         className={this.state.contextProjet !== null ? this.state.contextProjet === "Acheter" ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}>
                                        Acheter
                                    </div>
                                    <div onClick={() => this.handleAddContextProjet('Louer')}
                                         className={this.state.contextProjet !== null ? this.state.contextProjet === "Louer" ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}>
                                        Louer
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="form">
                                <h2>Vous voulez {this.state.contextProjet} !</h2>
                            </div>

                        }
                            <div className="form">

                                <h2>A quel horizon souhaitez-vous avoir trouvé votre bien ?</h2>
                                <div className="block">
                                    {
                                        this.state.delais.map((value, index) => (
                                            <div key={index}
                                                 className={this.state.delai !== null ? this.state.delai === value.name ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}
                                                 onClick={() => this.handleSelectTiming(value.name)}>
                                                {value.name}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="action">
                                <a href="/" className="gray" onClick={this.handleRetourSelect} >Retour</a>
                                <a href="" onClick={(e) => this.handleGotoLocal(e, 'local')}>Suivant <span>{">"}</span></a>
                            </div>
                        </>
                    }
                    {
                        part2 === 1 &&
                        <>
                            <div className="form localisation">
                            <h2>Où se situe votre recherche ?</h2>
                            {
                                    this.state.line.map((value,index) => (
                                        <div key={index} >
                                            <LineLocaAchat config={this.state.config} type="Achat" id={index} localisation={this.state.localisation} handleOnTab={this.handleOnTab}/>
                                        </div>
                                    ))
                                }

                                <a href="" className="addLine" onClick={(e) => this.handleAddLine(e)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.013" height="15.013" viewBox="0 0 15.013 15.013">
                                        <g id="Icon_ionic-md-add-circle-outline" data-name="Icon ionic-md-add-circle-outline" transform="translate(-3.375 -3.375)">
                                            <path id="Tracé_8" data-name="Tracé 8" d="M18.2,15.1H15.1v3.1H13.58V15.1h-3.1V13.58h3.1v-3.1H15.1v3.1h3.1Z" transform="translate(-3.457 -3.457)" fill="#2f2f2f"/>
                                            <path id="Tracé_9" data-name="Tracé 9" d="M10.881,4.891A5.988,5.988,0,1,1,6.645,6.645a5.966,5.966,0,0,1,4.237-1.754m0-1.516a7.506,7.506,0,1,0,7.506,7.506,7.505,7.505,0,0,0-7.506-7.506Z" fill="#2f2f2f"/>
                                        </g>
                                    </svg>
                                    <span>Ajouter</span>
                                </a>
                                <div className="action">
                                    <a href="" className="gray"
                                       onClick={(e) => this.handleGotoLocal(e, 'timing')}>{`< Retour`}</a>
                                    <a href="" onClick={(e) => this.handleGotoLocal(e, 'type')}>Suivant</a>
                                </div>

                            </div>
                        </>
                    }
                    {
                        part3 === 1 &&
                        <>
                            <div className="form">
                                <h2>Quel type de bien recherchez vous?</h2>
                                <div className="block">

                                    {
                                        this.state.types.map((value, index) => (
                                            <div key={index}
                                                 className={this.state.type !== null ? this.state.type === value.name ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}
                                                 onClick={() => this.handleSelectType(value.name)}>
                                                {value.name}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="action">
                                <a href="" className="gray"
                                   onClick={(e) => this.handleGotoLocal(e, 'local')}><span>{"<"}</span> Retour</a>
                                <a href="" onClick={(e) => this.handleGotoLocal(e, 'carac')}>Suivant <span>{">"}</span></a>
                            </div>
                        </>
                    }
                    {
                        part4 === 1 &&
                        <>
                            <div className="form">
                                <h2>Quelles sont les caractéristiques du bien recherché ?</h2>
                                <div className="block">
                                    {
                                        this.state.caracs.map((value, index) => (

                                            <div key={index}
                                                 className={this.state.carac.indexOf(value.name) != -1 ? "selected btn formbtn" : "btn formbtn"}
                                                 onClick={() => this.handleSelectCarac(value.name)}>
                                                {value.name}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="action">
                                <a href="" className="gray"
                                   onClick={(e) => this.handleGotoLocal(e, 'type')}><span>{"<"}</span> Retour</a>
                                <a href=""
                                   onClick={(e) => this.handleGotoLocal(e, 'sup')}>Suivant <span>{">"}</span></a>
                            </div>
                        </>
                    }
                    {
                        part5 === 1 &&
                        <>
                            <div className="form">
                                <h2>Superficie et prix du bien recherché ?</h2>
                                {
                                    this.state.type !== "Terrain" &&
                                    <div className="formblock">
                                        <label htmlFor="">Surface du bien</label>
                                        <input type="number" placeholder="Surface min. m²"
                                               value={this.state.surfaceBienMin}
                                               onChange={(e) => this.handleSurfaceBienMin(e.target.value)}/>
                                        <input type="number" placeholder="Surface max. m²"
                                               value={this.state.surfaceBienMax}
                                               onChange={(e) => this.handleSurfaceBienMax(e.target.value)}/>
                                        {
                                            this.state.errorSurfBienMax &&
                                            <div className="message">
                                                La surface max. doit être supérieur à la surface min.
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    this.state.chemin !== "entreprise" &&
                                    <>
                                        {
                                            this.state.type !== "Appartement" ?

                                                <div className="formblock">
                                                    <label htmlFor="">Surface des extérieurs</label>
                                                    <input type="number" placeholder="Surface min. m²"
                                                        value={this.state.surfaceExtMin}
                                                        onChange={(e) => this.handleSurfaceExtMin(e.target.value)}/>
                                                    <input type="number" placeholder="Surface max. m²"
                                                        value={this.state.surfaceExtMax}
                                                        onChange={(e) => this.handleSurfaceExtMax(e.target.value)}/>
                                                    {
                                                        this.state.errorSurfExtMax &&
                                                        <div className="message">
                                                            La surface max. doit être supérieur à la surface min.
                                                        </div>
                                                    }
                                                </div>
                                                :
                                                this.state.appartCheckSurfaceExt ?
                                                    <div className="formblock">
                                                        <label htmlFor="">Surface des extérieurs</label>
                                                        <input type="number" placeholder="Surface min. m²"
                                                            value={this.state.surfaceExtMin}
                                                            onChange={(e) => this.handleSurfaceExtMin(e.target.value)}/>
                                                        <input type="number" placeholder="Surface max. m²"
                                                            value={this.state.surfaceExtMax}
                                                            onChange={(e) => this.handleSurfaceExtMax(e.target.value)}/>
                                                        {
                                                            this.state.errorSurfExtMax &&
                                                            <div className="message">
                                                                La surface max. doit être supérieur à la surface min.
                                                            </div>
                                                        }
                                                    </div>
                                                    : null
                                        }
                                        {
                                            this.state.type !== "Terrain" &&

                                            <div className="formblock disflex">
                                                <label htmlFor="">Nombre de pièce(s)</label>
                                                <div className="blockNbPiece">
                                                    <div
                                                        className={this.state.nbPiece === "Studio" ? "nbpiece selected" : "nbpiece"}
                                                        onClick={() => this.handleSelectNbPiece("Studio")}>Studio
                                                    </div>
                                                    <div className={this.state.nbPiece === "1" ? "nbpiece selected" : "nbpiece"}
                                                        onClick={() => this.handleSelectNbPiece("1")}>1
                                                    </div>
                                                    <div className={this.state.nbPiece === "2" ? "nbpiece selected" : "nbpiece"}
                                                        onClick={() => this.handleSelectNbPiece("2")}>2
                                                    </div>
                                                    <div className={this.state.nbPiece === "3" ? "nbpiece selected" : "nbpiece"}
                                                        onClick={() => this.handleSelectNbPiece("3")}>3
                                                    </div>
                                                    <div className={this.state.nbPiece === "4" ? "nbpiece selected" : "nbpiece"}
                                                        onClick={() => this.handleSelectNbPiece("4")}>4
                                                    </div>
                                                    <div
                                                        className={this.state.nbPiece === "+5" ? "nbpiece selected" : "nbpiece"}
                                                        onClick={() => this.handleSelectNbPiece("+5")}>+5
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.type !== "Terrain" ?
                                                this.state.nbPiece !== "" && this.state.nbPiece !== "Studio" ?

                                                    this.state.nbPiece !== "+5" ?
                                                        this.state.nbPiece === "1" ?
                                                            <>
                                                                <div className="formblock disflex">
                                                                    <label htmlFor="">Nombre de chambre(s)</label>
                                                                    <div className="blockNbPiece">
                                                                        <div
                                                                            className={this.state.nbCham === "1" ? "nbpiece selected" : "nbpiece"}
                                                                            onClick={() => this.handleSelectNbChambre("1")}>1
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.state.messageFlashChambre !== null &&
                                                                    <div className="message displaynone">
                                                                        {this.state.messageFlashChambre}
                                                                    </div>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <div className="formblock disflex">
                                                                    <label htmlFor="">Nombre de chambre(s)</label>
                                                                    <div className="blockNbPiece">
                                                                        {
                                                                            this.state.tabCham.map((value, index) => (
                                                                                <div key={index}
                                                                                    className={this.state.nbCham === index + 1 ? "nbpiece selected" : "nbpiece"}
                                                                                    onClick={() => this.handleSelectNbChambre(index + 1)}>{index + 1}</div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    this.state.messageFlashChambre !== null &&
                                                                    <div className="message displaynone">
                                                                        {this.state.messageFlashChambre}
                                                                    </div>
                                                                }
                                                            </>
                                                        :
                                                        <>
                                                            <div className="formblock disflex">
                                                                <label htmlFor="">Nombre de chambre(s)</label>
                                                                <div className="blockNbPiece">
                                                                    <div
                                                                        className={this.state.nbCham === "1" ? "nbpiece selected" : "nbpiece"}
                                                                        onClick={() => this.handleSelectNbChambre("1")}>1
                                                                    </div>
                                                                    <div
                                                                        className={this.state.nbCham === "2" ? "nbpiece selected" : "nbpiece"}
                                                                        onClick={() => this.handleSelectNbChambre("2")}>2
                                                                    </div>
                                                                    <div
                                                                        className={this.state.nbCham === "3" ? "nbpiece selected" : "nbpiece"}
                                                                        onClick={() => this.handleSelectNbChambre("3")}>3
                                                                    </div>
                                                                    <div
                                                                        className={this.state.nbCham === "4" ? "nbpiece selected" : "nbpiece"}
                                                                        onClick={() => this.handleSelectNbChambre("4")}>4
                                                                    </div>
                                                                    <div
                                                                        className={this.state.nbCham === "+5" ? "nbpiece selected" : "nbpiece"}
                                                                        onClick={() => this.handleSelectNbChambre("+5")}>+5
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.messageFlashChambre !== null &&
                                                                <div className="message displaynone">
                                                                    {this.state.messageFlashChambre}
                                                                </div>
                                                            }

                                                        </>
                                                    :
                                                    null
                                                : null
                                        }
                                        {
                                            this.state.type !== "Terrain" &&
                                            this.state.nbPiece !== "" &&
                                            this.state.nbPiece !== "Studio" &&
                                            this.state.nbPiece !== "1" &&
                                            <div className="formblock disflex">
                                                <label htmlFor="">Nombre de salle(s) de bain</label>
                                                <div className="blockNbPiece">
                                                    {
                                                        this.state.nbPiece !== "+5" ?
                                                        this.state.nbPiece === "1" ?
                                                    <div  className={this.state.nbSdb == 1 ? "nbpiece selected" : "nbpiece"}
                                                         onClick={() => this.handleSelectNbSdb("1")}>1
                                                    </div>
                                                :
                                                            this.state.tabCham.map((value, index) => (
                                                                <div key={index}
                                                                    className={this.state.nbSdb == (index + 1) ? "nbpiece selected" : "nbpiece"}
                                                                    onClick={() => this.handleSelectNbSdb(index + 1)}>{index + 1 == 5 ? "+5" : index + 1}
                                                                </div>
                                                            ))
                                                            :
                                                            <>
                                                                <div
                                                                    className={this.state.nbSdb === "1" ? "nbpiece selected" : "nbpiece"}
                                                                    onClick={() => this.handleSelectNbSdb("1")}>1
                                                                </div>
                                                                <div
                                                                    className={this.state.nbSdb === "2" ? "nbpiece selected" : "nbpiece"}
                                                                    onClick={() => this.handleSelectNbSdb("2")}>2
                                                                </div>
                                                                <div
                                                                    className={this.state.nbSdb === "3" ? "nbpiece selected" : "nbpiece"}
                                                                    onClick={() => this.handleSelectNbSdb("3")}>3
                                                                </div>
                                                                <div
                                                                    className={this.state.nbSdb === "4" ? "nbpiece selected" : "nbpiece"}
                                                                    onClick={() => this.handleSelectNbSdb("4")}>4
                                                                </div>
                                                                <div
                                                                    className={this.state.nbSdb === "+5" ? "nbpiece selected" : "nbpiece"}
                                                                    onClick={() => this.handleSelectNbSdb("+5")}>+5
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                                <div
                                    className={`formblock displayBlock ${this.state.type === "Terrain" ? "terrain" : ""}`}>
                                    <div className="prixfull">
                                        <label htmlFor="">Budget</label>
                                        <div className="prixblock">
                                            <input type="number" placeholder="Min. €" value={this.state.prixMin}
                                                   onChange={(e) => this.handlePrixMin(e.target.value)}/>
                                            <input type="number" placeholder="Max. €" value={this.state.prixMax}
                                                   onChange={(e) => this.handlePrixMax(e.target.value)}/>
                                        </div>
                                        {
                                            this.state.errorPrixMax &&
                                            <div className="message">
                                                Le prix max. doit être supérieur au prix min.
                                            </div>
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.chemin !== "entreprise" &&
                                    <>
                                        <div className="formblock disflex">
                                            <label htmlFor="">Type de bien</label>
                                            <div className="blockNbPiece typeForm">
                                                <div onClick={() => this.handleAddEtatBien('Neuf')}
                                                    className={this.state.etat !== null ? this.state.etat === "Neuf" ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}>Neuf
                                                </div>
                                                <div onClick={() => this.handleAddEtatBien('Ancien')}
                                                    className={this.state.etat !== null ? this.state.etat === "Ancien" ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}>Ancien
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.etat === "Ancien" && this.state.contextProjet === "Acheter" ?
                                                <div className="formblock disflex">
                                                    <label htmlFor="">Travaux</label>
                                                    <div className="blockNbPiece travauxForm">
                                                        {
                                                            this.state.travaux.map((value, index) => (
                                                                <div key={index}
                                                                    onClick={() => this.handleAddTravaux(value.name)}
                                                                    className={this.state.travau !== null ? this.state.travau === value.name ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}>{value.name}</div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                : null
                                        }
                                </>
                                }
                                <div
                                    className={`formblock displayBlock ${this.state.type === "Terrain" ? "terrain" : ""}`}>
                                    <label htmlFor="">Descriptif de votre recherche de bien (Facultatif limité à 400
                                        caractères)</label>
                                    <textarea name="presentation" value={this.state.presentation}
                                              onChange={(e) => this.handleChangePresentation(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="action">
                                <a href="" className="gray"
                                   onClick={(e) => this.handleGotoLocal(e, 'carac')}><span>{"<"}</span> Retour</a>
                                <a href="" onClick={(e) => this.handleGotoLocal(e, 'recap')}>Suivant <span>{">"}</span></a>
                            </div>
                        </>
                    }
                    {
                        part6 === 1 &&
                        <>
                            <div className="form ">
                                <h2>Récapitulatif de votre recherche</h2>
                                <div className="blockRecap">
                                    <p>Type de bien :</p>
                                    <p>{this.state.contextProjet} | {this.state.type}{this.state.etat !== null ?` | ${this.state.etat}`:""}</p>
                                </div>
                                {
                                    this.state.localisation.map((value, index) => (
                                        <div key={index} className="blockRecap">
                                            <p>Localisation {index + 1} :</p>
                                            <p>
                                                {value.departement !== "" ? `${value.departement} | ` :""} {value.ville ? value.ville : ""}
                                            </p>
                                        </div>
                                    ))
                                }
                                {
                                    this.state.type !== "Terrain" ?
                                    <>
                                    <div className="blockRecap">
                                        <p>Superficie :</p>
                                        <p>{this.state.surfaceBienMin}{this.state.surfaceBienMin !== this.state.surfaceBienMax ? ` / ${this.state.surfaceBienMax} m² habitable` : " m² habitable "}
                                            {this.state.surfaceExtMin !== "" ? ` | ${this.state.surfaceExtMin}${this.state.surfaceExtMin !== this.state.surfaceExtMax ? ` / ${this.state.surfaceExtMax}` : ""} m² d'extérieur` : ""}</p>

                                    </div>
                                    <div className="blockRecap">
                                        <p>Configuration :</p>
                                        <p>{this.state.nbPiece === "Studio" ? this.state.nbPiece : `${this.state.nbPiece} pièce${this.state.nbPiece > 1 ? "s" : ""}`}
                                        {this.state.nbCham !== "" ? ` | ${this.state.nbCham} chambre${this.state.nbCham > 1 ? "s" : ""}` : ""}
                                        {this.state.nbSdb !== "" ? ` | ${this.state.nbSdb} Salle${this.state.nbSdb > 1 ? "s" : ""} de bain` : ""}</p>
                                    </div>
                                    </>
                                    :
                                    <div className="blockRecap">
                                        <p>Superficie :</p>
                                        <p>
                                            {this.state.surfaceExtMin !== "" ? ` | ${this.state.surfaceExtMin}${this.state.surfaceExtMin !== this.state.surfaceExtMax ? ` / ${this.state.surfaceExtMax}` : ""} m² d'extérieur` : ""}</p>

                                    </div>
                                }
                                {this.state.carac[0] &&
                                <div className="blockRecap">
                                    <p>Caractéristiques :</p>
                                    <p>
                                        {
                                            this.state.carac.map((value, index) => (
                                                <span key={index}>
                                                {value} {index < this.state.carac.length - 1 ? " | " : ""}
                                            </span>
                                            ))
                                        }
                                    </p>
                                </div>
                                }
                                <div className="blockRecap">
                                    <p>Budget :</p>
                                    <p>{this.state.prixMin !== "" ? `${this.state.prixMin} - ${this.state.prixMax} €` : "non communiqué"}</p>
                                </div>
                                <div className="blockRecap">
                                    <p>Délai de réalisation :</p>
                                    <p>{this.state.delai}</p>
                                </div>
                                {this.state.presentation !== "" &&
                                <div className="blockRecap">
                                    <p>Descriptif :</p>
                                    <p>{this.state.presentation}</p>
                                </div>
                                }
                                {this.state.travau !== null &&
                                <div className="blockRecap">
                                    <p>Travaux :</p>
                                    <p>{this.state.travau}</p>
                                </div>
                                }
                            </div>
                            <div className="form colonne">
                                <h2>Vos identifiants</h2>
                                <input type="text" value={this.state.email}
                                       onChange={(e) => this.setState({email: e.target.value})} placeholder="E-mail"/>
                                       {
                                        this.state.emailNotValide &&
                                            <span className="redColor">L'email que vous avez renseigné est déjà utilisé.</span>
                                       }
                                <input type="text" placeholder="Téléphone" onChange={(e) => this.setState({phone: e.target.value})} value={this.state.phone}/>
                                <span  >Votre numéro (recommandé mais pas obligatoire)</span>
                                <input type="password" value={this.state.password}
                                       onChange={(e) => this.setState({password: e.target.value})}
                                       placeholder="Mot de passe"/>
                                       <span>Le mot de passe doit contenir 8 caractères minimum</span>
                            </div>
                            <div className="action">
                                <a href="" className="gray"
                                   onClick={(e) => this.handleGotoLocal(e, 'sup')}><span>{"<"}</span> Retour</a>
                                <a href="" id="addProjet" onClick={(e) => this.handleSave(e)}>Valider <span>{">"}</span></a>
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    }
}
if (document.getElementById('formProjetAchat')) {
    const element = document.getElementById('formProjetAchat');
    let props = Object.assign({}, element.dataset);
    ReactDOM.render(<FormProjetAchat {...props}/>, document.getElementById('formProjetAchat'));
}
