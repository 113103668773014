import React from "react";
import ReactDOM from "react-dom";

import RelationProListeVente from "./RelationProListeVente";
import RelationProListeAchat from "./RelationProListeAchat";

export default class RelationProListe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config:JSON.parse(this.props.config),
            relations: JSON.parse(this.props.relations),
            relationsachats: JSON.parse(this.props.relationsachats),
            achatVente: "vente",
            user:this.props.user,
        };
        this.handleSelect = this.handleSelect.bind(this);
    }
    async componentDidMount() {
        let url = window.location.href;
        url = url.split("?");
        if (url[1]) {
            url = url[1].split("&");
            let type = null;
            await url.map(v => {
                let newParam = v.split("=");
                if (newParam[0] == "type") {
                    type = newParam[1];
                }
            });
            this.setState({achatVente:type});
        }
    }

    handleSelect(value) {
        this.setState({ achatVente: value });
    }

    render() {
        return (
            <>
            {
                this.state.config.habitat === 1 || this.state.config.entreprise === 1 ?
                <div className="form newform">
                    <div className="block">
                        <div
                            className={
                                this.state.achatVente !== null
                                    ? this.state.achatVente === "vente"
                                        ? "selected btn formbtn"
                                        : "btn formbtn"
                                    : "btn formbtn"
                            }
                            onClick={() => this.handleSelect("vente")}
                        >
                            Vente
                        </div>
                        <div
                            className={
                                this.state.achatVente !== null
                                    ? this.state.achatVente === "achat"
                                        ? "selected btn formbtn"
                                        : "btn formbtn"
                                    : "btn formbtn"
                            }
                            onClick={() => this.handleSelect("achat")}
                        >
                            Achat | Location
                        </div>
                    </div>
                </div>
                :
                <RelationProListeVente relations={this.state.relations} type={this.state.achatVente} user={this.state.user} config={this.state.config}/>
                }
                {
                this.state.achatVente !== null ?
                this.state.achatVente === "vente" ?
                    <RelationProListeVente relations={this.state.relations} type={this.state.achatVente} user={this.state.user} config={this.state.config}/>
                :
                    <RelationProListeAchat  relations={this.state.relationsachats} type={this.state.achatVente} user={this.state.user} config={this.state.config}/>
                    :null
            }
            </>
        );
    }
}
if (document.getElementById("relationProliste")) {
    const element = document.getElementById("relationProliste");
    let props = Object.assign({}, element.dataset);
    ReactDOM.render(
        <RelationProListe {...props} />,
        document.getElementById("relationProliste")
    );
}
