import React from "react";
import ReactDOM from "react-dom";

import axios from "axios";
import ChatComponent from "./ChatComponent";
import Moment from "react-moment";

export default class RelationProListeVente extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config:this.props.config,
            relations: this.props.relations,
            currentProjet: {},
            openModalSee: false,
            idDelete: null,
            openModalDelete: false,
            currentRelation: {},
            openDernierMessage: false,
            dernierMessage: "",
            openChat: false,
            messages: [],
            currentUserId: this.props.user,
            openChatStatus: false,
            type:this.props.type,
        }
        this.handleSeeProjet = this.handleSeeProjet.bind(this);
        this.handleOpenModalDeleteProjet = this.handleOpenModalDeleteProjet.bind(this);
        this.handleDeleteProjet = this.handleDeleteProjet.bind(this);
        this.handleDernierMessage = this.handleDernierMessage.bind(this);
        this.handleCloseDernierMessage = this.handleCloseDernierMessage.bind(this);
        this.handleSeeCloseProjet = this.handleSeeCloseProjet.bind(this);
        this.handleOpenChat = this.handleOpenChat.bind(this);
        this.handleSeeCloseChat = this.handleSeeCloseChat.bind(this);
    }

    async componentDidMount() {
        //?type=vente&chat=true&id=88&pro=153
        let {relations} = this.state;
        let url = window.location.href;
        url = url.split("?");
        if(url[1]){
            url = url[1].split("&");
            let type = null;
            let currentRelation = null;
            let openModalSee = null;
            await url.map(v => {
                let newParam = v.split("=");
                if(newParam[0] == "type"){
                    type = newParam[1];
                }else if(newParam[0] == 'chat'){
                    openModalSee = true;
                }else if(newParam[0] == 'id'){
                    currentRelation = relations.filter( relation => relation.projet_id == parseInt(newParam[1]));
                }
            });
            await this.setState({
                type,
                currentProjet:currentRelation[0].projet,
                currentRelation:currentRelation[0],
                openModalSee,
                messages:currentRelation[0].messages,
                openChatStatus:true,
            });
        }
    }

    handleSeeProjet(e, index) {
        e.preventDefault();
        this.setState({
            currentRelation: this.state.relations[index],
            currentProjet: this.state.relations[index].projet
        }, () => {
            this.setState({messages: this.state.currentRelation.messages}, () => {
                if(this.state.currentRelation.status === "Refuser"){
                    this.setState({openModalSee: true});
                }else{
                    this.setState({openModalSee: true,openChatStatus:true});
                }
            })
        });
    }

    handleSeeCloseProjet() {
        this.setState({currentProjet: {}, openModalSee: false,openChatStatus:false});
    }

    handleOpenModalDeleteProjet(e, index) {
        e.preventDefault();
        this.setState({currentRelation: this.state.relations[index], openModalDelete: true});
    }

    handleDeleteCloseRelation(e) {
        e.preventDefault();
        this.setState({openModalDelete: false, currentRelation: {}});
    }

    handleDeleteProjet(e) {
        e.preventDefault();
        axios.post('/professionnel/mise-en-relation/delete-projet', {
            id: this.state.currentRelation.id
        }).then(res => {
            this.setState({openModalDelete:false});
        }).catch(error => {

        })
    }

    handleDernierMessage(e, index) {
        e.preventDefault();
        this.setState({currentRelation: this.state.relations[index], openDernierMessage: true});
    }
    handleCloseDernierMessage(e) {
        e.preventDefault();
        this.setState({
            openDernierMessage: false
        });
    }

    sendDernierMessage(e) {
        e.preventDefault();
        axios.post('/professionnel/dernier/message', {
            message: this.state.dernierMessage,
            relation_id: this.state.currentRelation.id,
        }).then(res => {
            this.setState({relations: res.data, openDernierMessage: false, dernierMessage: ""});
        }).catch(error => {
            console.log(error)
        })
    }

    handleOpenChat(e) {
        e.preventDefault();
        this.setState({openChat: true});
    }

    handleSeeCloseChat() {
        this.setState({
            messages: [],
            openChat: false
        })
    }

    render() {
        return (
            <>
                <div className="listeRelationBlock">
                    <div className="relationHeader">
                        <div className="block relationTitre">
                            <p>Projet de vente</p>
                        </div>
                        <div className="block relationStatus">
                            Etape de la Mise en relation
                        </div>
                        <div className="block relationStatus">
                            Date
                        </div>
                        <div className="block relationStatus">
                            Action
                        </div>
                    </div>
                    {
                        this.state.relations.map((value, index) => (

                            <div key={index} className="relationLine">
                                <div className="block relationTitreBlock">
                                    {
                                     value.projet.titre !== null &&
                                     <>
                                        {value.projet.titre}
                                     </>
                                    }
                                </div>
                                <div className="block relationStatusBlock">
                                    {value.status == 'Accepter' ? `Validée` : value.status == "Refuser" ? `Refusé` : value.status == 'En attente' ? `Contact pris` : ""}
                                </div>
                                <div className="block relationStatusBlock">
                                    <Moment format="DD/MM/YYYY">
                                        { value.updated_at }
                                    </Moment>
                                </div>
                                <div className="block relationStatusBlock">
                                    {
                                        value.count < 2 &&
                                        <a href="" onClick={(e) => this.handleDernierMessage(e, index)}>Dernier
                                            message</a>
                                    }
                                    <a href="" onClick={(e) => this.handleSeeProjet(e, index)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#43a1db"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12ZM12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75Z"/>
                                            <path
                                                d="M4.32343 10.6464C3.90431 11.2503 3.75 11.7227 3.75 12C3.75 12.2773 3.90431 12.7497 4.32343 13.3536C4.72857 13.9374 5.33078 14.5703 6.09267 15.155C7.61978 16.3271 9.71345 17.25 12 17.25C14.2865 17.25 16.3802 16.3271 17.9073 15.155C18.6692 14.5703 19.2714 13.9374 19.6766 13.3536C20.0957 12.7497 20.25 12.2773 20.25 12C20.25 11.7227 20.0957 11.2503 19.6766 10.6464C19.2714 10.0626 18.6692 9.42972 17.9073 8.84497C16.3802 7.67292 14.2865 6.75 12 6.75C9.71345 6.75 7.61978 7.67292 6.09267 8.84497C5.33078 9.42972 4.72857 10.0626 4.32343 10.6464ZM5.17941 7.65503C6.90965 6.32708 9.31598 5.25 12 5.25C14.684 5.25 17.0903 6.32708 18.8206 7.65503C19.6874 8.32028 20.4032 9.06244 20.9089 9.79115C21.4006 10.4997 21.75 11.2773 21.75 12C21.75 12.7227 21.4006 13.5003 20.9089 14.2089C20.4032 14.9376 19.6874 15.6797 18.8206 16.345C17.0903 17.6729 14.684 18.75 12 18.75C9.31598 18.75 6.90965 17.6729 5.17941 16.345C4.31262 15.6797 3.59681 14.9376 3.0911 14.2089C2.59937 13.5003 2.25 12.7227 2.25 12C2.25 11.2773 2.59937 10.4997 3.0911 9.79115C3.59681 9.06244 4.31262 8.32028 5.17941 7.65503Z"/>
                                        </svg>
                                    </a>
                                    <a href="" onClick={(e) => this.handleOpenModalDeleteProjet(e, index)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#939393"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V3.75H5C4.58579 3.75 4.25 4.08579 4.25 4.5C4.25 4.91421 4.58579 5.25 5 5.25H19C19.4142 5.25 19.75 4.91421 19.75 4.5C19.75 4.08579 19.4142 3.75 19 3.75H14.75V3C14.75 2.58579 14.4142 2.25 14 2.25H10Z"/>
                                            <path
                                                d="M10 10.65C10.4142 10.65 10.75 10.9858 10.75 11.4L10.75 18.4C10.75 18.8142 10.4142 19.15 10 19.15C9.58579 19.15 9.25 18.8142 9.25 18.4L9.25 11.4C9.25 10.9858 9.58579 10.65 10 10.65Z"/>
                                            <path
                                                d="M14.75 11.4C14.75 10.9858 14.4142 10.65 14 10.65C13.5858 10.65 13.25 10.9858 13.25 11.4V18.4C13.25 18.8142 13.5858 19.15 14 19.15C14.4142 19.15 14.75 18.8142 14.75 18.4V11.4Z"/>
                                            <path
                                                d="M5.99142 7.91718C6.03363 7.53735 6.35468 7.25 6.73684 7.25H17.2632C17.6453 7.25 17.9664 7.53735 18.0086 7.91718L18.2087 9.71852C18.5715 12.9838 18.5715 16.2793 18.2087 19.5446L18.189 19.722C18.045 21.0181 17.0404 22.0517 15.7489 22.2325C13.2618 22.5807 10.7382 22.5807 8.25108 22.2325C6.95954 22.0517 5.955 21.0181 5.81098 19.722L5.79128 19.5446C5.42846 16.2793 5.42846 12.9838 5.79128 9.71852L5.99142 7.91718ZM7.40812 8.75L7.2821 9.88417C6.93152 13.0394 6.93152 16.2238 7.2821 19.379L7.3018 19.5563C7.37011 20.171 7.84652 20.6612 8.45905 20.747C10.8082 21.0758 13.1918 21.0758 15.5409 20.747C16.1535 20.6612 16.6299 20.171 16.6982 19.5563L16.7179 19.379C17.0685 16.2238 17.0685 13.0394 16.7179 9.88417L16.5919 8.75H7.40812Z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {/* MODAL SEE */}
                {
                    this.state.openModalSee &&
                    <div className="modal modalSee">
                        <div className="backgroundModal">
                            <div className="contentModal">
                                <div className="header">
                                    <div className="title">
                                        <h3>{this.state.currentProjet.titre}</h3>
                                    </div>
                                    <div className="close" onClick={this.handleSeeCloseProjet}>
                                        <svg width="40" height="40" viewBox="0 0 24 24" fill="#2f2f2f"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.46445 15.5354L15.5355 8.46436" stroke="#2f2f2f"/>
                                            <path d="M8.46446 8.46458L15.5355 15.5356" stroke="#2f2f2f"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="relationBlock">
                                <div className="blockRecape">
                                    {
                                        this.state.config.vuclient === 0 ?
                                        this.state.currentRelation.status === "Accepter"   &&
                                        <div
                                            className={`${this.state.currentRelation.status === "Accepter" ? "left double" : "left disabledBlock"}`}>
                                            <h2>Le vendeur</h2>
                                            <div className="blockRecap">
                                                <h3>Prénom / Nom :</h3>
                                                <p>{this.state.currentRelation.part.prenom} {this.state.currentRelation.part.nom}</p>
                                            </div>
                                            <div className="blockRecap">
                                                <h3>Téléphone :</h3>
                                                <p>{this.state.currentRelation.part.telephone}</p>
                                            </div>
                                            <div className="blockRecap">
                                                <h3>Email :</h3>
                                                <p>{this.state.currentRelation.part.email}</p>
                                            </div>
                                            <div className="blockRecap">
                                                <h3>Mode de communication souhaité :</h3>
                                                <p>
                                                {
                                                    this.state.currentRelation.part.communication === "telephone" &&
                                                    <span> Téléphone</span>

                                                }
                                                {
                                                    this.state.currentRelation.part.communication === "email" &&
                                                <span> E-mail</span>
                                                }

                                                {
                                                    this.state.currentRelation.part.communication === "email | telephone" &&
                                                    <span> Téléphone | E-mail</span>

                                                }
                                                </p>
                                            </div>
                                        </div>
                                            :
                                            <div
                                                className={`${this.state.currentRelation.status === "Accepter" ? "left double" : "left disabledBlock"}`}>
                                                <h2>Le vendeur</h2>
                                                <div className="blockRecap">
                                                    <h3>Prénom / Nom :</h3>
                                                    <p>{this.state.currentRelation.part.prenom} {this.state.currentRelation.part.nom}</p>
                                                </div>
                                                <div className="blockRecap">
                                                    <h3>Téléphone :</h3>
                                                    <p>{this.state.currentRelation.part.telephone}</p>
                                                </div>
                                                <div className="blockRecap">
                                                    <h3>Email :</h3>
                                                    <p>{this.state.currentRelation.part.email}</p>
                                                </div>
                                                <div className="blockRecap">
                                                    <h3>Mode de communication souhaité :</h3>
                                                    <p>
                                                        {
                                                            this.state.currentRelation.part.communication === "telephone" &&
                                                            <span> Téléphone</span>

                                                        }
                                                        {
                                                            this.state.currentRelation.part.communication === "email" &&
                                                            <span> E-mail</span>
                                                        }

                                                        {
                                                            this.state.currentRelation.part.communication === "email | telephone" &&
                                                            <span> Téléphone | E-mail</span>

                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                    }
                                    <div
                                        className={`${this.state.currentRelation.status === "Accepter" ? "right double" : "right full"}`}>
                                        <h2>Bien en vente</h2>
                                        <div className="blockRecap">
                                            <h3>Type de bien :</h3>
                                            <p>{this.state.currentProjet.type}</p>
                                        </div>
                                        <div className="blockRecap">
                                            <h3>Localisation :</h3>
                                            <p>{this.state.currentProjet.localisation}</p>
                                        </div>
                                        {
                                        this.state.currentProjet.type === "Appartement" &&
                                        <div className="blockRecap">
                                            <h3>Étage :</h3>
                                            <p>{this.state.currentProjet.etage} / {this.state.currentProjet.etageTotal}</p>
                                        </div>
                                        }
                                        {
                                            this.state.currentProjet.type === "Terrain" ?
                                            <div className="blockRecap">
                                            <h3>Superficie :</h3>
                                            <p>
                                                {this.state.currentProjet.terrainMin !== null ? ` ${this.state.currentProjet.terrainMin}${this.state.currentProjet.terrainMin !== this.state.currentProjet.terrainMax ? this.state.currentProjet.terrainMax !== null ? ` / ${this.state.currentProjet.terrainMax}` : "": ""} m² d'extérieur` : ""}</p>
                                        </div>
                                            :
                                            <>
                                                <div className="blockRecap">
                                            <h3>Superficie :</h3>
                                            <p>{this.state.currentProjet.surfaceMin}{this.state.currentProjet.surfaceMin !== this.state.currentProjet.surfaceMax ? this.state.currentProjet.surfaceMax !== null ? ` / ${this.state.currentProjet.surfaceMax} m² habitable` : " m² habitable ": " m² habitable "}
                                                {this.state.currentProjet.terrainMin !== null ? ` | ${this.state.currentProjet.terrainMin}${this.state.currentProjet.terrainMin !== this.state.currentProjet.terrainMax ? this.state.currentProjet.terrainMax !== null ? ` / ${this.state.currentProjet.terrainMax}` : "": ""} m² d'extérieur` : ""}</p>
                                        </div>
                                        <div className="blockRecap">
                                            <h3>Configuration :</h3>
                                            <p>{this.state.currentProjet.piece === 0 ? "Studio" : `${this.state.currentProjet.piece} pièce${this.state.currentProjet.piece > 1 ? "s" : ""}`}
                                                {this.state.currentProjet.chambre !== null ? ` | ${this.state.currentProjet.chambre} chambre${this.state.currentProjet.chambre > 1 ? "s" : ""}` : ""}
                                                {this.state.currentProjet.sdb !== null ? ` | ${this.state.currentProjet.sdb} Salle${this.state.currentProjet.sdb > 1 ? "s" : ""} de bain` : ""}</p>
                                        </div>
                                            </>
                                        }

                                        <div className="blockRecap">
                                            <h3>Caractéristiques :</h3>
                                            <p>
                                                {this.state.currentProjet.caracteristique}
                                            </p>
                                        </div>
                                        <div className="blockRecap">
                                            <h3>Prix Net vendeur souhaité :</h3>
                                            <p>{this.state.currentProjet.budgetMin !== null ? this.state.currentProjet.budgetMin === this.state.currentProjet.budgetMax ? `${this.state.currentProjet.budgetMin} €` : this.state.currentProjet.budgetMax === null ? `${this.state.currentProjet.budgetMin} €` : `${this.state.currentProjet.budgetMin} / ${this.state.currentProjet.budgetMax} €` :"non communiqué"}</p>
                                        </div>
                                        <div className="blockRecap">
                                            <h3>Délai de réalisation :</h3>
                                            <p>{this.state.currentProjet.delai}</p>
                                        </div>
                                        {this.state.currentProjet.presentation !== "" &&
                                        <div className="blockRecap">
                                            <h3>Descriptif:</h3>
                                            <p>{this.state.currentProjet.presentation}</p>
                                        </div>
                                        }
                                    </div>

                                </div>
                                <div className="chatBlock">
                                    {
                                        this.state.openChatStatus &&
                                            <ChatComponent
                                                messages={this.state.messages}
                                                pro={this.state.currentRelation.user_pro_id}
                                                parti={this.state.currentRelation.user_parti_id}
                                                relation={this.state.currentRelation.id}
                                                user={this.state.currentUserId}
                                                handleSeeCloseChat={this.handleSeeCloseChat}
                                                type={this.state.type}
                                            />
                                    }
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                }
                {/* MODAL DELETE */}
                {
                    this.state.openModalDelete &&
                    <div className="modal modalDelete">
                        <div className="backgroundModal">
                            <div className="contentModal">
                                <div className="message">
                                    <p>Voulez-vous vraiment supprimer la relation avec le
                                        projet {this.state.currentRelation.projet.titre}</p>
                                </div>
                                <div className="action">
                                    <a href="" onClick={(e) => this.handleDeleteCloseRelation(e)}>Annuler</a>
                                    <a href="" onClick={(e) => this.handleDeleteProjet(e)}>Supprimer</a>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* DERNIER MESSAGE */}
                {
                    this.state.openDernierMessage &&
                    <div className="modal small modalDernierMessage">
                        <div className="backgroundModal">
                            <div className="contentModal">
                                <textarea onChange={(e) => this.setState({dernierMessage: e.target.value})}>
                                    {this.state.dernierMessage}
                                </textarea>
                                <div className="dernierMessage">
                                    <button onClick={(e) => this.handleCloseDernierMessage(e)}>Annuler</button>
                                    <button onClick={(e) => this.sendDernierMessage(e)}>Envoyer</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}
