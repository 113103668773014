import React from "react";
import ReactDOM from 'react-dom';

import axios from "axios";

export default class LineSelectAchat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            i: this.props.i,
            regions: this.props.regions,
            departements: null,
            villes: null,
            quartiers: null,
            region: null,
            departement: null,
            ville: null,
            quartier: null,
            tab: this.props.tab,
            line: this.props.line,
            index: this.props.index,
        }
        this.handleSelectRegion = this.handleSelectRegion.bind(this);
        this.handleSelectDepartement = this.handleSelectDepartement.bind(this);
        this.handleSelectVille = this.handleSelectVille.bind(this);
        this.handleSelectQuartier = this.handleSelectQuartier.bind(this);
    }

    async componentDidMount() {
        let {tab,index} = this.state;
        if(tab[0]){
            this.setState({
                region:tab[index][0],
                departement:tab[index][1],
                ville:tab[index][2],
                quartier:tab[index][3] ? tab[index][3] : null,
            },() => {
                this.handleSelectRegion(tab[index][0]);
                this.handleSelectDepartement(tab[index][1]);
                this.handleSelectVille(tab[index][2]);
            });
        }

    }

    handleSelectRegion(value) {
        axios.post('/location/departement', {
            region: value,
        }).then(res => {
            if (value === "Toutes les régions") {
                this.setState({
                    departements: null,
                    departement: "",
                    villes: null,
                    ville: "",
                    quartiers: null,
                    quartier: "",
                    region: value
                });
            } else {
                this.setState({departements: res.data, region: value});
            }
        }).catch(error => {

        })
    }

    handleSelectDepartement(value) {
        axios.post('/location/ville', {
            departement: value,
        }).then(res => {
            this.setState({villes: res.data, departement: value});
        }).catch(error => {

        })
    }

    handleSelectVille(value) {
        axios.post('/location/quartier', {
            ville: value,
        }).then(res => {
            let tab = [];
            this.setState({quartiers: res.data, ville: value}, () => {
                if(this.state.quartier !== null){
                    tab = [this.state.region, this.state.departement, this.state.ville, this.state.quartier];
                }else{
                    tab = [this.state.region, this.state.departement, this.state.ville];
                }
                this.props.handleOnTab(tab,this.state.index);
            });
        }).catch(error => {

        })
    }

    handleSelectQuartier(value) {
        this.setState({quartier: value}, () => {
            let tab = [this.state.region, this.state.departement, this.state.ville, this.state.quartier];
            this.props.handleOnTab(tab,this.state.index);
        });
    }

    render() {
        return (
            <>
                <div className="block">
                    <div className="selectBlock">
                        <select value={this.state.region} defaultValue={'DEFAULT'}
                                onChange={(e) => this.handleSelectRegion(e.target.value)}>
                            <option value="DEFAULT">Région</option>
                            {
                                this.state.regions.map((value, index) => (
                                    value.region !== "" &&
                                    value.region !== "Toutes les régions" &&
                                    this.state.tab[index] &&
                                    value.region === this.state.tab[index][0] ?
                                        <option selected="selected" key={index}
                                                value={value.region}>{value.region}</option>
                                        :
                                        <option key={index} value={value.region}>{value.region}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="selectBlock">
                        {
                            this.state.departements !== null &&
                            <select value={this.state.departement} defaultValue={'DEFAULT'}
                                    onChange={(e) => this.handleSelectDepartement(e.target.value)}>
                                <option value="DEFAULT">Département</option>
                                {
                                    this.state.departements.map((value, index) => (
                                        value.departement !== "" ?
                                            this.state.tab[index] &&
                                            value.departement === this.state.tab[index][1] ?
                                                <option selected="selected" key={index}
                                                        value={value.departement}>{value.departement}</option>
                                                :
                                                <option key={index}
                                                        value={value.departement}>{value.departement}</option>
                                            : null
                                    ))
                                }
                            </select>
                        }
                    </div>
                    <div className="selectBlock">
                        {
                            this.state.villes !== null &&
                            <select value={this.state.ville} defaultValue={'DEFAULT'}
                                    onChange={(e) => this.handleSelectVille(e.target.value)}>
                                <option value="DEFAULT">Ville</option>
                                {
                                    this.state.villes.map((value, index) => (
                                        value.ville != null ?
                                            value.ville != "" ?
                                                this.state.tab[index] &&
                                                    value.ville === this.state.tab[index][2] ?
                                                        <option selected="selected" key={index}
                                                                value={value.ville}>{value.ville}</option>
                                                        :
                                                        <option key={index} value={value.ville}>{value.ville}</option>
                                            :null
                                        :null
                                    ))
                                }
                            </select>
                        }
                    </div>
                    <div className="selectBlock">
                        {
                            this.state.quartiers !== null &&
                            <select value={this.state.quartier !== null ? this.state.quartier : 'DEFAULT'}
                                    defaultValue={'DEFAULT'}
                                    onChange={(e) => this.handleSelectQuartier(e.target.value)}>
                                <option value="DEFAULT">Quartier</option>
                                {
                                    this.state.quartiers.map((value, index) => (
                                        value.quartier !== null ?
                                            value.quartier !== "" ?
                                                this.state.tab[index] &&
                                                    value.ville === this.state.tab[index][3] ?
                                                        <option selected="selected" key={index} value={value.quartier}>{value.quartier}</option>
                                                            :
                                                        <option key={index} value={value.quartier}>{value.quartier}</option>
                                            :null
                                        :null
                                    ))
                                }
                            </select>
                        }
                    </div>
                </div>
            </>
        )
    }
}
