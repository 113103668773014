import React from "react";
import ReactDOM from 'react-dom';

import axios from "axios";

export default class LineSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            i: this.props.i,
            regions: this.props.regions,
            departements: null,
            villes: null,
            quartiers: null,
            region: null,
            departement: null,
            ville: null,
            quartier: null,
            tab: this.props.tab,
            line: this.props.line,
            modif: this.props.modif,
        }
        this.handleSelectRegion = this.handleSelectRegion.bind(this);
        this.handleSelectDepartement = this.handleSelectDepartement.bind(this);
        this.handleSelectVille = this.handleSelectVille.bind(this);
        this.handleSelectQuartier = this.handleSelectQuartier.bind(this);
        this.handleDeletePeriferique = this.handleDeletePeriferique.bind(this);

    }

    async componentDidMount() {
        let {line, region, departement, ville, quartier, tab} = this.state;
        setTimeout(() => {
            region = tab[line][0];
            departement = tab[line][1];
            ville = tab[line][2];
            quartier = tab[line][3] !== null ? tab[line][3] : null;

            if (region !== "Toutes les régions") {

                axios.post('/location/departement', {
                    region: region,
                }).then(res => {
                    this.setState({departements: res.data});
                }).catch(error => {

                })

                axios.post('/location/ville', {
                    departement: departement,
                }).then(res => {
                    this.setState({villes: res.data});
                }).catch(error => {

                })

                axios.post('/location/quartier', {
                    ville: ville,
                }).then(res => {
                    this.setState({quartiers: res.data});
                }).catch(error => {

                })
                this.setState({region, departement, ville, quartier});
            } else {
                this.setState({
                    region,
                    departement: "",
                    departements: null,
                    ville: "",
                    villes: null,
                    quartier: "",
                    quartiers: null
                });
            }
        }, 200)
    }


    handleSelectRegion(value) {
        let {line} = this.state;
        if (value == "DEFAULT") {
            this.setState({
                region:null,
                departement: null,
                ville: null,
                quartier: null,
            });
        } else {
            axios.post('/location/departement', {
                region: value,
            }).then(res => {
                if (value === "Toutes les régions") {
                    this.setState({
                        departements: null,
                        departement: "",
                        villes: null,
                        ville: "",
                        quartiers: null,
                        quartier: "",
                        region: value
                    }, () => {
                        let hiddenInput = document.querySelector('#perimettreMultiSelect');
                        let tab = [];
                        if (this.state.i !== 1) {
                            tab = JSON.parse(hiddenInput.value);
                        }

                        let result = [this.state.region, this.state.departement, this.state.ville];
                        if (this.state.modif) {
                            tab[this.state.line] = result;
                        } else {
                            tab[this.state.i - 1] = result;
                        }
                        this.props.handleOnTab(tab)
                    });

                } else {
                    this.setState({departements: res.data, region: value});
                }
            }).catch(error => {

            })
        }
    }

    handleSelectDepartement(value) {
        let {line} = this.state;
        if (value == "DEFAULT") {
            this.setState({
                departement: null,
                ville: null,
                quartier: null,
            },() => {
                let hiddenInput = document.querySelector('#perimettreMultiSelect');
                let tab = [];
                if (this.state.i !== 1) {
                    tab = JSON.parse(hiddenInput.value);
                }

                let result = [this.state.region, this.state.departement, this.state.ville];
                if (this.state.modif) {
                    tab[this.state.line] = result;
                } else {
                    tab[this.state.i - 1] = result;
                }
                this.props.handleOnTab(tab)
            });
        } else {
            axios.post('/location/ville', {
                departement: value,
            }).then(res => {
                this.setState({villes: res.data, departement: value}, () => {
                    let hiddenInput = document.querySelector('#perimettreMultiSelect');
                    let tab = [];
                    if (this.state.i !== 1) {
                        tab = JSON.parse(hiddenInput.value);
                    }

                    let result = [this.state.region, this.state.departement, this.state.ville];
                    if (this.state.modif) {
                        tab[this.state.line] = result;
                    } else {
                        tab[this.state.i - 1] = result;
                    }
                    this.props.handleOnTab(tab)
                });
            }).catch(error => {

            })

        }
    }

    handleSelectVille(value) {
        let {line} = this.state;
        console.log(value);
        if (value == "DEFAULT") {
            this.setState({
                ville: null,
                quartier: null,
            });
        } else {
            axios.post('/location/quartier', {
                ville: value,
            }).then(res => {
                this.setState({quartiers: res.data, ville: value}, () => {
                    let hiddenInput = document.querySelector('#perimettreMultiSelect');
                    let tab = [];
                    if (this.state.i !== 1) {
                        tab = JSON.parse(hiddenInput.value);
                    }
                    let result = [this.state.region, this.state.departement, this.state.ville];
                    if (this.state.modif) {
                        tab[this.state.i - 1] = result;
                    } else {
                        tab[this.state.line] = result;
                    }
                    this.props.handleOnTab(tab)
                });
            }).catch(error => {

            })
        }
    }

    handleSelectQuartier(value) {
        let {line} = this.state;
        if (value == "DEFAULT") {
            this.setState({
                quartier: null,
            });
        } else {
            this.setState({quartier: value}, () => {
                let hiddenInput = document.querySelector('#perimettreMultiSelect');
                let tab = JSON.parse(hiddenInput.value);
                let result = [this.state.region, this.state.departement, this.state.ville, this.state.quartier];
                if (this.state.modif) {
                    tab[this.state.line] = result;
                } else {
                    tab[this.state.i - 1] = result;
                }
                this.props.handleOnTab(tab)
            });
        }
    }
    async handleDeletePeriferique(e){
        e.preventDefault();
        console.log('hello '+this.state.line);
        let hiddenInput = document.querySelector('#perimettreMultiSelect');
        let tab = JSON.parse(hiddenInput.value);
        delete tab[this.state.line];
        let tab2 = tab.filter(el => {return el !== null});
        this.setState({
            region:null,
            departement: "",
            departements: null,
            ville: "",
            villes: null,
            quartier: "",
            quartiers: null
        })
        this.props.handleOnTab(tab2);
    }
    render() {
        return (
            <div className="block">
                <div className="selectBlock">
                    <select value={this.state.region} defaultValue={'DEFAULT'}
                            onChange={(e) => this.handleSelectRegion(e.target.value)}>
                        <option value="DEFAULT">Région</option>
                        {
                            this.state.regions.map((value, index) => (
                                value.region !== "" &&
                                value.region !== "Toutes les régions" &&
                                value.region === this.state.region ?
                                    <option selected="selected" key={index} value={value.region}>{value.region}</option>
                                    :
                                    <option key={index} value={value.region}>{value.region}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="selectBlock">
                    {
                        this.state.departements !== null &&
                        <select value={this.state.departement} defaultValue={'DEFAULT'}
                                onChange={(e) => this.handleSelectDepartement(e.target.value)}>
                            <option value="DEFAULT">Département</option>
                            {
                                this.state.departements.map((value, index) => (
                                    value.departement !== "" ?
                                        value.departement === this.state.departement ?
                                            <option selected="selected" key={index}
                                                    value={value.departement}>{value.departement}</option>
                                            :
                                            <option key={index} value={value.departement}>{value.departement}</option>
                                        : null
                                ))
                            }
                        </select>
                    }
                </div>
                <div className="selectBlock">
                    {
                        this.state.villes !== null &&
                        <select value={this.state.ville} defaultValue={'DEFAULT'}
                                onChange={(e) => this.handleSelectVille(e.target.value)}>
                            <option value="DEFAULT">Ville</option>
                            {
                                this.state.villes.map((value, index) => (
                                    value.ville !== "" &&
                                    value.ville !== null &&

                                    <option key={index} value={value.ville}>{value.ville}</option>
                                ))
                            }
                        </select>
                    }
                </div>
                <div className="selectBlock">
                    {
                        this.state.quartiers !== null &&
                        <select value={this.state.quartier !== null ? this.state.quartier : 'DEFAULT'}
                                defaultValue={'DEFAULT'} onChange={(e) => this.handleSelectQuartier(e.target.value)}>
                            <option value="DEFAULT">Quartier</option>
                            {
                                this.state.quartiers.map((value, index) => (
                                    value.quartier !== "" &&

                                    <option key={index} value={value.quartier}>{value.quartier}</option>
                                ))
                            }
                        </select>
                    }
                </div>
                <div className="removePerimettre">
                    <button id="deleteVideo2" onClick={(e) => this.handleDeletePeriferique(e)} className="btn btn-warning">Supprimer</button>
                </div>
            </div>
        )
    }
}
