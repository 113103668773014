import React from "react";
import ReactDOM from "react-dom";

import axios from "axios";
import Moment from "react-moment";

export default class ChatComponent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            messages:this.props.messages,
            newMessage:"",
            user_pro_id:this.props.pro,
            user_parti_id:this.props.parti,
            id:this.props.relation,
            currentUserId:this.props.user,
            index:this.props.index,
            pro:this.props.pro,
            type:this.props.type,
        }
        let interval = null;
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            axios.post('/recuperation/message/chat',{
                id:this.state.id,
                type:this.state.type,
            }).then(res => {
                this.setState({
                    messages:res.data,
                })
            }).catch(error => {

            })
        },10000)
        this.scrollToBottom();
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleSaveNewMessage(e){
        e.preventDefault();
        let sendMessageUserId = null;
        let typeUser = "";
        if(this.state.user_pro_id == this.state.currentUserId){
            sendMessageUserId = this.state.user_pro_id;
            typeUser = "pro";
        }else{
            sendMessageUserId = this.state.user_parti_id;
            typeUser = "parti";
        }
        axios.post('/save/message/user',{
            relation_id:this.state.id,
            message:this.state.newMessage,
            sendMessageUserId:sendMessageUserId,
            typeUser:typeUser,
            type:this.state.type,
        }).then(res => {
            this.setState({
                messages:res.data,
                newMessage:"",
            })
        }).catch(error => {

        })
    }

    scrollToBottom(){
        this.messagesEnd.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    render(){
        const textArea = document.querySelector("textarea");
        const textRowCount = textArea ? textArea.value.split("\n").length : 0;
        const rows = textRowCount + 1;
        return(
            <>
                {
                    !this.state.pro  &&
                        <div className="close" onClick={() => this.props.handleSeeCloseChat(this.state.index)}>
                            <svg width="40" height="40" viewBox="0 0 24 24" fill="#2f2f2f" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.46445 15.5354L15.5355 8.46436" stroke="#2f2f2f"/>
                                <path d="M8.46446 8.46458L15.5355 15.5356" stroke="#2f2f2f"/>
                            </svg>

                        </div>
                }
                <div className="chat" onChange={this.scrollToBottom}>
                    {
                        this.state.messages[0] !== undefined &&
                        this.state.messages.map((value, index) => (
                            <div key={index} className={`linemessage ${this.state.currentUserId == value.user_id ? "right": "left" }`}>
                                <Moment format="DD/MM/YYYY H:mm">
                                    { value.created_at }
                                </Moment>
                                <p>{value.message}</p>
                            </div>
                        ))
                    }
                    <div style={{ float:"left", clear: "both" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>
                </div>
                <div className="formChat">
                    <textarea rows={rows} value={this.state.newMessage} placeholder="Votre message" onChange={event => this.setState({ newMessage: event.target.value })}></textarea>
                    <div className="sendChat">
                        <a href="" onClick={(e) => this.handleSaveNewMessage(e)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.5" height="16.5" viewBox="0 0 18.5 16.5" fill="#43a1db">
                            <path id="Send" d="M21.75,12a.75.75,0,0,1-.386.656l-2.282,1.268A75.748,75.748,0,0,1,4.889,20.008l-.665.208A.75.75,0,0,1,3.25,19.5V13.75A.75.75,0,0,1,3.94,13l.227-.018A44.247,44.247,0,0,0,10.555,12a44.239,44.239,0,0,0-6.478-.991L3.942,11a.75.75,0,0,1-.692-.748V4.5a.75.75,0,0,1,.974-.716l.665.208a75.751,75.751,0,0,1,14.193,6.084l2.282,1.268A.75.75,0,0,1,21.75,12Zm-2.294,0-1.1-.612A74.247,74.247,0,0,0,4.75,5.521V9.56A45.753,45.753,0,0,1,13.4,11.191l.3.089a.75.75,0,0,1,0,1.441l-.391.113A45.749,45.749,0,0,1,4.75,14.44v4.039a74.25,74.25,0,0,0,13.6-5.867Z" transform="translate(-3.25 -3.75)" fill-rule="evenodd"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </>
        )
    }
}
