import React from "react";
import ReactDOM from "react-dom";
import LineLocaAchat from "./LineLocaAchat";
import LineLocaVente from "./LineLocaVente";


export default class Localisation extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            localisation:[],
            achatVente:this.props.type,
            config:JSON.parse(this.props.config),
            line:[1],
            id:0,
            tab:[],
        }
    }
    handleAddLine = (e) => {
        e.preventDefault();
        let {line,id} = this.state;
        line.push(1);
        id++;
        this.setState({line,id});
    }
    handleOnTab = (value) => {
        let {localisation,id} = this.state;
        localisation[id] = value;
        this.setState({
            localisation,
        })
    }
    

    render(){
        const {achatVente} = this.state;
        return(
            <>
                {
                    achatVente === "vente" ?
                            <LineLocaVente config={this.state.config} type={this.state.achatVente}/>
                        :
                        <>
                                {
                                    this.state.line.map((value,index) => (
                                        <div key={index} >
                                            <LineLocaAchat config={this.state.config} type={this.state.achatVente} id={this.state.id} handleOnTab={this.handleOnTab}/>
                                        </div>
                                    ))
                                }
                            <a onClick={(e) => this.handleAddLine(e)} >+ Ajouter</a>
                            <div className="action">
                                <a onClick={this.handleSave}>Suivant</a>
                            </div>
                        </>
                }
            </>
        );
    }
}

if (document.getElementById('localisation')) {
    const element = document.getElementById('localisation');
    let props = Object.assign({}, element.dataset);
    ReactDOM.render(<Localisation {...props}/>, document.getElementById('localisation'));
}