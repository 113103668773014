/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

require('./components/front/FormProjetVente');
require('./components/front/FromProjetAchat');
require('./components/front/Perimetre');
require('./components/front/CheckedCompoment');
require('./components/front/CheckedChasse');
require('./components/front/CheckedRelation');
require('./components/front/ConsultePro');
require('./components/front/BlockProjetsParti');
require('./components/front/RelationProListe');
require('./components/front/SelectionDepartement');
require('./components/front/SelectionCode');
require('./components/front/SubcriptionCancel');
require('./components/front/localisation/Localisation');
require('./components/Croppers');
require('./components/front/mini/FormProjetAchat');
require('./components/front/mini/FormProjetVente');
require('./components/front/Lienformhome');
require('./components/front/Formcontactinde');
