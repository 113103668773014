import React from 'react';
import ReactDOM from 'react-dom';

export default class LineLocaAchat extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            localisation:this.props.localisation,
            config:this.props.config,
            achatVente:this.props.type,
            id:this.props.id,
            locaConfigAchat:null,
            locaConfigVente:null,
            locavente:null,
            locaachat:null,
            regions:null,
            region:"",
            depart:null,
            departement:"",
            villes:null,
            ville:"",
            quartiers:null,
            quartier:"",
            departOpen:false,
            villeOpen:false,
            quartierOpen:false,

        }
    };
    componentDidMount() {
        const {config} = this.state;
            this.setState({locaConfigAchat:JSON.parse(config.locaConfigAchat)},() => {
                if(this.state.locaConfigAchat.region){
                    axios.post('/loca-achat-region').then(res => {
                        this.setState({regions: res.data});
                    }).catch(error => {

                    })
                }else{
                    if(this.state.locaConfigAchat.departement){
                        axios.post('/loca-achat-depart').then(res => {
                            this.setState({depart: res.data,departOpen:true});
                        }).catch(error => {

                        })
                    }else{
                        axios.post('/loca-achat-ville').then(res => {
                            let autre = res.data.filter(d => d.ville === "Autre");
                            if(autre[0]){
                                let loca = res.data.filter(d => d.ville !== "Autre");
                                let newAutre = {ville: "Autre"};
                                loca.push(newAutre)
                                this.setState({villes: loca,villeOpen:true});
                            }else{
                                this.setState({villes: res.data,villeOpen:true});
                            }

                        }).catch(error => {

                        })
                    }
                }

            });
            if(this.state.localisation){
            if(this.state.localisation[this.state.id]){
            this.setState({
                region:this.state.localisation[this.state.id].region,
                departement:this.state.localisation[this.state.id].departement,
                ville:this.state.localisation[this.state.id].ville,
                quartier:this.state.localisation[this.state.id].quartier,
                departOpen:true,
                villeOpen:true,
                quartierOpen:true,
            },() => {
                axios.post('/loca-achat-depart-select',{
                    region:this.state.region
                }).then(res => {
                    this.setState({depart: res.data,departOpen:true},() => {
                        axios.post('/loca-achat-ville-select',{
                            depart:this.state.departement
                        }).then(res => {
                            this.setState({villes: res.data,villeOpen:true},() => {
                                axios.post('/loca-quartier',{
                                    ville:this.state.ville,
                                    type:this.state.achatVente,
                                }).then(res => {
                                    this.setState({quartiers: res.data[0] ? res.data : null ,quartierOpen:true});
                                }).catch(error => {

                                })
                            });
                        }).catch(error => {

                        })
                    });
                }).catch(error => {

                })
            })
        }
        }
    };
    handleDepart = (value) => {
        this.setState({
            region:value
        },() => {
            axios.post('/loca-achat-depart-select',{
                region:value
            }).then(res => {
                this.setState({depart: res.data,departOpen:true});
            }).catch(error => {

            })
        });
    };
    handleVille = (value) =>{
        this.setState({
            departement:value
        },() => {
            axios.post('/loca-achat-ville-select',{
                depart:value
            }).then(res => {
                let autre = res.data.filter(d => d.ville === "Autre");
                if(autre[0]){
                    let loca = res.data.filter(d => d.ville !== "Autre");
                    let newAutre = {ville: "Autre"};
                    loca.push(newAutre)
                    this.setState({villes: loca,villeOpen:true});
                }else{
                    this.setState({villes: res.data,villeOpen:true});
                }
            }).catch(error => {

            })
        });
    };
    handleQuartier = (value,id) =>{
        this.setState({
            ville:value,
            quartierOpen:false,
        },() => {
            if(this.state.locaConfigAchat.quartier ){
                axios.post('/loca-quartier',{
                    ville:value,
                    type:this.state.achatVente,
                }).then(res => {
                    this.setState({quartiers: res.data[0] ? res.data : null ,quartierOpen:true});
                }).catch(error => {

                })
                this.handleSave();
            }else{
                this.handleSave();
            }
        });
    };
    handleSelectFinal = (value) => {
        this.setState({
            quartier:value,
        },() =>{
            this.handleSave();
        })
    };
    handleSave = () => {
        let {localisation} = this.state;
        let achat = {
            region: this.state.region,
            departement: this.state.departement,
            ville: this.state.ville,
            quartier: this.state.quartier
        };
        localisation[this.state.id] = achat;
        this.setState({
            localisation
        },() => {
            this.props.handleOnTab(this.state.localisation,this.state.id);
        })
    };
    render(){
        return(
            <>
                {
                    this.state.locaConfigAchat !== null &&
                    this.state.locaConfigAchat.mini ?
                        <div className="form">

                            <div className='block'>
                            {
                                this.state.locaConfigAchat !== null &&
                                this.state.locaConfigAchat.region &&
                                <select onChange={(e) => this.handleDepart(e.target.value)}>
                                    <option>Region</option>
                                    {
                                        this.state.regions !== null &&
                                        this.state.regions.map((value,index) => (
                                            <option key={index} value={value.region}>{value.region}</option>
                                        ))
                                    }
                                </select>
                            }
                            {
                                this.state.locaConfigAchat !== null &&
                                this.state.locaConfigAchat.departement &&
                                this.state.departOpen &&
                                <select onChange={(e) => this.handleVille(e.target.value)}>
                                    <option value="">Département</option>
                                    {
                                        this.state.depart !== null &&
                                        this.state.depart.map((value,index) => (
                                            <option key={index} value={value.departement}>{value.departement}</option>
                                        ))
                                    }
                                </select>
                            }
                            {
                                this.state.villeOpen &&
                                <select name="" id="" onChange={(e) => this.handleQuartier(e.target.value)}>
                                        <option value="">Ville</option>
                                        {
                                            this.state.villes !== null &&
                                            this.state.villes.map((value,index) => (
                                                <option key={index} value={value.ville}>{value.ville}</option>
                                            ))
                                        }
                                    </select>
                            }
                            {
                                this.state.locaConfigAchat !== null &&
                                this.state.locaConfigAchat.quartier &&
                                this.state.quartierOpen &&
                                this.state.quartiers !== null &&
                                this.state.quartiers.length > 1 &&
                                <select onChange={(e) => this.handleSelectFinal(e.target.value)}>
                                    <option value="">Quartier</option>
                                    {

                                        this.state.quartiers.map((value,index) => (
                                            <option key={index} value={value.quartier}>{value.quartier}</option>
                                        ))
                                    }
                                </select>
                            }
                             </div>
                        </div>
                        :
                        <div className="form">

                            <div className="block">
                            {
                                this.state.villeOpen &&
                                this.state.locaConfigAchat &&
                                this.state.locaConfigAchat.choix &&
                                <>
                                    {
                                        this.state.locaConfigAchat.choix.choix1 !== null &&

                                        <div
                                        className={this.state.localisation[this.state.id] ? this.state.localisation[this.state.id].ville ? this.state.localisation[this.state.id].ville === this.state.locaConfigAchat.choix.choix1  ? "selected btn formbtn" : "btn formbtn" : "btn formbtn" : "btn formbtn"}
                                        onClick={() => this.handleQuartier(this.state.locaConfigAchat.choix.choix1,this.state.id)}>
                                            {this.state.locaConfigAchat.choix.choix1}
                                        </div>
                                    }
                                    {
                                        this.state.locaConfigAchat.choix.choix2 !== null &&
                                        <div className={this.state.localisation[this.state.id] ? this.state.localisation[this.state.id].ville ? this.state.localisation[this.state.id].ville === this.state.locaConfigAchat.choix.choix2 ? "selected btn formbtn" : "btn formbtn" : "btn formbtn" : "btn formbtn"}
                                        onClick={() => this.handleQuartier(this.state.locaConfigAchat.choix.choix2,this.state.id)}>
                                            {this.state.locaConfigAchat.choix.choix2}
                                        </div>
                                    }
                                    {
                                        this.state.locaConfigAchat.choix.choix3 !== null &&
                                        <div
                                        className={this.state.localisation[this.state.id] ? this.state.localisation[this.state.id].ville ? this.state.localisation[this.state.id].ville === this.state.locaConfigAchat.choix.choix3 ? "selected btn formbtn" : "btn formbtn" : "btn formbtn" : "btn formbtn"}
                                        onClick={() => this.handleQuartier(this.state.locaConfigAchat.choix.choix3,this.state.id)}>
                                            {this.state.locaConfigAchat.choix.choix3}
                                        </div>
                                    }

                                </>

                            }
                        </div>
                    </div>
                }
            </>
        )
    }
}
