import React from "react";
import ReactDOM from "react-dom";
import LineLocaVente from "../localisation/LineLocaVente";

export default class FormProjetVente extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            types: JSON.parse(this.props.types),
            config: JSON.parse(this.props.config),
            lead: JSON.parse(this.props.lead),
            type: null,
            localisation: [],
            id: 0,
            nbPiece: null,
            superficie: null,
            email: null,
            password: null,
            region: null,
            departement: null,
            ville: null,
            quartier: null,
            budgetMax: null,
            phone: "",
            messageFlash:"",
        };
    }

    handleSelectType = (value) => {
        this.setState({
            type: value,
        });
    };

    handleOnTab = (value) => {
        let {localisation, id} = this.state;
        localisation[id] = value;
        this.setState({
            localisation,
            ville: value.ville,
        });
    };
    handleSelectNbPiece = (value) => {
        this.setState({nbPiece: value});
    }
    handleChangeSuperfici = (value) => {
        this.setState({
            superficie: value
        })
    }
    handleBudget = (value) => {
        this.setState({
            budgetMax: value
        })
    }
    handleSave = (e) => {
        e.preventDefault();
        let btn = document.querySelector("#addProjet");
        btn.textContent = "Validation en cours";
        btn.style.transition = '0ms';
        btn.style.width = '195px';
        btn.style.cursor = 'not-allowed';
        this.setState({emailNotValide: false});
        let {
            email,
            password,
            type,
            region,
            departement,
            ville,
            quartier,
            nbPiece,
            budgetMax,
            superficie,
            localisation,
            lead,
            phone
        } = this.state;
        if (type !== null) {
            let titre = type + " " + ville;
            if (password.length < 8) {
                this.setState({messageFlash: "Le mot de passe doit contenir 8 caractéres minium."});
            } else {
                if (this.validateEmail(email)) {
                    let piece = 0;
                    if (nbPiece === "Studio") {
                        piece = 0;
                    } else if (nbPiece === "+5") {
                        piece = 5;
                    } else {
                        piece = parseInt(nbPiece);
                    }
                    if (ville !== null) {
                        axios.post('/annoncer-votre-projet-d-achat-de-bien-immobillier/valider/vente', {
                            email: email,
                            tr: password,
                            type: type,
                            region: region,
                            departement: departement,
                            ville: ville,
                            quartier: quartier,
                            piece: parseInt(piece),
                            budgetMin: budgetMax,
                            surfaceMin: superficie,
                            localisation: localisation,
                            titre: titre,
                            lead_user_id: lead.id ? parseInt(lead.id) : 0,
                            lead_type: lead.type ? lead.type : "classique",
                            typeProjet: "vente",
                            telephone: phone,
                        }).then(res => {
                            if (res.data === "email deja utiliser") {
                                this.setState({emailNotValide: true});
                            } else {
                                if (res.data === 1) {
                                    this.setState({messageFlash: ""}, () => {
                                        document.location.replace('/');
                                    })
                                } else {
                                    let btn = document.querySelector("#addProjet");
                                    btn.textContent = "Validation";
                                    btn.style.transition = '0ms';
                                    btn.style.width = '185px';
                                    btn.style.cursor = 'pointer';
                                    this.setState({messageFlash: "Un probléme est survenu lors de l'enregistrement."})
                                }
                            }
                        }).catch(error => {
                            console.log(error);
                        })
                    } else {
                        let btn = document.querySelector("#addProjet");
                        btn.textContent = "Validation";
                        btn.style.transition = '0ms';
                        btn.style.width = '185px';
                        btn.style.cursor = 'pointer';
                        this.setState({messageFlash: "Veuillez selectionner une ville."});
                    }
                } else {
                    let btn = document.querySelector("#addProjet");
                    btn.textContent = "Validation";
                    btn.style.transition = '0ms';
                    btn.style.width = '185px';
                    btn.style.cursor = 'pointer';
                    this.setState({messageFlash: "Le format de votre adresse e-mail n'est pas valide."})
                }
            }
        }else{
            let btn = document.querySelector("#addProjet");
            btn.textContent = "Validation";
            btn.style.transition = '0ms';
            btn.style.width = '185px';
            btn.style.cursor = 'pointer';
            this.setState({messageFlash: "Veuillez selectionner un type de bien."})
        }
    }

    validateEmail(email) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/
        );
    };

    render() {
        return (
            <>
                {
                    this.state.messageFlash !== "" &&
                    <div className="alert alert-warning">
                        <p>{this.state.messageFlash}</p>
                    </div>
                }

            <div className="ecran">

                <div className="form">
                    <h2>Quel type de bien souhaitez-vous vendre?</h2>
                    <div className="block">
                        <div
                            className={
                                this.state.type !== null
                                    ? this.state.type === "Appartement"
                                        ? "selected btn formbtn"
                                        : "btn formbtn"
                                    : "btn formbtn"
                            }
                            onClick={() => this.handleSelectType("Appartement")}
                        >
                            Appartement
                        </div>
                        <div
                            className={
                                this.state.type !== null
                                    ? this.state.type === "Maison / Villa"
                                        ? "selected btn formbtn"
                                        : "btn formbtn"
                                    : "btn formbtn"
                            }
                            onClick={() =>
                                this.handleSelectType("Maison / Villa")
                            }
                        >
                            Maison / Villa
                        </div>
                        <div className="selectBlock">
                            <select onChange={(e) => this.handleSelectType(e.target.value)}>
                                <option value="">Autres</option>
                                {this.state.types.map((value, index) => (
                                    value.name !== "Appartement" && value.name !== "Maison / Villa" &&
                                    <option key={index} value={value.name}>
                                        {value.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <LineLocaVente
                    config={this.state.config}
                    type="Vente"
                    id={this.state.id}
                    handleOnTab={this.handleOnTab}
                />
                <div className="form alignDouble">
                    <div className="double">
                        {this.state.type !== "Terrain" && (
                            <>
                                <div className="formblock disflex">
                                    <h2>Composition</h2>
                                    <div className="blockNbPiece">
                                        <div
                                            className={this.state.nbPiece === "Studio" ? "nbpiece selected" : "nbpiece"}
                                            onClick={() => this.handleSelectNbPiece("Studio")}>
                                            Studio
                                        </div>
                                        <div className={this.state.nbPiece === "1" ? "nbpiece selected" : "nbpiece"}
                                             onClick={() => this.handleSelectNbPiece("1")}>
                                            1
                                        </div>
                                        <div className={this.state.nbPiece === "2" ? "nbpiece selected" : "nbpiece"}
                                             onClick={() => this.handleSelectNbPiece("2")}>
                                            2
                                        </div>
                                        <div className={this.state.nbPiece === "3" ? "nbpiece selected" : "nbpiece"}
                                             onClick={() => this.handleSelectNbPiece("3")}>
                                            3
                                        </div>
                                        <div className={this.state.nbPiece === "4" ? "nbpiece selected" : "nbpiece"}
                                             onClick={() => this.handleSelectNbPiece("4")}>
                                            4
                                        </div>
                                        <div className={this.state.nbPiece === "+5" ? "nbpiece selected" : "nbpiece"}
                                             onClick={() => this.handleSelectNbPiece("+5")}>
                                            +5
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="double">
                        <h2>Superficie</h2>
                        <input type="text" onChange={(e) => this.handleChangeSuperfici(e.target.value)}
                               placeholder="M²"/>
                    </div>
                </div>
                {
                    this.state.config.budgetmaxVente == 1 &&
                    <div className="form">
                        <h2>Prix net vendeur souhaité</h2>
                        <input onChange={(e) => this.handleBudget(e.target.value)} type="text" placeholder="€"/>
                    </div>
                }
                <div className="form createUser">
                    <h2>Vos identifiants</h2>
                    <input
                        type="text"
                        value={this.state.email}
                        onChange={(e) =>
                            this.setState({email: e.target.value})
                        }
                        placeholder="E-mail"
                    />
                    {this.state.emailNotValide && (
                        <span className="redColor">
                            L'email que vous avez renseigné est déjà utilisé.
                        </span>
                    )}
                    <input
                        type="password"
                        value={this.state.password}
                        onChange={(e) =>
                            this.setState({password: e.target.value})
                        }
                        placeholder="Mot de passe"
                    /><br/>
                    <span>
                        Le mot de passe doit contenir 8 caractères minimum
                    </span>
                    <br/>

                </div>
                <div className="form ">
                    <input type="text" placeholder="Votre numéro (recommandé mais pas obligatoire)"
                           onChange={(e) => this.setState({phone: e.target.value})} value={this.state.phone}/>
                </div>
                <div className="action">
                    <a href="" id="addProjet" onClick={(e) => this.handleSave(e)}>
                        Valider <span>{">"}</span>
                    </a>
                </div>
            </div>
            </>
        );
    }
}
if (document.getElementById("miniFormProjetVente")) {
    const element = document.getElementById("miniFormProjetVente");
    let props = Object.assign({}, element.dataset);
    ReactDOM.render(
        <FormProjetVente {...props} />,
        document.getElementById("miniFormProjetVente")
    );
}
