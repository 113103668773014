import ReactDOM from "react-dom";
import React from "react";
import axios from "axios";

import Moment from 'react-moment';

export default class SubcriptionCancel extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            subcriptions: JSON.parse(this.props.sub),
            subSave: JSON.parse(this.props.sub),
            openModalCancel:false,
            currentSub:{},
            checkedTout:true,
            checkedCours:false,
            checkedAnnuler:false,
            modalFacture:false,
            currentSub:null,
            factures:null,
        }
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleCancelSub = this.handleCancelSub.bind(this);
        this.handleFiltre = this.handleFiltre.bind(this);
        this.handleLaunchFacture = this.handleLaunchFacture.bind(this);
        this.handleOpenModalFacture = this.handleOpenModalFacture.bind(this);
        this.handleCloseModalFacture = this.handleCloseModalFacture.bind(this);
    }
    handleOpenModal(e,index){
        e.preventDefault()
        let currentSub = this.state.subcriptions[index];
        this.setState({openModalCancel:true,currentSub});
    }
    handleCloseModal(e){
        e.preventDefault();
        this.setState({openModalCancel:false,currentSub:{}});
    }
    handleCancelSub(e){
        e.preventDefault();
        const {currentSub} = this.state;
        axios.post('/professionnel/mon-abonnement/desabonnement',{
            user_id: currentSub.user_id,
            token_desabo: currentSub.token_desabo,
        }).then(res => {
            console.log(res.data);
            //this.setState({subcriptions:res.data});
        }).catch(error => {
            console.log(error)
        })
    }
    handleFiltre(value){
        let {subcriptions,subSave} = this.state;
        console.log(value);
        if(value === "tout"){
            subcriptions = subSave;
        }else if(value === "cours"){
            let newSub = subSave.filter(sub => sub.abonnement === 1);
            subcriptions = newSub;
        }else{
            let newSub = subSave.filter(sub => sub.abonnement === 0);
            subcriptions = newSub;
        }
        this.setState({subcriptions});
    }
    handleCloseModalFacture(){
        this.setState({modalFacture:false,currentSub:null,factures:null});
    }
    handleOpenModalFacture(e,index){
        e.preventDefault();
        axios.post('/professionnel/mon-abonnement/search',{
            id:this.state.subcriptions[index].id
        }).then(res => {
            this.setState({factures:res.data},() => {
                this.setState({modalFacture:true,currentSub:this.state.subcriptions[index]})
            });
        }).catch(error => {
            console.log(error);
        });
    }
    handleLaunchFacture(e,index){
        e.preventDefault();
        let {subcriptions,factures} = this.state;
        let fac = factures[index];
        let dataPdf = subcriptions.filter(sub => sub.id = fac.abo_id);
        dataPdf = dataPdf[0];
        let url = "/professionnel/"+dataPdf.token_desabo+"/facture/"+dataPdf.id+"/"+fac.id;
        window.open(url,'_blank');
    }
    financial(x) {
        return Number.parseFloat(x).toFixed(2);
    }
    render(){
        return(
            <>
                <div className="blockfiltre">
                    <div className="block" >
                        <label><input type="radio" id="tout"  name="filtre" onChange={() => this.handleFiltre('tout')} />Toutes les abonnements</label>
                    </div>
                    <div className="block" >
                        <label><input type="radio" id="cours"  name="filtre" onChange={() => this.handleFiltre('cours')} />Les abonnements en cours</label>
                    </div>
                    <div className="block" >
                        <label><input type="radio" id="annuler" name="filtre"  onChange={() => this.handleFiltre('annuler')} />Les abonnements annulés</label>
                    </div>
                </div>
                <div className="tableHeader">
                    <div className="trHeader">Description</div>
                    <div className="trHeader">Prix</div>
                    <div className="trHeader">Action</div>
                </div>
                <div className="tableBody">
                    {
                        this.state.subcriptions.map((value,index) => (
                            <div className="tableLine" key={index}>
                                <div className="trBody">{value.description}</div>
                                <div className="trBody">{this.financial(value.amount)} € TTC</div>
                                {
                                    value.abonnement === 1 ?
                                    <div className="trBody">
                                        <a href="" onClick={(e) => this.handleOpenModal(e,index)}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="#2f2f2f" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V3.75H5C4.58579 3.75 4.25 4.08579 4.25 4.5C4.25 4.91421 4.58579 5.25 5 5.25H19C19.4142 5.25 19.75 4.91421 19.75 4.5C19.75 4.08579 19.4142 3.75 19 3.75H14.75V3C14.75 2.58579 14.4142 2.25 14 2.25H10Z" />
                                            <path d="M10 10.65C10.4142 10.65 10.75 10.9858 10.75 11.4L10.75 18.4C10.75 18.8142 10.4142 19.15 10 19.15C9.58579 19.15 9.25 18.8142 9.25 18.4L9.25 11.4C9.25 10.9858 9.58579 10.65 10 10.65Z" />
                                            <path d="M14.75 11.4C14.75 10.9858 14.4142 10.65 14 10.65C13.5858 10.65 13.25 10.9858 13.25 11.4V18.4C13.25 18.8142 13.5858 19.15 14 19.15C14.4142 19.15 14.75 18.8142 14.75 18.4V11.4Z" />
                                            <path  d="M5.99142 7.91718C6.03363 7.53735 6.35468 7.25 6.73684 7.25H17.2632C17.6453 7.25 17.9664 7.53735 18.0086 7.91718L18.2087 9.71852C18.5715 12.9838 18.5715 16.2793 18.2087 19.5446L18.189 19.722C18.045 21.0181 17.0404 22.0517 15.7489 22.2325C13.2618 22.5807 10.7382 22.5807 8.25108 22.2325C6.95954 22.0517 5.955 21.0181 5.81098 19.722L5.79128 19.5446C5.42846 16.2793 5.42846 12.9838 5.79128 9.71852L5.99142 7.91718ZM7.40812 8.75L7.2821 9.88417C6.93152 13.0394 6.93152 16.2238 7.2821 19.379L7.3018 19.5563C7.37011 20.171 7.84652 20.6612 8.45905 20.747C10.8082 21.0758 13.1918 21.0758 15.5409 20.747C16.1535 20.6612 16.6299 20.171 16.6982 19.5563L16.7179 19.379C17.0685 16.2238 17.0685 13.0394 16.7179 9.88417L16.5919 8.75H7.40812Z" />
                                            </svg>
                                        </a>
                                        <a href="" onClick={(e) => this.handleOpenModalFacture(e,index)}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="#2f2f2f" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.4549 5.41575C21.6471 5.70687 21.615 6.10248 21.3588 6.35876L12.1664 15.5511C12.0721 15.6454 11.9545 15.7128 11.8256 15.7465L7.99716 16.7465C7.87229 16.7791 7.74358 16.7784 7.62265 16.7476C7.49408 16.7149 7.37431 16.6482 7.27729 16.5511C7.08902 16.3629 7.01468 16.0889 7.08197 15.8313L8.08197 12.0028C8.11144 11.89 8.16673 11.7786 8.24322 11.6912L17.4697 2.46967C17.5504 2.38891 17.6477 2.32846 17.7536 2.29163C17.8321 2.26432 17.9153 2.25 18 2.25C18.1989 2.25 18.3897 2.32902 18.5303 2.46967L21.3588 5.2981C21.3954 5.33471 21.4274 5.37416 21.4549 5.41575ZM19.7678 5.82843L18 4.06066L9.48184 12.5788L8.85685 14.9716L11.2496 14.3466L19.7678 5.82843Z" />
                                            <path d="M19.6414 17.1603C19.9148 14.8227 20.0018 12.4688 19.9023 10.1208C19.8976 10.0084 19.9399 9.89898 20.0194 9.81942L21.0027 8.83609C21.1236 8.71519 21.3302 8.79194 21.3415 8.96254C21.5265 11.7522 21.4564 14.5545 21.1312 17.3346C20.8946 19.3571 19.2703 20.9421 17.2583 21.167C13.7917 21.5544 10.2083 21.5544 6.74177 21.167C4.72971 20.9421 3.10538 19.3571 2.86883 17.3346C2.45429 13.7903 2.45429 10.2097 2.86883 6.66543C3.10538 4.6429 4.72971 3.05789 6.74177 2.83301C9.37152 2.5391 12.0685 2.46815 14.7306 2.62016C14.9022 2.62996 14.9804 2.83757 14.8589 2.95909L13.8664 3.95165C13.7877 4.03034 13.6798 4.07261 13.5685 4.06885C11.3421 3.99376 9.10055 4.07872 6.90838 4.32373C5.57827 4.47239 4.51278 5.522 4.35867 6.83968C3.95767 10.2682 3.95767 13.7318 4.35867 17.1603C4.51278 18.478 5.57827 19.5276 6.90838 19.6763C10.2642 20.0513 13.7358 20.0513 17.0916 19.6763C18.4218 19.5276 19.4872 18.478 19.6414 17.1603Z" />
                                            </svg>
                                        </a>
                                    </div>

                                    :
                                    <div className="trBody">
                                        <svg width="40" height="40" viewBox="0 0 24 24" fill="#ff0000" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.46445 15.5354L15.5355 8.46436" stroke="#ff0000" />
                                            <path d="M8.46446 8.46458L15.5355 15.5356" stroke="#ff0000" />
                                        </svg>

                                        <a href="" onClick={(e) => this.handleOpenModalFacture(e,index)}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="#2f2f2f" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.4549 5.41575C21.6471 5.70687 21.615 6.10248 21.3588 6.35876L12.1664 15.5511C12.0721 15.6454 11.9545 15.7128 11.8256 15.7465L7.99716 16.7465C7.87229 16.7791 7.74358 16.7784 7.62265 16.7476C7.49408 16.7149 7.37431 16.6482 7.27729 16.5511C7.08902 16.3629 7.01468 16.0889 7.08197 15.8313L8.08197 12.0028C8.11144 11.89 8.16673 11.7786 8.24322 11.6912L17.4697 2.46967C17.5504 2.38891 17.6477 2.32846 17.7536 2.29163C17.8321 2.26432 17.9153 2.25 18 2.25C18.1989 2.25 18.3897 2.32902 18.5303 2.46967L21.3588 5.2981C21.3954 5.33471 21.4274 5.37416 21.4549 5.41575ZM19.7678 5.82843L18 4.06066L9.48184 12.5788L8.85685 14.9716L11.2496 14.3466L19.7678 5.82843Z" />
                                            <path d="M19.6414 17.1603C19.9148 14.8227 20.0018 12.4688 19.9023 10.1208C19.8976 10.0084 19.9399 9.89898 20.0194 9.81942L21.0027 8.83609C21.1236 8.71519 21.3302 8.79194 21.3415 8.96254C21.5265 11.7522 21.4564 14.5545 21.1312 17.3346C20.8946 19.3571 19.2703 20.9421 17.2583 21.167C13.7917 21.5544 10.2083 21.5544 6.74177 21.167C4.72971 20.9421 3.10538 19.3571 2.86883 17.3346C2.45429 13.7903 2.45429 10.2097 2.86883 6.66543C3.10538 4.6429 4.72971 3.05789 6.74177 2.83301C9.37152 2.5391 12.0685 2.46815 14.7306 2.62016C14.9022 2.62996 14.9804 2.83757 14.8589 2.95909L13.8664 3.95165C13.7877 4.03034 13.6798 4.07261 13.5685 4.06885C11.3421 3.99376 9.10055 4.07872 6.90838 4.32373C5.57827 4.47239 4.51278 5.522 4.35867 6.83968C3.95767 10.2682 3.95767 13.7318 4.35867 17.1603C4.51278 18.478 5.57827 19.5276 6.90838 19.6763C10.2642 20.0513 13.7358 20.0513 17.0916 19.6763C18.4218 19.5276 19.4872 18.478 19.6414 17.1603Z" />
                                            </svg>
                                        </a>
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>
                {/* MODAL Flash MESSAGE */}
                {
                    this.state.openModalCancel &&
                    <div className="modal modalDelete">
                        <div className="backgroundModal">
                            <div className="contentModal">
                                <div className="message">
                                    <p>Voulez-vous vraiment anuuler votre abonnement:</p>
                                    <p>{this.state.currentSub.description}</p>
                                </div>
                                <div className="action blue">
                                    <a href="" onClick={(e) => this.handleCloseModal(e)}>Annuler</a>
                                    <a href="" onClick={(e) => this.handleCancelSub(e)}>Se désabonner</a>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.modalFacture &&
                    <div className="modal modalEdit">
                        <div className="backgroundModal">
                            <div className="contentModal">
                                <div className="close" onClick={this.handleCloseModalFacture}>
                                    <svg width="40" height="40" viewBox="0 0 24 24" fill="#2f2f2f"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.46445 15.5354L15.5355 8.46436" stroke="#2f2f2f"/>
                                        <path d="M8.46446 8.46458L15.5355 15.5356" stroke="#2f2f2f"/>
                                    </svg>
                                </div>
                                <div className="tableHeader">
                                    <div className="trHeader">Date</div>
                                    <div className="trHeader">Prix</div>
                                    <div className="trHeader">Action</div>
                                </div>
                                <div className="tableBody">
                                    {
                                        this.state.factures.map((value,index) => (
                                            <div className="tableLine" key={index}>
                                                <div className="trBody">
                                                    du {value.dateStart} au {value.dateEnd}
                                                </div>
                                                <div className="trBody">{this.financial(value.amount)} € TTC</div>
                                                <div className="trBody">
                                                    <a href="" onClick={(e) => this.handleLaunchFacture(e,index)}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#2f2f2f" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.4549 5.41575C21.6471 5.70687 21.615 6.10248 21.3588 6.35876L12.1664 15.5511C12.0721 15.6454 11.9545 15.7128 11.8256 15.7465L7.99716 16.7465C7.87229 16.7791 7.74358 16.7784 7.62265 16.7476C7.49408 16.7149 7.37431 16.6482 7.27729 16.5511C7.08902 16.3629 7.01468 16.0889 7.08197 15.8313L8.08197 12.0028C8.11144 11.89 8.16673 11.7786 8.24322 11.6912L17.4697 2.46967C17.5504 2.38891 17.6477 2.32846 17.7536 2.29163C17.8321 2.26432 17.9153 2.25 18 2.25C18.1989 2.25 18.3897 2.32902 18.5303 2.46967L21.3588 5.2981C21.3954 5.33471 21.4274 5.37416 21.4549 5.41575ZM19.7678 5.82843L18 4.06066L9.48184 12.5788L8.85685 14.9716L11.2496 14.3466L19.7678 5.82843Z" />
                                                        <path d="M19.6414 17.1603C19.9148 14.8227 20.0018 12.4688 19.9023 10.1208C19.8976 10.0084 19.9399 9.89898 20.0194 9.81942L21.0027 8.83609C21.1236 8.71519 21.3302 8.79194 21.3415 8.96254C21.5265 11.7522 21.4564 14.5545 21.1312 17.3346C20.8946 19.3571 19.2703 20.9421 17.2583 21.167C13.7917 21.5544 10.2083 21.5544 6.74177 21.167C4.72971 20.9421 3.10538 19.3571 2.86883 17.3346C2.45429 13.7903 2.45429 10.2097 2.86883 6.66543C3.10538 4.6429 4.72971 3.05789 6.74177 2.83301C9.37152 2.5391 12.0685 2.46815 14.7306 2.62016C14.9022 2.62996 14.9804 2.83757 14.8589 2.95909L13.8664 3.95165C13.7877 4.03034 13.6798 4.07261 13.5685 4.06885C11.3421 3.99376 9.10055 4.07872 6.90838 4.32373C5.57827 4.47239 4.51278 5.522 4.35867 6.83968C3.95767 10.2682 3.95767 13.7318 4.35867 17.1603C4.51278 18.478 5.57827 19.5276 6.90838 19.6763C10.2642 20.0513 13.7358 20.0513 17.0916 19.6763C18.4218 19.5276 19.4872 18.478 19.6414 17.1603Z" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

if (document.getElementById('subcriptionCancel')) {
    const element = document.getElementById('subcriptionCancel');
    let props = Object.assign({}, element.dataset);
    ReactDOM.render(<SubcriptionCancel {...props}/>, document.getElementById('subcriptionCancel'));
}
