import React from "react";
import ReactDOM from 'react-dom';

import LineSelect from './LineSelect';

import axios from "axios";



export default class Perimetre extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regions: JSON.parse(this.props.regions),
            interventions:JSON.parse(this.props.inter),
            nb:[1],
            i:1,
            perimetre: [],
            tab:[],
            modif:false,
        }
        this.handleAddLine = this.handleAddLine.bind(this);
        this.handleOnTab = this.handleOnTab.bind(this);
    }

    componentDidMount() {
        let {i,nb,tab,interventions} = this.state;
        let blockService = document.querySelector(".blockcontent .bloclService");
        let blockpassword = document.querySelector(".blockcontent .blockpasswword");
        let valideform = document.querySelector(".blockcontent .formAlign .valide_form");
        let app = document.querySelector("footer");
        if(this.state.interventions[0]){
            i = this.state.interventions.length
            blockService.style.top = (1840 + (120 * i))+ "px";
            blockpassword.style.top = (2410 + (120 * i))+ "px";
            valideform.style.top = (2770 + (120 * i))+ "px";
            app.style.marginTop = (750 * i)+ "px";
            for (let j = 1; j<i;j++){
                nb.push(1);
            }
            interventions.map(value => {
                let t = [value.region,value.departement,value.ville,value.quartier];
                tab.push(t)
            })
            this.setState({i,nb,tab});
        }
    }


    handleAddLine() {
        let {nb,i} = this.state;
        nb.push(1);
        i++;
        this.setState({nb,i,modif:true});
        let blockService = document.querySelector(".blockcontent .bloclService");
        blockService.style.top = (1840 + (120 * i))+ "px";
        let blockpassword = document.querySelector(".blockcontent .blockpasswword");
        blockpassword.style.top = (2410 + (120 * i))+ "px";
        let valideform = document.querySelector(".blockcontent .formAlign .valide_form");
        valideform.style.top = (2770 + (120 * i))+ "px";
        let app = document.querySelector("footer");
        app.style.marginTop = (750 + (120 * i))+ "px";


    }
    handleOnTab(tab){
        this.setState({tab,modif:false});
    }

    render() {
        return (
            <>
            <React.StrictMode>
                <div id="lineselectperimetre">
                    {
                        this.state.nb.map((value,index) => (
                            <div key={index} className="blockSelectLine">
                                <LineSelect modif={this.state.modif} i={this.state.i} line={index} regions={this.state.regions} tab={this.state.tab} handleOnTab={this.handleOnTab}/>
                            </div>
                        ))
                    }
                </div>

                <div className="addperimettre">
                    <div className="btn btn_add" onClick={this.handleAddLine}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.013" height="15.013"
                             viewBox="0 0 15.013 15.013">
                            <g id="Icon_ionic-md-add-circle-outline" data-name="Icon ionic-md-add-circle-outline"
                               transform="translate(-3.375 -3.375)">
                                <path id="Tracé_8" data-name="Tracé 8"
                                      d="M18.2,15.1H15.1v3.1H13.58V15.1h-3.1V13.58h3.1v-3.1H15.1v3.1h3.1Z"
                                      transform="translate(-3.457 -3.457)" fill="#2f2f2f"/>
                                <path id="Tracé_9" data-name="Tracé 9"
                                      d="M10.881,4.891A5.988,5.988,0,1,1,6.645,6.645a5.966,5.966,0,0,1,4.237-1.754m0-1.516a7.506,7.506,0,1,0,7.506,7.506,7.505,7.505,0,0,0-7.506-7.506Z"
                                      fill="#2f2f2f"/>
                            </g>
                        </svg>
                        <span>Ajouter</span>
                    </div>
                </div>

                <input type="hidden" name="perimetre" id="perimettreMultiSelect" value={JSON.stringify(this.state.tab)}/>
            </React.StrictMode>
            </>
        )
    }
}

if (document.getElementById('perimetre')) {
    const element = document.getElementById('perimetre');
    let props = Object.assign({}, element.dataset);
    ReactDOM.render(<Perimetre {...props}/>, document.getElementById('perimetre'));
}
