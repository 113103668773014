import React from "react";
import ReactDOM from 'react-dom';

export default class CheckedChasse extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ventes:JSON.parse(this.props.value),
            values:[],
            pscs:JSON.parse(this.props.pscs),
            tab:[],
        }
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        const {pscs,tab,values} = this.state;
        pscs.map(value => {
            tab.push(value.service_chasse_id);
            values.push(""+value.service_chasse_id+"")
            let vente = document.querySelector('#chasse');
                vente.value = JSON.stringify(values);
        })
        this.setState({tab,values})
    }

    handleSelect(value){
        let {values,tab} = this.state;
        if(values.indexOf(value) === -1) {
            values.push(value);
            tab.push(parseInt(value));
            this.setState({values,tab}, () => {
                let vente = document.querySelector('#chasse');
                vente.value = JSON.stringify(values);
            });
        }else{
            const i = values.indexOf(value);
            values.splice(i, 1);
            tab.splice(i, 1)
            this.setState({values,tab});
            let vente = document.querySelector('#chasse');
                vente.value = JSON.stringify(values);
        }
    }
    render(){
        return (
            <div>
                {
                    this.state.ventes.map((value,index) => (
                        <div className="checkChasse" key={index}>
                            <input  type="checkbox" checked={this.state.tab.indexOf(value.id) !== -1 ? "checked" : ""} value={value.id} onChange={(e) => this.handleSelect(e.target.value)} /><label>{value.name}</label>
                        </div>
                    ))
                }
            </div>
        )
    }
}
if (document.getElementById('checkedChasse')) {
    const element = document.getElementById('checkedChasse');
    let props = Object.assign({}, element.dataset);
    ReactDOM.render(<CheckedChasse {...props}/>, document.getElementById('checkedChasse'));
}
