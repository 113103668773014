import React from 'react';
import ReactDOM from 'react-dom'
import LineLocaAchat from "../localisation/LineLocaAchat";

export default class FormProjetAchat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            types: JSON.parse(this.props.types),
            config: JSON.parse(this.props.config),
            lead: JSON.parse(this.props.lead),
            achatLouer: null,
            type: null,
            localisation: [],
            id: 0,
            nbPiece: null,
            superficie: null,
            email: null,
            password: null,
            region: null,
            departement: null,
            ville: null,
            quartier: null,
            budgetMax: null,
            line: [1],
            chemin: this.props.chemin,
            phone: "",
            messageFlash: "",
        }
    };

    componentDidMount() {
        const {config} = this.state;
        if (config.location === 0 && config.achatAchat === 1) {
            this.setState({
                achatLouer: "Acheter"
            })
        } else if (config.location === 1 && config.achatAchat === 0) {
            this.setState({
                achatLouer: "Louer"
            })
        }
    }

    handleSelectAchatLoca = (value) => {
        this.setState({achatLouer: value})
    };
    handleSelectType = (value) => {
        this.setState({
            type: value,
        });
    };
    handleOnTab = (value, id) => {
        let {localisation} = this.state;
        localisation = value;
        this.setState({
            localisation,
            ville: value.ville,
        });
    };
    handleSelectNbPiece = (value) => {
        this.setState({
            nbPiece: value
        })
    };
    handleChangeSuperfici = (value) => {
        this.setState({
            superficie: value
        })
    };
    handleBudget = (value) => {
        this.setState({
            budgetMax: value
        })
    };
    handleSave = (e) => {
        e.preventDefault();
        let btn = document.querySelector("#addProjet");
        btn.textContent = "Validation en cours";
        btn.style.transition = '0ms';
        btn.style.width = '185px';
        btn.style.cursor = 'not-allowed';
        this.setState({emailNotValide: false});
        let {
            email,
            password,
            type,
            region,
            departement,
            ville,
            quartier,
            nbPiece,
            budgetMax,
            superficie,
            localisation,
            chemin,
            lead,
            phone,
        } = this.state;
        if (type !== null) {
        let titre = type + " " + localisation[0].ville;
        if (password.length < 8) {
            this.setState({messageFlash: "Le mot de passe doit contenir 8 charactéres minium."});
        } else {
            if (this.validateEmail(email)) {
                let piece = 0;
                if (nbPiece === "Studio") {
                    piece = 0;
                } else if (nbPiece !== "+5") {
                    piece = parseInt(nbPiece);
                } else {
                    piece = 5;
                }
                if (ville !== null) {
                    axios.post('/annoncer-votre-projet-d-achat-de-bien-immobillier/valider/achat', {
                        email: email,
                        tr: password,
                        type: type,
                        region: region,
                        departement: departement,
                        ville: ville,
                        quartier: quartier,
                        piece: parseInt(nbPiece),
                        budgetMin: budgetMax,
                        surfaceMin: superficie,
                        localisation: localisation,
                        chemin: chemin,
                        titre: titre,
                        lead_user_id: lead.id ? parseInt(lead.id) : 0,
                        lead_type: lead.type ? lead.type : "classique",
                        typeProjet: "achat",
                        telephone: phone,

                    }).then(res => {
                        if (res.data === "email deja utiliser") {
                            this.setState({emailNotValide: true});
                        } else {
                            if (res.data === 1) {
                                this.setState({messageFlash: ""}, () => {
                                    document.location.replace('/');
                                })
                            } else {
                                let btn = document.querySelector("#addProjet");
                                btn.textContent = "Validation";
                                btn.style.transition = '0ms';
                                btn.style.width = '185px';
                                btn.style.cursor = 'pointer';
                                this.setState({messageFlash: "Un probléme est survenu lors de l'enregistrement."})
                            }
                        }
                    }).catch(error => {
                        console.log(error);
                    })
                } else {
                    let btn = document.querySelector("#addProjet");
                    btn.textContent = "Validation";
                    btn.style.transition = '0ms';
                    btn.style.width = '185px';
                    btn.style.cursor = 'pointer';
                    this.setState({messageFlash: "Veuillez selectionner une ville."});
                }
            } else {
                let btn = document.querySelector("#addProjet");
                btn.textContent = "Validation";
                btn.style.transition = '0ms';
                btn.style.width = '185px';
                btn.style.cursor = 'pointer';
                this.setState({messageFlash: "Le format de votre adresse e-mail n'est pas valide."})
            }
        }
        }else{
            let btn = document.querySelector("#addProjet");
            btn.textContent = "Validation";
            btn.style.transition = '0ms';
            btn.style.width = '185px';
            btn.style.cursor = 'pointer';
            this.setState({messageFlash: "Veuillez selectionner un type de bien."})
        }
    }
    validateEmail(email) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/
        );
    };

    handleAddLine = (e) => {
        e.preventDefault();
        let {line, id} = this.state;
        line.push(1);
        id++;
        this.setState({line, id});
    }

    render() {
        return (
            <>
                {
                    this.state.messageFlash !== "" &&
                    <div className="alert alert-warning">
                        <p>{this.state.messageFlash}</p>
                    </div>
                }
                <div className="ecran">
                    {
                        this.state.config.location === 1 && this.state.config.achatAchat === 1 &&
                        <div className="form">
                            <h2>Souhaitez-vous acheter ou louer ?</h2>
                            <div className="block">
                                <div
                                    className={
                                        this.state.achatLouer !== null
                                            ? this.state.achatLouer === "Acheter"
                                                ? "selected btn formbtn"
                                                : "btn formbtn"
                                            : "btn formbtn"
                                    }
                                    onClick={() => this.handleSelectAchatLoca("Acheter")}
                                >
                                    Acheter
                                </div>

                                <div
                                    className={
                                        this.state.achatLouer !== null
                                            ? this.state.achatLouer === "Louer"
                                                ? "selected btn formbtn"
                                                : "btn formbtn"
                                            : "btn formbtn"
                                    }
                                    onClick={() => this.handleSelectAchatLoca("Louer")}
                                >
                                    Louer
                                </div>
                            </div>
                        </div>
                    }
                    <div className="form">
                        <h2>Quel type de bien recherchez vous ?</h2>
                        <div className="block">
                            <div
                                className={
                                    this.state.type !== null
                                        ? this.state.type === "Appartement"
                                            ? "selected btn formbtn"
                                            : "btn formbtn"
                                        : "btn formbtn"
                                }
                                onClick={() => this.handleSelectType(this.state.types[0].name)}
                            >
                                {this.state.types[0].name}
                            </div>
                            <div
                                className={
                                    this.state.type !== null
                                        ? this.state.type === "Maison / Villa"
                                            ? "selected btn formbtn"
                                            : "btn formbtn"
                                        : "btn formbtn"
                                }
                                onClick={() =>
                                    this.handleSelectType(this.state.types[1].name)
                                }
                            >
                                {this.state.types[1].name}
                            </div>
                            <div className="selectBlock">
                                <select onChange={(e) => this.handleSelectType(e.target.value)}>
                                    <option value="">Autres</option>
                                    {this.state.types.map((value, index) => (
                                        index !== 0 && index !== 1 &&
                                        <option key={index} value={value.name}>
                                            {value.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form">
                        <h2>Où se situe votre recherche de bien</h2>
                        {
                            this.state.line.map((value, index) => (
                                <LineLocaAchat
                                    config={this.state.config}
                                    type="Achat"
                                    id={this.state.id}
                                    handleOnTab={this.handleOnTab}
                                    localisation={this.state.localisation}
                                />
                            ))
                        }
                        <a href="" className="addLine" onClick={(e) => this.handleAddLine(e)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.013" height="15.013"
                                 viewBox="0 0 15.013 15.013">
                                <g id="Icon_ionic-md-add-circle-outline" data-name="Icon ionic-md-add-circle-outline"
                                   transform="translate(-3.375 -3.375)">
                                    <path id="Tracé_8" data-name="Tracé 8"
                                          d="M18.2,15.1H15.1v3.1H13.58V15.1h-3.1V13.58h3.1v-3.1H15.1v3.1h3.1Z"
                                          transform="translate(-3.457 -3.457)" fill="#2f2f2f"/>
                                    <path id="Tracé_9" data-name="Tracé 9"
                                          d="M10.881,4.891A5.988,5.988,0,1,1,6.645,6.645a5.966,5.966,0,0,1,4.237-1.754m0-1.516a7.506,7.506,0,1,0,7.506,7.506,7.505,7.505,0,0,0-7.506-7.506Z"
                                          fill="#2f2f2f"/>
                                </g>
                            </svg>
                            <span>Ajouter</span>
                        </a>
                    </div>
                    <div className="form alignDouble">
                        <div className="double">
                            {this.state.type !== "Terrain" && (
                                <>
                                    <div className="formblock disflex">
                                        <h2>Composition</h2>
                                        <div className="blockNbPiece">
                                            <div
                                                className={this.state.nbPiece === "Studio" ? "nbpiece selected" : "nbpiece"}
                                                onClick={() => this.handleSelectNbPiece("Studio")}>
                                                Studio
                                            </div>
                                            <div className={this.state.nbPiece === "1" ? "nbpiece selected" : "nbpiece"}
                                                 onClick={() => this.handleSelectNbPiece("1")}>
                                                1
                                            </div>
                                            <div className={this.state.nbPiece === "2" ? "nbpiece selected" : "nbpiece"}
                                                 onClick={() => this.handleSelectNbPiece("2")}>
                                                2
                                            </div>
                                            <div className={this.state.nbPiece === "3" ? "nbpiece selected" : "nbpiece"}
                                                 onClick={() => this.handleSelectNbPiece("3")}>
                                                3
                                            </div>
                                            <div className={this.state.nbPiece === "4" ? "nbpiece selected" : "nbpiece"}
                                                 onClick={() => this.handleSelectNbPiece("4")}>
                                                4
                                            </div>
                                            <div
                                                className={this.state.nbPiece === "+5" ? "nbpiece selected" : "nbpiece"}
                                                onClick={() => this.handleSelectNbPiece("+5")}>
                                                +5
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="double">
                            <h2>Superficie</h2>
                            <input type="text" onChange={(e) => this.handleChangeSuperfici(e.target.value)}
                                   placeholder="M²"/>
                        </div>
                    </div>
                    {
                        this.state.config.budgetmaxAchat == 1 &&
                        <div className="form">
                            <h2>Budget Max</h2>
                            <input type="text" onChange={(e) => this.handleBudget(e.target.value)} placeholder="€"/>
                        </div>
                    }
                    <div className="form createUser">
                        <h2>Vos identifiants</h2>
                        <input
                            type="text"
                            value={this.state.email}
                            onChange={(e) =>
                                this.setState({email: e.target.value})
                            }
                            placeholder="E-mail"
                        />
                        {this.state.emailNotValide && (
                            <span className="redColor">
                            L'email que vous avez renseigné est déjà utilisé.
                        </span>
                        )}
                        <input
                            type="password"
                            value={this.state.password}
                            onChange={(e) =>
                                this.setState({password: e.target.value})
                            }
                            placeholder="Mot de passe"
                        />
                        <span>
                        Le mot de passe doit contenir 8 caractères minimum
                    </span>
                    </div>
                    <div className="form ">
                        <input type="text" placeholder="Votre numéro (recommandé mais pas obligatoire)"
                               onChange={(e) => this.setState({phone: e.target.value})} value={this.state.phone}/>
                    </div>
                    <div className="action">
                        <a href="" id="addProjet" onClick={(e) => this.handleSave(e)}>
                            Valider <span>{">"}</span>
                        </a>
                    </div>
                </div>
            </>
        )
    }
}
if (document.getElementById('miniFormProjetAchat')) {
    const element = document.getElementById('miniFormProjetAchat');
    let props = Object.assign({}, element.dataset);
    ReactDOM.render(<FormProjetAchat {...props}/>, document.getElementById('miniFormProjetAchat'));
}
