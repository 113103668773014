import React from "react";
import ReactDOM from 'react-dom';

import axios from "axios";

export default class LineSelectConsult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nb: this.props.nb,
            regions: this.props.regions,
            i: this.props.i,
            departements: null,
            villes: null,
            quartiers: null,
            region: "",
            departement: "",
            ville: "",
            quartier: "",
            launcher:false,
        }
        this.handleSelectRegion = this.handleSelectRegion.bind(this);
        this.handleSelectDepartement = this.handleSelectDepartement.bind(this);
        this.handleSelectVille = this.handleSelectVille.bind(this);
        this.handleSelectQuartier = this.handleSelectQuartier.bind(this);
    }
    componentDidMount() {
        this.setState({
            launcher:true
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.tab !== null) {
            setTimeout(() => {
                this.setState({
                    region: prevProps.tab[0],
                    departement: prevProps.tab[1],
                    ville: prevProps.tab[2],
                    quartier: prevProps.tab[3],
                }, () => {
                    if (this.state.departements === null) {
                        axios.post('/location/departement', {
                            region: prevProps.tab[0],
                        }).then(res => {
                            this.setState({departements: res.data}, () => {
                                axios.post('/location/ville', {
                                    departement: prevProps.tab[1],
                                }).then(res => {
                                    this.setState({villes: res.data}, () => {
                                        axios.post('/location/quartier', {
                                            ville: prevProps.tab[2],
                                        }).then(res => {
                                            this.setState({quartiers: res.data});
                                        }).catch(error => {

                                        })
                                    });
                                }).catch(error => {

                                })
                            });
                        }).catch(error => {

                        })
                    }
                })
            }, 1000)
        }
    }

    handleSelectRegion(value) {
        axios.post('/location/departement', {
            region: value,
        }).then(res => {
            this.setState({departements: res.data, region: value}, () => {
                let result = [this.state.region];
                let tab = result;
                this.props.handleOnTab(tab)
            });
        }).catch(error => {

        })
    }

    handleSelectDepartement(value) {
        axios.post('/location/ville', {
            departement: value,
        }).then(res => {
            this.setState({villes: res.data, departement: value}, () => {
                let result = [this.state.region, this.state.departement];
                let tab = result;
                this.props.handleOnTab(tab)
            });
        }).catch(error => {

        })
    }

    handleSelectVille(value) {
        axios.post('/location/quartier', {
            ville: value,
        }).then(res => {
            this.setState({quartiers: res.data, ville: value}, () => {

                let result = [this.state.region, this.state.departement, this.state.ville];
                let tab = result;
                this.props.handleOnTab(tab)
            });
        }).catch(error => {

        })
    }

    handleSelectQuartier(value) {
        this.setState({quartier: value}, () => {
            //let hiddenInput = document.querySelector('#perimettreMultiSelect');
            //let tab = JSON.parse(hiddenInput.value);
            let result = [this.state.region, this.state.departement, this.state.ville, this.state.quartier];
            let tab = result;
            this.props.handleOnTab(tab)
        });
    }

    render() {
        return (
            <div className="block">
                <div className="selectBlock">
                    <select value={this.state.region} onChange={(e) => this.handleSelectRegion(e.target.value)}>
                        <option value="DEFAULT">Région</option>
                        {
                            this.state.regions.map((value, index) => (
                                value.region !== "" &&
                                value.region !== "Toutes les régions" &&
                                value.region === this.state.region ?
                                    <option selected="selected" key={index} value={value.region}>{value.region}</option>
                                    :
                                    <option key={index} value={value.region}>{value.region}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="selectBlock">
                    {
                        this.state.departements !== null &&
                        <select value={this.state.departement}
                                onChange={(e) => this.handleSelectDepartement(e.target.value)}>
                            <option value="DEFAULT">Département</option>
                            {
                                this.state.departements.map((value, index) => (
                                    value.departement !== "" ?
                                    value.departement === this.state.departement ?
                                        <option selected="selected" key={index}
                                                value={value.departement}>{value.departement}</option>
                                        :
                                        <option key={index} value={value.departement}>{value.departement}</option>
                                        :null
                                ))
                            }
                        </select>
                    }
                </div>
                <div className="selectBlock">
                    {
                        this.state.villes !== null &&
                        <select value={this.state.ville} onChange={(e) => this.handleSelectVille(e.target.value)}>
                            <option value="DEFAULT">Ville</option>
                            {
                                this.state.villes.map((value, index) => (
                                    value.ville !== "" &&
                                    value.ville !== null &&

                                    <option key={index} value={value.ville}>{value.ville}</option>
                                ))
                            }
                        </select>
                    }
                </div>
                <div className="selectBlock">
                    {
                        this.state.quartiers !== null &&
                        <select value={this.state.quartier !== null ? this.state.quartier : 'DEFAULT'}
                                onChange={(e) => this.handleSelectQuartier(e.target.value)}>
                            <option value="DEFAULT">Quartier</option>
                            {
                                this.state.quartiers.map((value, index) => (
                                    value.quartier !== "" &&

                                    <option key={index} value={value.quartier}>{value.quartier}</option>
                                ))
                            }
                        </select>
                    }
                </div>
            </div>
        )
    }

}
