import React from 'react';
import ReactDOM from 'react-dom';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const cropper = React.createRef();

export default class Croppers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            src: "",
            image: "",
            cropmodal: false,
            inputImg: "Choisir une image",
            ratiow: parseInt(this.props.ratiow),
            ratioh: parseInt(this.props.ratioh),
            url: this.props.url,
            path: this.props.path,
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChangeHandlerUpload = this.onChangeHandlerUpload.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.onChange = this.onChange.bind(this);
        this.createImage = this.createImage.bind(this);
        this.cropImage = this.cropImage.bind(this);
        this.cropAnnuler = this.cropAnnuler.bind(this);
        this.handleDeleteImage = this.handleDeleteImage.bind(this);
    }

    componentDidMount() {

    }

    onFormSubmit(e) {
        e.preventDefault()
        this.fileUpload(this.state.image);
    }

    onChangeHandlerUpload(e) {
        let files = e.target.files || e.dataTransfer.files;
        this.setState({inputImg: files[0].name});
        if (!files.length)
            return;
        this.createImage(files[0]);
    }

    fileUpload(image) {
        const id = this.state.adh.id;
        const formData = {file: this.state.image}
    }

    onChange(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({src: reader.result});
        };
        reader.readAsDataURL(files[0]);
    }

    createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            this.setState({
                image: e.target.result,
                src: e.target.result,
                cropmodal: true,
            })
        };
        reader.readAsDataURL(file);
    }

    cropImage(e) {
        e.preventDefault();
        if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
            return;
        }
        let imgCropp = this.cropper.getCroppedCanvas().toDataURL();
        let inputHidden = document.querySelector('.image');
        let inputHiddenThumb = document.querySelector('.imageThumb');
        this.setState({cropmodal: false, image: imgCropp}, () => {
            inputHidden.value = this.state.image;
            if (inputHiddenThumb !== null) {
                inputHiddenThumb.value = this.state.src;
            }
        });
        document.getElementById('cropper').classList.remove('warning')
    }

    cropAnnuler() {

        this.setState({
            src: "",
            image: "",
            cropmodal: false,
            inputImg: "Choisir une image",
        });
    }

    handleDeleteImage(e) {
        e.preventDefault();
        let test = document.getElementById('cropperContainer')
        let supp = document.getElementById('supp');
        supp.value = 1;
        if (test) {
            test.value = ""
        }
        this.setState({
            src: "",
            image: "",
            cropmodal: false,
            inputImg: "Choisir une image",
            url: "",
        });
    }

    render() {
        return (
            <div>
                <div className="custom-file">
                    <input type="file" name="file-1[]" id="file-1" id="customFile" className="inputfile inputfile-1"
                           data-multiple-caption="Image choisi" onChange={this.onChangeHandlerUpload}/>
                    <label htmlFor="file-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                            <path
                                d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/>
                        </svg>
                        <span>Choisir une image&hellip;</span></label>
                </div>
                {
                    this.state.src !== "" &&
                    <div className="imgCropperPreview">
                        <img src={this.state.image} className="img-thumbnail portraitAdh"/>
                    </div>
                }
                {
                    this.state.url !== "" &&
                    <div className="imgCropperPreview">
                        <img src={`${this.state.url}`} className="img-thumbnail portraitAdh"/>
                        <button className="btn btn-warning" onClick={this.handleDeleteImage}>Supprimer</button>
                    </div>
                }
                {
                    this.state.cropmodal &&
                    <div className="bgmodal">
                        <div className="containerModal">
                            <Cropper
                                ref={cropper => {
                                    this.cropper = cropper;
                                }}
                                src={this.state.src}
                                preview=".img-preview-crop"
                                style={{height: 250, width: '50%'}}
                                zoomable={false}
                                aspectRatio={this.state.ratiow / this.state.ratioh}
                                guides={true}
                                crop={this._crop}
                            />
                            <div className="box">
                                <div className="img-preview-crop"
                                     style={{width: 300, float: 'left', height: 200, overflow: "hidden"}}/>
                            </div>
                            <button onClick={(e) => this.cropImage(e)} className="btn btn-primary cropBtn">Valider
                            </button>
                            <button onClick={(e) => this.cropAnnuler(e)} className="btn btn-primary cropBtn2">Annuler
                            </button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
if (document.getElementById('cropper')) {
    const element = document.getElementById('cropper');
    let props = Object.assign({}, element.dataset);
    ReactDOM.render(<Croppers {...props}/>,
        document.getElementById('cropper'));
}
