import React from "react";
import ReactDOM from "react-dom";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import axios from "axios";
import Moment from "react-moment";
import LineSelectConsult from "./LineSelectConsult";

export default class ConsulteAchatPro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pro: this.props.pro,
            delais: this.props.delais,
            delai: "",
            types: this.props.types,
            caracs: this.props.caracs,
            regions: this.props.regions,
            config: this.props.config,
            nb: [1],
            tab: null,
            i: 1,
            type: null,
            carac: [],
            prixMin: "",
            prixMax: "",
            errorPrixMax: false,
            surfaceMin: "",
            surfaceMax: "",
            surfaceExtMin: "",
            surfaceExtMax: "",
            nbPiece: "",
            nbCham: "",
            recherche: [],
            listeAlerte: this.props.listeAlerte !== [] ? this.props.listeAlerte : [],
            listeAlerteFull: this.props.listeAlerteFull !== [] ? this.props.listeAlerteFull : [],
            openVoletAlert: false,
            name: "",
            email_reception: "",
            openFormRelation: false,
            messageRelation: "",
            user_parti_id: null,
            projet_id: null,
            openForm: false,
            flashMessage: "",
            openFlashMessage: false,
            modifier: false,
            modeRecherche: false,
            idAlert: null,
            openDeleteModal: false,
            currentAlert: {},
            contextProjet: null,
            chemin: null,
            autocomplete: [],
            inputValue: "",
            openAlertBlock: null,
            acheterlouer: false,
            echeance: false,
            secteur: false,
            critere: false,
            budget: false,
            surfacebien: false,
            surfaceterrain: false,
            piece: false,
            phone: "",
            namePart:"",
        };
        this.handleOnTab = this.handleOnTab.bind(this);
        this.handleAddLine = this.handleAddLine.bind(this);
        this.handleSelectTiming = this.handleSelectTiming.bind(this);
        this.handleSelectType = this.handleSelectType.bind(this);
        this.handleSelectCarac = this.handleSelectCarac.bind(this);
        this.handleSurfaceBienMin = this.handleSurfaceBienMin.bind(this);
        this.handleSurfaceBienMax = this.handleSurfaceBienMax.bind(this);
        this.handleSelectNbPiece = this.handleSelectNbPiece.bind(this);
        this.handleSelectNbChambre = this.handleSelectNbChambre.bind(this);
        this.handlePrixMin = this.handlePrixMin.bind(this);
        this.handlePrixMax = this.handlePrixMax.bind(this);
        this.handleSearchBien = this.handleSearchBien.bind(this);
        this.handleCreateAlert = this.handleCreateAlert.bind(this);
        this.handleOpenAlertVolet = this.handleOpenAlertVolet.bind(this);
        this.handleCloseAlertProjet = this.handleCloseAlertProjet.bind(this);
        this.handleConsulteAlertBin = this.handleConsulteAlertBin.bind(this);
        this.handleModifierAlertBin = this.handleModifierAlertBin.bind(this);
        this.handleSupprimerAlertBin = this.handleSupprimerAlertBin.bind(this);
        this.handleMiseEnRelation = this.handleMiseEnRelation.bind(this);
        this.handleOpenForm = this.handleOpenForm.bind(this);
        this.handleCloseForm = this.handleCloseForm.bind(this);
        this.handleCloseMessageAlert = this.handleCloseMessageAlert.bind(this);
        this.handleUpdateAlert = this.handleUpdateAlert.bind(this);
        this.handleAnnuleDeleteAlert = this.handleAnnuleDeleteAlert.bind(this);
        this.handleDeleteAlert = this.handleDeleteAlert.bind(this);
        this.handleAddFormRelation = this.handleAddFormRelation.bind(this);
        this.handleSearchFilterAlert = this.handleSearchFilterAlert.bind(this);
        this.handleOpenAlertBlock = this.handleOpenAlertBlock.bind(this);
    }

    componentDidMount() {
        const {listeAlerteFull, config} = this.state;
        let tab = [];
        listeAlerteFull.map((value) => {
            tab.push(value.name);
        });
        this.setState({autocomplete: tab});
        if (config.habitat === 0 && config.entreprise === 1) {
            this.handleAddChemin("entreprise");
        } else if (config.habitat === 1 && config.entreprise === 0) {
            this.handleAddChemin("habitat");
        }
        let e = null;
        this.handleSearchBien(e);
    }

    handleOnTab(tab) {
        this.setState({tab});
    }

    handleAddLine() {
        let {nb, i} = this.state;
        nb.push(1);
        i++;
        this.setState({nb, i});
    }

    handleSelectTiming(value) {
        this.setState({
            delai: value,
        });
    }

    handleSelectType(value) {
        this.setState({type: value});
    }

    handleSelectCarac(value) {
        let {carac} = this.state;
        if (carac.indexOf(value) !== -1) {
            const i = carac.indexOf(value);
            carac.splice(i, 1);
        } else {
            carac.push(value);
        }
        this.setState({carac: carac});
    }

    handlePrixMin(value) {
        this.setState({
            prixMin: value,
            prixMax: value,
        });
    }

    handlePrixMax(value) {
        const {prixMin} = this.state;
        if (parseInt(prixMin) < parseInt(value)) {
            this.setState({errorPrixMax: false});
        } else {
            this.setState({
                errorPrixMax: true,
            });
        }
        this.setState({prixMax: value});
    }

    handleSurfaceBienMin(value) {
        this.setState({
            surfaceMin: value,
            surfaceMax: value,
        });
    }

    handleSurfaceBienMax(value) {
        const {surfaceMin} = this.state;
        if (parseInt(surfaceMin) < parseInt(value)) {
            this.setState({errorSurfBienMax: false});
        } else {
            this.setState({
                errorSurfBienMax: true,
            });
        }
        this.setState({surfaceMax: value});
    }

    handleSurfaceExtMin(value) {
        this.setState({
            surfaceExtMin: value,
            surfaceExtMax: value,
        });
    }

    handleSurfaceExtMax(value) {
        const {surfaceExtMin} = this.state;
        if (parseInt(surfaceExtMin) < parseInt(value)) {
            this.setState({errorSurfExtMax: false});
        } else {
            this.setState({
                errorSurfExtMax: true,
            });
        }
        this.setState({surfaceExtMax: value});
    }

    handleSelectNbPiece(value) {
        if (value !== "Studio" && value !== "+5") {
            let tabCham = [];
            for (let i = 1; i < parseInt(value); i++) {
                tabCham.push(i);
            }
            this.setState({nbPiece: value, tabCham: tabCham});
        } else {
            let tabCham = [];
            this.setState({nbPiece: value, tabCham: tabCham});
        }
    }

    handleSelectNbChambre(value) {
        const {nbPiece} = this.state;
        if (nbPiece == 1) {
            this.setState({nbCham: value, messageFlashChambre: null});
        } else if (nbPiece === "+5") {
            this.setState({nbCham: value, messageFlashChambre: null});
        } else {
            if (nbPiece > value) {
                this.setState({nbCham: value, messageFlashChambre: null});
            } else {
                this.setState({
                    messageFlashChambre:
                        "Le nombre de chambre doit être inferieur au nombre de pièce.",
                });
            }
        }
    }

    handleSearchBien(e) {
        if(e !== null){
            e.preventDefault();
        }
        axios
            .post("/professionnel/consulter-biens-en-achat/recherche", {
                chemin: this.state.chemin,
                delai: this.state.delai,
                caracteristique: JSON.stringify(this.state.carac),
                budgetMin: this.state.prixMin,
                budgetMax: this.state.prixMax,
                type: this.state.type,
                localisation: JSON.stringify(this.state.tab),
                piece: this.state.nbPiece,
                chambre: this.state.nbCham,
                surfaceMin: this.state.surfaceMin,
                surfaceMax: this.state.surfaceMax,
                terrainMin: this.state.surfaceExtMin,
                terrainMax: this.state.surfaceExtMax,
                contextProjet: this.state.contextProjet,
            })
            .then((res) => {
                this.setState({recherche: res.data, modeRecherche: true});
            })
            .catch((error) => {
            });
    }

    handleCreateAlert(e) {
        e.preventDefault();
        axios
            .post("/professionnel/consulter-biens-en-achat/alert", {
                delai: this.state.delai,
                caracteristique: JSON.stringify(this.state.carac),
                budgetMin: this.state.prixMin,
                budgetMax: this.state.prixMax,
                type: this.state.type,
                localisation: JSON.stringify(this.state.tab),
                piece: this.state.nbPiece,
                chambre: this.state.nbCham,
                surfaceMin: this.state.surfaceMin,
                surfaceMax: this.state.surfaceMax,
                terrainMin: this.state.surfaceExtMin,
                terrainMax: this.state.surfaceExtMax,
                name: this.state.name,
                email_reception: this.state.email_reception,
                chemin: this.state.chemin,
                contextProjet: this.state.contextProjet,
            })
            .then((res) => {
                this.setState(
                    {listeAlerte: res.data, openForm: false},
                    () => {
                        this.setState({
                            flashMessage: "Votre alerte a bien été créée.",
                            openFlashMessage: true,
                        });
                    }
                );
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleCloseMessageAlert(e) {
        e.preventDefault();
        this.setState({flashMessage: "", openFlashMessage: false});
    }

    handleOpenAlertVolet() {
        let {listeAlerte} = this.state;
        listeAlerte.map((value) => {
            let loca = JSON.parse(value.localisation);
            console.log(loca);
            if (loca != null) {
                console.log("hello");
                if (loca[3]) {
                    value.loca = loca[3];
                } else if (loca[2]) {
                    value.loca = loca[2];
                } else if (loca[1]) {
                    value.loca = loca[1];
                } else if (loca[0]) {
                    value.loca = loca[0];
                } else {
                    value.loca = "_";
                }
            } else {
                value.loca = "_";
            }
        });
        this.setState({listeAlerte}, () => {
            this.setState({openVoletAlert: !this.state.openVoletAlert});
        });
    }

    handleCloseAlertProjet() {
        this.setState({openVoletAlert: !this.state.openVoletAlert});
    }

    handleConsulteAlertBin(e, index) {
        e.preventDefault();
        let {listeAlerte} = this.state;
        let currentAlert = listeAlerte[index];
        this.setState(
            {
                tab: JSON.parse(currentAlert.localisation),
                carac: JSON.parse(currentAlert.caracteristique),
            },
            () => {
                let tabCham = [];
                for (let i = 1; i <= currentAlert.chambre; i++) {
                    tabCham.push(i);
                }
                this.setState(
                    {
                        openVoletAlert: false,
                        delai: currentAlert.delai,
                        type: currentAlert.type,
                        prixMin: currentAlert.budgetMin,
                        prixMax: currentAlert.budgetMax,
                        surfaceMin: currentAlert.surfaceMin,
                        surfaceMax: currentAlert.surfaceMax,
                        surfaceExtMin: currentAlert.terrainMin,
                        surfaceExtMax: currentAlert.terrainMax,
                        nbCham: currentAlert.chambre,
                        tabCham: tabCham,
                        nbPiece: currentAlert.piece,
                        chemin: currentAlert.chemin,
                        contextProjet: currentAlert.contextProjet,
                    },
                    () => {
                        setTimeout(() => {
                            axios
                                .post(
                                    "/professionnel/consulter-biens-en-achat/recherche",
                                    {
                                        delai: this.state.delai,
                                        caracteristique: JSON.stringify(
                                            this.state.carac
                                        ),
                                        budgetMin: this.state.prixMin,
                                        budgetMax: this.state.prixMax,
                                        type: this.state.type,
                                        localisation: JSON.stringify(
                                            this.state.tab
                                        ),
                                        piece: this.state.nbPiece,
                                        chambre: this.state.nbCham,
                                        surfaceMin: this.state.surfaceMin,
                                        surfaceMax: this.state.surfaceMax,
                                        terrainMin: this.state.surfaceExtMin,
                                        terrainMax: this.state.surfaceExtMax,
                                        chemin: this.state.chemin,
                                        contextProjet: this.state.contextProjet,
                                    }
                                )
                                .then((res) => {
                                    this.setState(
                                        {recherche: res.data},
                                        () => {
                                            window.scrollTo({
                                                left: 0,
                                                top: document.body.scrollHeight,
                                                behavior: "smooth",
                                            });
                                        }
                                    );
                                })
                                .catch((error) => {
                                }, 1000);
                        });
                    }
                );
            }
        );
    }

    handleModifierAlertBin(e, index) {
        e.preventDefault();
        let {listeAlerte} = this.state;
        let currentAlert = listeAlerte[index];
        this.setState(
            {
                tab: JSON.parse(currentAlert.localisation),
                carac: JSON.parse(currentAlert.caracteristique),
            },
            () => {
                let tabCham = [];
                for (let i = 1; i <= currentAlert.chambre; i++) {
                    tabCham.push(i);
                }
                this.setState(
                    {
                        openVoletAlert: false,
                        delai: currentAlert.delai,
                        type: currentAlert.type,
                        prixMin: currentAlert.budgetMin,
                        prixMax: currentAlert.budgetMax,
                        surfaceMin: currentAlert.surfaceMin,
                        surfaceMax: currentAlert.surfaceMax,
                        surfaceExtMin: currentAlert.terrainMin,
                        surfaceExtMax: currentAlert.terrainMax,
                        nbCham: currentAlert.chambre,
                        tabCham: tabCham,
                        nbPiece: currentAlert.piece,
                        modifier: true,
                        modeRecherche: false,
                        name: currentAlert.name,
                        email_reception: currentAlert.email_reception,
                        idAlert: currentAlert.id,
                    },
                    () => {
                    }
                );
            }
        );
    }

    handleSupprimerAlertBin(e, index) {
        e.preventDefault();
        let {listeAlerte} = this.state;
        let currentAlert = listeAlerte[index];
        this.setState({
            modeRecherche: false,
            idAlert: currentAlert.id,
            currentAlert: currentAlert,
            openDeleteModal: true,
            openVoletAlert: false,
        });
    }

    handleAnnuleDeleteAlert(e) {
        e.preventDefault();
        this.setState({
            idAlert: null,
            currentAlert: {},
            openDeleteModal: false,
        });
    }

    handleDeleteAlert(e) {
        e.preventDefault();
        axios
            .post("/professionnel/consulter-biens-en-achat/alert/delete", {
                id: this.state.idAlert,
            })
            .then((res) => {
                this.setState({listeAlerte: res.data}, () => {
                    this.setState({
                        flashMessage: "Votre alerte a bien été supprimée.",
                        openFlashMessage: true,
                        idAlert: null,
                        currentAlert: {},
                        openDeleteModal: false,
                    });
                });
            })
            .catch((error) => {
            });
    }

    handleMiseEnRelation(e, user_id, projet_id) {
        e.preventDefault();
        if (this.state.pro.agence_id !== null) {
            axios.post('/professionnel/search-phone-number', {
                projet_id: projet_id,
                type: "achat",
            }).then(res => {
                this.setState({
                    phone:res.data.phone,
                    namePart:res.data.name,
                },() => {
                    this.setState({
                        user_parti_id: user_id,
                        projet_id: projet_id,
                        openFormRelation: true,
                    });
                });
            }).catch(error => {
                console.log(error);
            })
        } else {
            this.setState({
                flashMessage:
                    "Vous devez remplir votre profil avant de faire une demande de mise en relation.",
                openFlashMessage: true,
            });
        }
    }

    handleCloseFormRelation(e) {
        e.preventDefault();
        this.setState({openFormRelation: false});
    }

    handleAddFormRelation(e) {
        e.preventDefault();
        let {acheterlouer} = this.state;
        let typeProjet = "";
        if(acheterlouer){
            typeProjet = "louer"
        }else{
            typeProjet = "achat"
        }
        let btn = document.querySelector("#relationAdd");
        btn.textContent = "Validation en cours";
        btn.style.transition = '0ms';
        btn.style.width = '185px';
        btn.style.cursor = 'not-allowed';
        axios
            .post("/professionnel/consulter-biens-en-achat/relation", {
                user_parti_id: this.state.user_parti_id,
                projet_id: this.state.projet_id,
                message: this.state.messageRelation,
                typeProjet,
            })
            .then((res) => {
                if (res.data == 1) {
                    this.setState(
                        {
                            openFormRelation: false,
                            flashMessage:
                                "Votre demande de mise en relation a bien été enregistrée.",
                            openFlashMessage: true,
                        },
                        () => {
                            axios
                                .post(
                                    "/professionnel/consulter-biens-en-achat/recherche",
                                    {
                                        delai: this.state.delai,
                                        caracteristique: JSON.stringify(
                                            this.state.carac
                                        ),
                                        budgetMin: this.state.prixMin,
                                        budgetMax: this.state.prixMax,
                                        type: this.state.type,
                                        localisation: JSON.stringify(
                                            this.state.tab
                                        ),
                                        piece: this.state.nbPiece,
                                        chambre: this.state.nbCham,
                                        surfaceMin: this.state.surfaceMin,
                                        surfaceMax: this.state.surfaceMax,
                                        terrainMin: this.state.surfaceExtMin,
                                        terrainMax: this.state.surfaceExtMax,
                                        contextProjet: this.state.contextProjet,
                                        chemin: this.state.chemin,
                                    }
                                )
                                .then((res) => {
                                    this.setState({recherche: res.data});
                                })
                                .catch((error) => {
                                });
                        }
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleOpenForm(e) {
        e.preventDefault();
        this.setState({openForm: true, modeRecherche: false});
    }

    handleCloseForm(e) {
        e.preventDefault();
        this.setState({openForm: false});
    }

    handleUpdateAlert(e) {
        e.preventDefault();
        this.setState({modeRecherche: false}, () => {
            axios
                .post("/professionnel/consulter-biens-en-achat/alert/edit", {
                    delai: this.state.delai,
                    caracteristique: JSON.stringify(this.state.carac),
                    budgetMin: this.state.prixMin,
                    budgetMax: this.state.prixMax,
                    type: this.state.type,
                    localisation: JSON.stringify(this.state.tab),
                    piece: this.state.nbPiece,
                    chambre: this.state.nbCham,
                    surfaceMin: this.state.surfaceMin,
                    surfaceMax: this.state.surfaceMax,
                    terrainMin: this.state.surfaceExtMin,
                    terrainMax: this.state.surfaceExtMax,
                    name: this.state.name,
                    email_reception: this.state.email_reception,
                    idAlert: this.state.idAlert,
                    chemin: this.state.chemin,
                })
                .then((res) => {
                    this.setState({listeAlerte: res.data}, () => {
                        this.setState({
                            flashMessage: "Votre alerte a bien été modifiée.",
                            openFlashMessage: true,
                            modifier: false,
                            idAlert: null,
                        });
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }

    handleAddContextProjet(value) {
        this.setState({contextProjet: value});
    }

    handleAddChemin(value) {
        let types = [];
        let caracs = [];
        if (value === "habitat") {
            types = this.props.types.filter(
                (type) => type.entreprise === 0
            );
            caracs = this.props.caracs.filter(
                (carac) => carac.entreprise === 0
            );
            this.setState({types, caracs}, () => {
                this.setState({chemin: value});
            });
        } else {
            types = this.props.types.filter(
                (type) => type.entreprise === 1
            );
            caracs = this.props.caracs.filter(
                (carac) => carac.entreprise === 1
            );
            this.setState({types, caracs}, () => {
                this.setState({chemin: value});
            });
        }
    }

    handleSearchFilterAlert(value) {
        let {listeAlerteFull, listeAlerte, inputValue} = this.state;
        if (inputValue === "") {
            listeAlerte = listeAlerteFull;
        } else {
            listeAlerte = listeAlerteFull.filter((l) =>
                l.name.includes(inputValue)
            );
        }
        this.setState({listeAlerte});
    }

    handleOpenAlertBlock(e, index) {
        e.preventDefault();
        this.setState({openAlertBlock: index});
    }

    handleOpenAction = (e, action) => {
        e.preventDefault();
        console.log(action);
        if (action === "acheterlouer") {
            this.setState({acheterlouer: !this.state.acheterlouer});
        } else if (action === "echeance") {
            this.setState({echeance: !this.state.echeance});
        } else if (action === "secteur") {
            this.setState({secteur: !this.state.secteur});
        } else if (action === "critere") {
            this.setState({critere: !this.state.critere});
        } else if (action === "budget") {
            this.setState({budget: !this.state.budget});
        } else if (action === "surfacebien") {
            this.setState({surfacebien: !this.state.surfacebien});
        } else if (action === "surfaceterrain") {
            this.setState({surfaceterrain: !this.state.surfaceterrain});
        } else if (action === "piece") {
            this.setState({piece: !this.state.piece});
        }
    }

    render() {
        return (
            <>
                <div className="form">
                    <div className="block">
                        {
                            this.state.config.habitat == 1 && this.state.config.entreprise == 1 &&
                            <h2>Usage | Immobilier d’entreprise</h2>
                        }
                        {
                            this.state.config.habitat == 1 &&
                            <div
                                onClick={() => this.handleAddChemin("habitat")}
                                className={
                                    this.state.chemin !== null
                                        ? this.state.chemin === "habitat"
                                            ? "selected btn formbtn"
                                            : "btn formbtn"
                                        : "btn formbtn"
                                }
                            >
                                Immobilier résidentiel
                            </div>
                        }
                        {
                            this.state.config.entreprise == 1 &&
                            <div
                                onClick={() => this.handleAddChemin("entreprise")}
                                className={
                                    this.state.chemin !== null
                                        ? this.state.chemin === "entreprise"
                                            ? "selected btn formbtn"
                                            : "btn formbtn"
                                        : "btn formbtn"
                                }
                            >
                                Immobilier d’entreprise
                            </div>
                        }
                    </div>
                </div>
                {this.state.chemin !== null && (
                    <>
                        <div className="form">
                            <h2>Type de projet <a href="" className="actionOpen"
                                                  onClick={(e) => this.handleOpenAction(e, "acheterlouer")}>{this.state.acheterlouer ? "-" : "+"}</a>
                            </h2>
                            <div className="block">
                                {
                                    this.state.acheterlouer &&
                                    <>
                                        <div
                                            onClick={() =>
                                                this.handleAddContextProjet("Acheter")
                                            }
                                            className={
                                                this.state.contextProjet !== null
                                                    ? this.state.contextProjet ===
                                                    "Acheter"
                                                        ? "selected btn formbtn"
                                                        : "btn formbtn"
                                                    : "btn formbtn"
                                            }
                                        >
                                            Acheter
                                        </div>
                                        <div
                                            onClick={() =>
                                                this.handleAddContextProjet("Louer")
                                            }
                                            className={
                                                this.state.contextProjet !== null
                                                    ? this.state.contextProjet ===
                                                    "Louer"
                                                        ? "selected btn formbtn"
                                                        : "btn formbtn"
                                                    : "btn formbtn"
                                            }
                                        >
                                            Louer
                                        </div>
                                    </>
                                }

                            </div>
                            <h2>
                                Vous souhaitez accéder aux recherches dont
                                l’échéance est <a href="" className="actionOpen"
                                                  onClick={(e) => this.handleOpenAction(e, "echeance")}>{this.state.echeance ? "-" : "+"}</a>
                            </h2>
                            {
                                this.state.echeance &&
                                <div className="block">
                                    {this.state.delais.map((value, index) => (
                                        <div
                                            key={index}
                                            className={
                                                this.state.delai !== null
                                                    ? this.state.delai ===
                                                    value.name
                                                        ? "selected btn formbtn"
                                                        : "btn formbtn"
                                                    : "btn formbtn"
                                            }
                                            onClick={() =>
                                                this.handleSelectTiming(value.name)
                                            }
                                        >
                                            {value.name}
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                        <div className="form">
                            <h2>Secteur <a href="" className="actionOpen"
                                           onClick={(e) => this.handleOpenAction(e, "secteur")}>{this.state.secteur ? "-" : "+"}</a>
                            </h2>
                            {
                                this.state.secteur &&

                                <div id="lineselectperimetre">
                                    {this.state.nb.map((value, index) => (
                                        <div
                                            key={index}
                                            className="blockSelectLine"
                                        >
                                            <LineSelectConsult
                                                i={this.state.i}
                                                nb={this.state.nb}
                                                regions={this.state.regions}
                                                tab={this.state.tab}
                                                handleOnTab={this.handleOnTab}
                                            />
                                        </div>
                                    ))}
                                </div>
                            }
                            {/*
                    <div className="addperimettre">
                        <div className="btn btn_add" onClick={this.handleAddLine}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15.013" height="15.013"
                                 viewBox="0 0 15.013 15.013">
                                <g id="Icon_ionic-md-add-circle-outline" data-name="Icon ionic-md-add-circle-outline"
                                   transform="translate(-3.375 -3.375)">
                                    <path id="Tracé_8" data-name="Tracé 8"
                                          d="M18.2,15.1H15.1v3.1H13.58V15.1h-3.1V13.58h3.1v-3.1H15.1v3.1h3.1Z"
                                          transform="translate(-3.457 -3.457)" fill="#2f2f2f"/>
                                    <path id="Tracé_9" data-name="Tracé 9"
                                          d="M10.881,4.891A5.988,5.988,0,1,1,6.645,6.645a5.966,5.966,0,0,1,4.237-1.754m0-1.516a7.506,7.506,0,1,0,7.506,7.506,7.505,7.505,0,0,0-7.506-7.506Z"
                                          fill="#2f2f2f"/>
                                </g>
                            </svg>
                            <span>Ajouter</span>
                        </div>
                    </div>
                    */}
                            <input
                                type="hidden"
                                name="perimetre"
                                id="perimettreMultiSelect"
                                value={JSON.stringify(this.state.tab)}
                            />

                        </div>
                        <div className="form">
                            <h2>Type de bien <a href="" className="actionOpen"
                                                onClick={(e) => this.handleOpenAction(e, "bien")}>{this.state.bien ? "-" : "+"}</a>
                            </h2>
                            {
                                this.state.bien &&
                                <div className="block">
                                    {this.state.types.map((value, index) => (
                                        <div
                                            key={index}
                                            className={
                                                this.state.type !== null
                                                    ? this.state.type === value.name
                                                        ? "selected btn formbtn"
                                                        : "btn formbtn"
                                                    : "btn formbtn"
                                            }
                                            onClick={() =>
                                                this.handleSelectType(value.name)
                                            }
                                        >
                                            {value.name}
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                        <div className="form">
                            <h2>
                                Quels sont les critères complémentaires que vous
                                souhaitez préciser <a href="" className="actionOpen"
                                                      onClick={(e) => this.handleOpenAction(e, "critere")}>{this.state.critere ? "-" : "+"}</a>
                            </h2>
                            {
                                this.state.critere &&
                                <div className="block">
                                    {this.state.caracs.map((value, index) => (
                                        <div
                                            key={index}
                                            className={
                                                this.state.carac.indexOf(
                                                    value.name
                                                ) != -1
                                                    ? "selected btn formbtn"
                                                    : "btn formbtn"
                                            }
                                            onClick={() =>
                                                this.handleSelectCarac(value.name)
                                            }
                                        >
                                            {value.name}
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                        <div className="form">
                            <h2>Budget en € <a href="" className="actionOpen"
                                               onClick={(e) => this.handleOpenAction(e, "budget")}>{this.state.budget ? "-" : "+"}</a>
                            </h2>
                            {
                                this.state.budget &&
                                <div className="prix">
                                    <input
                                        type="text"
                                        placeholder="min €"
                                        value={this.state.prixMin}
                                        onChange={(e) =>
                                            this.handlePrixMin(e.target.value)
                                        }
                                    />
                                    <input
                                        type="text"
                                        placeholder="max €"
                                        value={this.state.prixMax}
                                        onChange={(e) =>
                                            this.handlePrixMax(e.target.value)
                                        }
                                    />
                                    {this.state.errorPrixMax && (
                                        <div className="message">
                                            Le prix max. doit être supérieur au prix
                                            min.
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                        <div className="form">
                            <h2>Surface du bien en m² <a href="" className="actionOpen"
                                                         onClick={(e) => this.handleOpenAction(e, "surfacebien")}>{this.state.surfacebien ? "-" : "+"}</a>
                            </h2>
                            {
                                this.state.surfacebien &&
                                <div className="prix">
                                    <input
                                        type="text"
                                        placeholder="min m²"
                                        value={this.state.surfaceMin}
                                        onChange={(e) =>
                                            this.handleSurfaceBienMin(
                                                e.target.value
                                            )
                                        }
                                    />
                                    <input
                                        type="text"
                                        placeholder="max m²"
                                        value={this.state.surfaceMax}
                                        onChange={(e) =>
                                            this.handleSurfaceBienMax(
                                                e.target.value
                                            )
                                        }
                                    />
                                    {this.state.errorSurfBienMax && (
                                        <div className="message">
                                            Le prix max. doit être supérieur au prix
                                            min.
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                        <div className="form">
                            <h2>Surface de terrain en m² <a href="" className="actionOpen"
                                                            onClick={(e) => this.handleOpenAction(e, "surfaceterrain")}>{this.state.surfaceterrain ? "-" : "+"}</a>
                            </h2>
                            {
                                this.state.surfaceterrain &&
                                <div className="prix">
                                    <input
                                        type="text"
                                        placeholder="min m²"
                                        value={this.state.surfaceExtMin}
                                        onChange={(e) =>
                                            this.handleSurfaceExtMin(e.target.value)
                                        }
                                    />
                                    <input
                                        type="text"
                                        placeholder="max m²"
                                        value={this.state.surfaceExtMax}
                                        onChange={(e) =>
                                            this.handleSurfaceExtMax(e.target.value)
                                        }
                                    />
                                    {this.state.errorSurfExtMax && (
                                        <div className="message">
                                            Le prix max. doit être supérieur au prix
                                            min.
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                        <div className="form">
                            <div className="formblock disflex">
                                <h2>Nombre de pièces <a href="" className="actionOpen"
                                                        onClick={(e) => this.handleOpenAction(e, "piece")}>{this.state.piece ? "-" : "+"}</a>
                                </h2>
                                {
                                    this.state.piece &&
                                    <div className="blockNbPiece">
                                        <div
                                            className={
                                                this.state.nbPiece === "Studio"
                                                    ? "nbpiece selected"
                                                    : "nbpiece"
                                            }
                                            onClick={() =>
                                                this.handleSelectNbPiece("Studio")
                                            }
                                        >
                                            Studio
                                        </div>
                                        <div
                                            className={
                                                this.state.nbPiece == "1"
                                                    ? "nbpiece selected"
                                                    : "nbpiece"
                                            }
                                            onClick={() =>
                                                this.handleSelectNbPiece("1")
                                            }
                                        >
                                            1
                                        </div>
                                        <div
                                            className={
                                                this.state.nbPiece == "2"
                                                    ? "nbpiece selected"
                                                    : "nbpiece"
                                            }
                                            onClick={() =>
                                                this.handleSelectNbPiece("2")
                                            }
                                        >
                                            2
                                        </div>
                                        <div
                                            className={
                                                this.state.nbPiece == "3"
                                                    ? "nbpiece selected"
                                                    : "nbpiece"
                                            }
                                            onClick={() =>
                                                this.handleSelectNbPiece("3")
                                            }
                                        >
                                            3
                                        </div>
                                        <div
                                            className={
                                                this.state.nbPiece == "4"
                                                    ? "nbpiece selected"
                                                    : "nbpiece"
                                            }
                                            onClick={() =>
                                                this.handleSelectNbPiece("4")
                                            }
                                        >
                                            4
                                        </div>
                                        <div
                                            className={
                                                this.state.nbPiece === "+5"
                                                    ? "nbpiece selected"
                                                    : "nbpiece"
                                            }
                                            onClick={() =>
                                                this.handleSelectNbPiece("+5")
                                            }
                                        >
                                            +5
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="form">
                            {this.state.nbPiece !== "" &&
                            this.state.nbPiece !== "Studio" ? (
                                this.state.nbPiece !== "+5" ? (
                                    this.state.nbPiece === "1" ? (
                                        <div className="formblock disflex">
                                            <label htmlFor="">
                                                Nombre de chambres
                                            </label>
                                            <div className="blockNbPiece">
                                                <div
                                                    className={
                                                        this.state.nbCham ===
                                                        "1"
                                                            ? "nbpiece selected"
                                                            : "nbpiece"
                                                    }
                                                    onClick={() =>
                                                        this.handleSelectNbChambre(
                                                            "1"
                                                        )
                                                    }
                                                >
                                                    1
                                                </div>
                                            </div>
                                            <div className="message">
                                                {this.state.messageFlashChambre}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="formblock disflex">
                                            <label htmlFor="">
                                                Nombre de chambres
                                            </label>
                                            <div className="blockNbPiece">
                                                {this.state.tabCham.map(
                                                    (value, index) => (
                                                        <div
                                                            key={index}
                                                            className={
                                                                this.state
                                                                    .nbCham ===
                                                                index + 1
                                                                    ? "nbpiece selected"
                                                                    : "nbpiece"
                                                            }
                                                            onClick={() =>
                                                                this.handleSelectNbChambre(
                                                                    index + 1
                                                                )
                                                            }
                                                        >
                                                            {index + 1}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            <div className="message">
                                                {this.state.messageFlashChambre}
                                            </div>
                                        </div>
                                    )
                                ) : (
                                    <div className="formblock disflex">
                                        <label htmlFor="">
                                            Nombre de chambres
                                        </label>
                                        <div className="blockNbPiece">
                                            <div
                                                className={
                                                    this.state.nbCham === "1"
                                                        ? "nbpiece selected"
                                                        : "nbpiece"
                                                }
                                                onClick={() =>
                                                    this.handleSelectNbChambre(
                                                        "1"
                                                    )
                                                }
                                            >
                                                1
                                            </div>
                                            <div
                                                className={
                                                    this.state.nbCham === "2"
                                                        ? "nbpiece selected"
                                                        : "nbpiece"
                                                }
                                                onClick={() =>
                                                    this.handleSelectNbChambre(
                                                        "2"
                                                    )
                                                }
                                            >
                                                2
                                            </div>
                                            <div
                                                className={
                                                    this.state.nbCham === "3"
                                                        ? "nbpiece selected"
                                                        : "nbpiece"
                                                }
                                                onClick={() =>
                                                    this.handleSelectNbChambre(
                                                        "3"
                                                    )
                                                }
                                            >
                                                3
                                            </div>
                                            <div
                                                className={
                                                    this.state.nbCham === "4"
                                                        ? "nbpiece selected"
                                                        : "nbpiece"
                                                }
                                                onClick={() =>
                                                    this.handleSelectNbChambre(
                                                        "4"
                                                    )
                                                }
                                            >
                                                4
                                            </div>
                                            <div
                                                className={
                                                    this.state.nbCham === "+5"
                                                        ? "nbpiece selected"
                                                        : "nbpiece"
                                                }
                                                onClick={() =>
                                                    this.handleSelectNbChambre(
                                                        "+5"
                                                    )
                                                }
                                            >
                                                +5
                                            </div>
                                        </div>
                                        <div className="message">
                                            {this.state.messageFlashChambre}
                                        </div>
                                    </div>
                                )
                            ) : null}
                        </div>
                        {this.state.modifier ? (
                            <div className="form">
                                <div className="prix alertModif">
                                    <input
                                        type="text"
                                        value={this.state.name}
                                        name="nomAlert"
                                        placeholder="Nom de l'alerte"
                                        onChange={(e) =>
                                            this.setState({
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                    <input
                                        type="email"
                                        value={this.state.email_reception}
                                        name="emailAlert"
                                        placeholder="E-mail de réception de l'alerte"
                                        onChange={(e) =>
                                            this.setState({
                                                email_reception: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="submitForm">
                                    <a
                                        href=""
                                        onClick={(e) =>
                                            this.handleUpdateAlert(e)
                                        }
                                    >
                                        Modifier mon alerte
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div className="submitForm">
                                <a
                                    href=""
                                    onClick={(e) => this.handleOpenForm(e)}
                                >
                                    Créer une alerte
                                </a>
                                <a
                                    href=""
                                    onClick={(e) => this.handleSearchBien(e)}
                                >
                                    Rechercher
                                </a>
                            </div>
                        )}
                    </>
                )}

                <div
                    className={
                        this.state.openVoletAlert
                            ? "alertVolet open"
                            : "alertVolet"
                    }
                >
                    <div
                        className="ongletAlert"
                        onClick={this.handleOpenAlertVolet}
                    >
                        Mes alertes
                    </div>
                </div>
                {this.state.openForm && (
                    <div className="blockAlert">
                        <div className="alertList createAlert">
                            <div className="alertSubmitForm">
                                <div className="header">
                                    <div className="title">
                                        <p>Créer une nouvelle alerte</p>
                                    </div>
                                    <div className="close" onClick={(e) => this.handleCloseForm(e)}>
                                        <svg width="40" height="40" viewBox="0 0 24 24" fill="#2f2f2f"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.46445 15.5354L15.5355 8.46436" stroke="#2f2f2f"/>
                                            <path d="M8.46446 8.46458L15.5355 15.5356" stroke="#2f2f2f"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="body">
                                    <input
                                        type="text"
                                        name="nomAlert"
                                        placeholder="Nom de l'alerte"
                                        onChange={(e) =>
                                            this.setState({name: e.target.value})
                                        }
                                    />
                                    <input
                                        type="email"
                                        name="emailAlert"
                                        placeholder="E-mail de réception de l'alerte"
                                        onChange={(e) =>
                                            this.setState({
                                                email_reception: e.target.value,
                                            })
                                        }
                                    />
                                    <a
                                        href=""
                                        onClick={(e) => this.handleCreateAlert(e)}
                                    >
                                        Enregistrer une alerte
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.openVoletAlert && (
                    <div className="blockAlert">
                        <div className="alertList">
                            <div className="listeAlert">
                                <div className="header">
                                    <div className="title">
                                        <h2>Mes alertes</h2>
                                    </div>
                                    <div className="close" onClick={(e) => this.handleCloseAlertProjet(e)}>
                                        <svg width="40" height="40" viewBox="0 0 24 24" fill="#2f2f2f"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.46445 15.5354L15.5355 8.46436" stroke="#2f2f2f"/>
                                            <path d="M8.46446 8.46458L15.5355 15.5356" stroke="#2f2f2f"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="filter">
                                    <label htmlFor="searchFilter">
                                        Recherche par mot clés
                                    </label>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        freeSolo
                                        options={this.state.autocomplete}
                                        sx={{width: 300}}
                                        inputValue={this.state.inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            this.setState({inputValue: newInputValue,});
                                        }}
                                        onChange={(newValue) => this.handleSearchFilterAlert(newValue)}
                                        renderInput={(params) => (<TextField {...params} />)}
                                    />
                                </div>
                                {this.state.listeAlerte.map((value, index) => (
                                    <div key={index} className="alertLine">
                                        <div className="headerAlertBlock">
                                            <div className="left">
                                                {value.name}
                                                <a href="" onClick={(e) => this.handleOpenAlertBlock(e, index)}>
                                                    <svg id="Info-circle" xmlns="http://www.w3.org/2000/svg"
                                                         width="17.918" height="17.918" viewBox="0 0 17.918 17.918">
                                                        <path id="Tracé_629" data-name="Tracé 629"
                                                              d="M12.018,10.75a.768.768,0,0,1,.768.768v5.119a.768.768,0,0,1-1.536,0V11.518A.768.768,0,0,1,12.018,10.75Z"
                                                              transform="translate(-3.059 -3.071)" fill="#939393"/>
                                                        <path id="Tracé_630" data-name="Tracé 630"
                                                              d="M12.024,9.048A1.024,1.024,0,1,0,11,8.024,1.024,1.024,0,0,0,12.024,9.048Z"
                                                              transform="translate(-3.065 -3.16)" fill="#939393"/>
                                                        <path id="Tracé_631" data-name="Tracé 631"
                                                              d="M3.25,12.209a8.959,8.959,0,1,1,8.959,8.959A8.959,8.959,0,0,1,3.25,12.209Zm8.959-7.423a7.423,7.423,0,1,0,7.423,7.423A7.423,7.423,0,0,0,12.209,4.786Z"
                                                              transform="translate(-3.25 -3.25)" fill="#939393"
                                                              fill-rule="evenodd"/>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div className="right">
                                                <a href="" onClick={(e) => this.handleConsulteAlertBin(e, index)}>
                                                    Lancer la recherche >
                                                </a><br/>
                                                <Moment format="DD/MM/YYYY">
                                                    {value.created_at}
                                                </Moment>
                                            </div>
                                        </div>
                                        {
                                            this.state.openAlertBlock === index ?
                                                <>
                                                    <div className="emailBlock">
                                                        {value.email_reception}
                                                    </div>
                                                    <div className="type">{value.type}</div>
                                                    <div className="localisation">{value.loca}</div>
                                                    <div
                                                        className="surface">{value.surfaceMin !== null ? value.surfaceMin + "m²" : "_"}{value.surfaceMax !== null ? value.surfaceMin <= value.surfaceMax ? " | " + value.surfaceMax + " m²" : "" : ""}</div>
                                                    <div className="budget">
                                                        {value.budgetMin !== null ? value.budgetMin + " €" : "_"} {value.budgetMax !== null ? value.budgetMin <= value.budgetMax ? " | " + value.budgetMax + " €" : "" : ""}
                                                    </div>
                                                    <div
                                                        className="piece">{value.piece !== null ? value.piece + " piéces" : " _ "} | {value.chambre !== null ? value.chambre + " Chambres" : " _ "}</div>
                                                    <div
                                                        className="delais">{value.delai !== null ? value.delai : "_"}</div>
                                                </>
                                                :
                                                <p>
                                                    {value.type}
                                                    {value.surfaceMin !== null ? ", " : ""}
                                                    {value.surfaceMin !== null ? value.surfaceMin + "m²" : ""}
                                                    {value.surfaceMax !== null ? value.surfaceMin < value.surfaceMax ? " | " + value.surfaceMax + " m²" : "" : ""}
                                                    {value.budgetMin !== null ? ", " : ""}
                                                    {value.budgetMin !== null ? value.budgetMin + " €" : ""}
                                                    {value.budgetMax !== null ? value.budgetMin < value.budgetMax ? " | " + value.budgetMax + " €" : "" : ""}
                                                </p>
                                        }

                                        <div className="action">
                                            <a
                                                href=""
                                                onClick={(e) =>
                                                    this.handleConsulteAlertBin(
                                                        e,
                                                        index
                                                    )
                                                }
                                            >
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="#43a1db"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12ZM12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75Z"/>
                                                    <path
                                                        d="M4.32343 10.6464C3.90431 11.2503 3.75 11.7227 3.75 12C3.75 12.2773 3.90431 12.7497 4.32343 13.3536C4.72857 13.9374 5.33078 14.5703 6.09267 15.155C7.61978 16.3271 9.71345 17.25 12 17.25C14.2865 17.25 16.3802 16.3271 17.9073 15.155C18.6692 14.5703 19.2714 13.9374 19.6766 13.3536C20.0957 12.7497 20.25 12.2773 20.25 12C20.25 11.7227 20.0957 11.2503 19.6766 10.6464C19.2714 10.0626 18.6692 9.42972 17.9073 8.84497C16.3802 7.67292 14.2865 6.75 12 6.75C9.71345 6.75 7.61978 7.67292 6.09267 8.84497C5.33078 9.42972 4.72857 10.0626 4.32343 10.6464ZM5.17941 7.65503C6.90965 6.32708 9.31598 5.25 12 5.25C14.684 5.25 17.0903 6.32708 18.8206 7.65503C19.6874 8.32028 20.4032 9.06244 20.9089 9.79115C21.4006 10.4997 21.75 11.2773 21.75 12C21.75 12.7227 21.4006 13.5003 20.9089 14.2089C20.4032 14.9376 19.6874 15.6797 18.8206 16.345C17.0903 17.6729 14.684 18.75 12 18.75C9.31598 18.75 6.90965 17.6729 5.17941 16.345C4.31262 15.6797 3.59681 14.9376 3.0911 14.2089C2.59937 13.5003 2.25 12.7227 2.25 12C2.25 11.2773 2.59937 10.4997 3.0911 9.79115C3.59681 9.06244 4.31262 8.32028 5.17941 7.65503Z"/>
                                                </svg>
                                            </a>
                                            <a
                                                href=""
                                                onClick={(e) =>
                                                    this.handleModifierAlertBin(
                                                        e,
                                                        index
                                                    )
                                                }
                                            >
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="#43a1db"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M21.4549 5.41575C21.6471 5.70687 21.615 6.10248 21.3588 6.35876L12.1664 15.5511C12.0721 15.6454 11.9545 15.7128 11.8256 15.7465L7.99716 16.7465C7.87229 16.7791 7.74358 16.7784 7.62265 16.7476C7.49408 16.7149 7.37431 16.6482 7.27729 16.5511C7.08902 16.3629 7.01468 16.0889 7.08197 15.8313L8.08197 12.0028C8.11144 11.89 8.16673 11.7786 8.24322 11.6912L17.4697 2.46967C17.5504 2.38891 17.6477 2.32846 17.7536 2.29163C17.8321 2.26432 17.9153 2.25 18 2.25C18.1989 2.25 18.3897 2.32902 18.5303 2.46967L21.3588 5.2981C21.3954 5.33471 21.4274 5.37416 21.4549 5.41575ZM19.7678 5.82843L18 4.06066L9.48184 12.5788L8.85685 14.9716L11.2496 14.3466L19.7678 5.82843Z"/>
                                                    <path
                                                        d="M19.6414 17.1603C19.9148 14.8227 20.0018 12.4688 19.9023 10.1208C19.8976 10.0084 19.9399 9.89898 20.0194 9.81942L21.0027 8.83609C21.1236 8.71519 21.3302 8.79194 21.3415 8.96254C21.5265 11.7522 21.4564 14.5545 21.1312 17.3346C20.8946 19.3571 19.2703 20.9421 17.2583 21.167C13.7917 21.5544 10.2083 21.5544 6.74177 21.167C4.72971 20.9421 3.10538 19.3571 2.86883 17.3346C2.45429 13.7903 2.45429 10.2097 2.86883 6.66543C3.10538 4.6429 4.72971 3.05789 6.74177 2.83301C9.37152 2.5391 12.0685 2.46815 14.7306 2.62016C14.9022 2.62996 14.9804 2.83757 14.8589 2.95909L13.8664 3.95165C13.7877 4.03034 13.6798 4.07261 13.5685 4.06885C11.3421 3.99376 9.10055 4.07872 6.90838 4.32373C5.57827 4.47239 4.51278 5.522 4.35867 6.83968C3.95767 10.2682 3.95767 13.7318 4.35867 17.1603C4.51278 18.478 5.57827 19.5276 6.90838 19.6763C10.2642 20.0513 13.7358 20.0513 17.0916 19.6763C18.4218 19.5276 19.4872 18.478 19.6414 17.1603Z"/>
                                                </svg>
                                            </a>
                                            <a
                                                href=""
                                                onClick={(e) =>
                                                    this.handleSupprimerAlertBin(
                                                        e,
                                                        index
                                                    )
                                                }
                                            >
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="red"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V3.75H5C4.58579 3.75 4.25 4.08579 4.25 4.5C4.25 4.91421 4.58579 5.25 5 5.25H19C19.4142 5.25 19.75 4.91421 19.75 4.5C19.75 4.08579 19.4142 3.75 19 3.75H14.75V3C14.75 2.58579 14.4142 2.25 14 2.25H10Z"/>
                                                    <path
                                                        d="M10 10.65C10.4142 10.65 10.75 10.9858 10.75 11.4L10.75 18.4C10.75 18.8142 10.4142 19.15 10 19.15C9.58579 19.15 9.25 18.8142 9.25 18.4L9.25 11.4C9.25 10.9858 9.58579 10.65 10 10.65Z"/>
                                                    <path
                                                        d="M14.75 11.4C14.75 10.9858 14.4142 10.65 14 10.65C13.5858 10.65 13.25 10.9858 13.25 11.4V18.4C13.25 18.8142 13.5858 19.15 14 19.15C14.4142 19.15 14.75 18.8142 14.75 18.4V11.4Z"/>
                                                    <path
                                                        d="M5.99142 7.91718C6.03363 7.53735 6.35468 7.25 6.73684 7.25H17.2632C17.6453 7.25 17.9664 7.53735 18.0086 7.91718L18.2087 9.71852C18.5715 12.9838 18.5715 16.2793 18.2087 19.5446L18.189 19.722C18.045 21.0181 17.0404 22.0517 15.7489 22.2325C13.2618 22.5807 10.7382 22.5807 8.25108 22.2325C6.95954 22.0517 5.955 21.0181 5.81098 19.722L5.79128 19.5446C5.42846 16.2793 5.42846 12.9838 5.79128 9.71852L5.99142 7.91718ZM7.40812 8.75L7.2821 9.88417C6.93152 13.0394 6.93152 16.2238 7.2821 19.379L7.3018 19.5563C7.37011 20.171 7.84652 20.6612 8.45905 20.747C10.8082 21.0758 13.1918 21.0758 15.5409 20.747C16.1535 20.6612 16.6299 20.171 16.6982 19.5563L16.7179 19.379C17.0685 16.2238 17.0685 13.0394 16.7179 9.88417L16.5919 8.75H7.40812Z"/>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                {this.state.openFlashMessage && (
                    <div className="modal modalSee">
                        <div className="backgroundModal">
                            <div className="contentModal">
                                <p>{this.state.flashMessage}</p>
                                <div>
                                    <a
                                        href=""
                                        onClick={(e) =>
                                            this.handleCloseMessageAlert(e)
                                        }
                                    >
                                        OK
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="blockSearch">
                    {this.state.recherche[0]
                        ? this.state.recherche.map((value, index) => (
                            <div
                                key={index}
                                className={`bienBlock ${
                                    value.relation ? "relation" : ""
                                }`}
                            >
                                <div className="headerBien">
                                    <h2>{value.titre}</h2>
                                </div>
                                <div className="contentBien">
                                    <div className="dateformat">
                                        <p> {value.dateFormat}</p>
                                    </div>
                                    <div className="chemin">
                                        <h2>
                                            {value.chemin === "habitat"
                                                ? "Habitat"
                                                : "Immobilier d’entreprise"}
                                        </h2>
                                    </div>
                                    <div className="contentBien">
                                        <div className="blockRecap">
                                            <p>Type de bien :</p>
                                            <p>
                                                {value.contextProjet} {value.type}{" "}
                                                {value.etat}
                                            </p>
                                        </div>
                                        {value.locas.map((v, i) => (
                                            <>
                                                <div key={i} className="blockRecap">
                                                    <p>Localisations {i + 1}:</p>
                                                    <p>
                                                        {v.departement}{" "}
                                                        {`- ${v.ville}`}{" "}
                                                        {`${
                                                            v.quartier !== null
                                                                ? "-" + v.quartier
                                                                : ""
                                                        }`}
                                                    </p>
                                                    <br/>
                                                </div>
                                            </>
                                        ))}
                                        {
                                            value.type === "Terrain" ?
                                                <div className="blockRecap">
                                                    <p>Superficie :</p>
                                                    <p>
                                                        {value.terrainMin !== null ? ` ${value.terrainMin}${value.terrainMin !== value.terrainMax ? ` / ${value.terrainMax}` : ""} m² d'extérieur` : ""}</p>
                                                </div>
                                                :
                                                <>
                                                    <div className="blockRecap">
                                                        <p>Superficie :</p>
                                                        <p>{value.surfaceMin}{value.surfaceMin !== value.surfaceMax ? value.surfaceMax !== null ? ` / ${value.surfaceMax} m² habitable` : " m² habitable " : " m² habitable"}
                                                            {value.terrainMin !== null ? ` | ${value.terrainMin}${value.terrainMin !== value.terrainMax ? ` / ${value.terrainMax}` : ""} m² d'extérieur` : ""}</p>
                                                    </div>
                                                    {value.chemin === "habitat" && (
                                                        <div className="blockRecap">
                                                            <p>Configuration :</p>
                                                            <p>{value.piece === 0 ? "Studio" : `${value.piece} pièce${value.piece > 1 ? "s" : ""}`}
                                                                {value.chambre !== null ? ` | ${value.chambre} chambre${value.chambre > 1 ? "s" : ""}` : ""}
                                                                {value.sdb !== null ? ` | ${value.sdb} Salle${value.sdb > 1 ? "s" : ""} de bain` : ""}</p>
                                                        </div>
                                                    )}
                                                </>
                                        }


                                        {
                                            value.travaux !== null &&
                                            <div className="blockRecap">
                                                <p>Travaux :</p>
                                                <p>{value.travaux}</p>
                                            </div>
                                        }
                                        <div className="blockRecap">
                                            <p>Budget :</p>
                                            <p>{value.budgetMin != null ? `${value.budgetMin}${value.budgetMax !== value.budgetMin ? value.budgetMax !== null ? " - " + value.budgetMax : "" : ""} €` : "non communiqué"}</p>
                                        </div>
                                        <div className="blockRecap">
                                            <p>Délai de réalisation :</p>
                                            <p>{value.delai}</p>
                                        </div>
                                    </div>
                                </div>
                                {value.relation ? (
                                    <div className="footerAddBien">
                                        <p>
                                            Vous avez déjà pris contact
                                        </p>
                                    </div>
                                ) : (
                                    <div className="footerAddBien">
                                        <a
                                            href=""
                                            onClick={(e) =>
                                                this.handleMiseEnRelation(
                                                    e,
                                                    value.user_id,
                                                    value.id
                                                )
                                            }
                                        >
                                            Contacter l’acheteur
                                        </a>
                                    </div>
                                )}
                            </div>
                        ))
                        : this.state.modeRecherche && (
                        <div className="nothingSearch">
                            <p>
                                Aucun bien ne correspond à votre recherche
                            </p>
                        </div>
                    )}
                </div>
                {this.state.openFormRelation && (
                    <div className="modal modalSee">
                        <div className="backgroundModal">
                            <div className="contentModal">
                                {
                                    this.state.phone !== null ?
                                        <>
                                            <h3>{this.state.namePart}</h3>
                                            <h3>N° de téléphone du porteur de projet : {this.state.phone}</h3>
                                        </>
                                        :
                                        <>
                                            <h3>{this.state.namePart}</h3>
                                            <h3>Le porteur de projet n’a pas communiqué son N° de téléphone</h3>
                                        </>
                                }
                                <h3>Lui envoyer un message avec votre carte de visite digitale</h3>
                                <textarea
                                    onChange={(e) =>
                                        this.setState({
                                            messageRelation: e.target.value,
                                        })
                                    }
                                >
                                    {this.state.messageRelation}
                                </textarea>
                                <div>
                                    <a
                                        href=""
                                        onClick={(e) =>
                                            this.handleCloseFormRelation(e)
                                        }
                                    >
                                        Annuler
                                    </a>
                                    <a
                                        href=""
                                        id="relationAdd"
                                        onClick={(e) =>
                                            this.handleAddFormRelation(e)
                                        }
                                    >
                                        Valider
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.openDeleteModal && (
                    <div className="modal modalSee">
                        <div className="backgroundModal">
                            <div className="contentModal">
                                <p>
                                    Voulez-vous vraiment surppimer l'alerte{" "}
                                    {this.state.currentAlert.name}
                                </p>
                                <div>
                                    <a
                                        href=""
                                        onClick={(e) =>
                                            this.handleAnnuleDeleteAlert(e)
                                        }
                                    >
                                        Annuler
                                    </a>
                                    <a
                                        href=""
                                        className="warning"
                                        onClick={(e) =>
                                            this.handleDeleteAlert(e)
                                        }
                                    >
                                        Supprimer
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
