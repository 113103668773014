import React from "react";
import ReactDOM from 'react-dom';

import FormProjetVenteModal from "./FormProjetVenteModal";
import ChatComponent from "./ChatComponent";
import {flatMap} from "lodash";


export default class BlockProjetsPartiVente extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projets: this.props.projets,
            openModalSee: false,
            openModalEdit: false,
            openModalDelete: false,
            currentProjet: {},
            currentRelation: {},
            currentId: null,
            openModalAdd: false,
            openChat: false,
            messages: [],
            currentUserId: this.props.user,
            modalSee: false,
            openMessageFlash:false,
            messageFlash:"",
            type:this.props.type,
            proId:null,
        }
        this.handleSeeOpenProjet = this.handleSeeOpenProjet.bind(this);
        this.handleEditOpenProjet = this.handleEditOpenProjet.bind(this);
        this.handleEditCloseProjet = this.handleEditCloseProjet.bind(this);
        this.handleDeleteProjet = this.handleDeleteProjet.bind(this);
        this.handleDeleteCloseProjet = this.handleDeleteCloseProjet.bind(this);
        this.handleDeleteProjetBdd = this.handleDeleteProjetBdd.bind(this);
        this.handleAddProjet = this.handleAddProjet.bind(this);
        this.handleAddCloseProjet = this.handleAddCloseProjet.bind(this);
        this.openPro = this.openPro.bind(this);
        this.validationRelationPro = this.validationRelationPro.bind(this);
        this.handleOpenChat = this.handleOpenChat.bind(this);
        this.handleSeeCloseProjet = this.handleSeeCloseProjet.bind(this);
        this.handleSeeCloseChat = this.handleSeeCloseChat.bind(this);
        this.handleSeeProjet = this.handleSeeProjet.bind(this);
        this.handleCloseSeeProjet = this.handleCloseSeeProjet.bind(this);
    }
    async componentDidMount() {
        let {projets} = this.state;
        let url = window.location.href;
        url = url.split("?");
        if(url[1]){
            url = url[1].split("&");
            let type = null;
            let currentProjet = null;
            let currentRelation = null;
            let openModalSee = null;
            await url.map(v => {
                let newParam = v.split("=");
                if(newParam[0] == "type"){
                    type = newParam[1];
                }else if(newParam[0] == 'chat'){
                    openModalSee = true;
                }else if(newParam[0] == 'id'){
                    currentProjet = projets.filter( pro => pro.id == parseInt(newParam[1]));
                }else{
                    currentRelation = currentProjet[0].relation.filter(cr => cr.user_pro_id == parseInt(newParam[1]));
                }
            });

            await this.setState({
                type,
                currentProjet:currentProjet[0],
                currentRelation:currentRelation[0],
                openModalSee,
                messages:currentRelation[0].message
            });
        }

    }

    handleSeeProjet(e, id, index) {
        e.preventDefault();
        this.setState({
            modalSee: true,
            currentProjet: this.state.projets[index],
            currentId: id,
        })
    }

    handleCloseSeeProjet() {
        this.setState({
            modalSee: false,
            currentProjet: {},
            currentId: null,
        })
    }

    handleSeeOpenProjet(e, id, index, iRelation) {
        e.preventDefault();
        this.setState({
            currentProjet: this.state.projets[index],
            currentId: id,
        },() => {
            this.setState({
                proId:this.state.currentProjet.relation[iRelation].user_pro_id,
                currentRelation:this.state.currentProjet.relation[iRelation],
                messages:this.state.currentProjet.relation[iRelation].message
            },() => {
                this.setState({openModalSee: true,})
            })
        })

    }

    handleSeeCloseProjet() {
        this.setState({
            openModalSee: false,
            currentProjet: {},
            currentId: null,
        })
    }

    handleEditOpenProjet(e, id, index) {
        e.preventDefault();
        this.setState({
            openModalEdit: true,
            currentProjet: this.state.projets[index],
            currentId: id,
        })
    }

    handleEditCloseProjet() {
        this.setState({
            openModalEdit: false,
            currentProjet: {},
            currentId: null,
        })
    }

    handleDeleteProjet(e, id, index) {
        e.preventDefault();
        this.setState({
            openModalDelete: true,
            currentProjet: this.state.projets[index],
            currentId: id,
        })
    }

    handleDeleteCloseProjet(e) {
        e.preventDefault();
        this.setState({
            openModalDelete: false,
            currentProjet: {},
            currentId: null,
        })
    }

    handleDeleteProjetBdd(e) {
        e.preventDefault();
        axios.post('/particulier/projet/delete', {
            id: this.state.currentId,
            type:this.state.type,
        }).then(res => {
            console.log(res.data);
            if (res.data === 1) {
                window.location.reload();
            }
        }).catch(error => {
            console.log(error)
        })
    }

    handleAddProjet(e) {
        e.preventDefault();
        this.setState({openModalAdd: true});
    }

    handleAddCloseProjet() {
        this.setState({
            openModalAdd: false,
            currentProjet: {},
            currentId: null,
        })
    }

    handleChangeStatusProjet(e, value, id) {
        e.preventDefault();
        let status = !value;
        axios.post('/particulier/change/status/projet', {
            id,
            status,
            type:this.state.type,
        }).then(res => {
            let projets = res.data;
            this.setState({projets: projets});
        }).catch(error => {

        })
    }

    openPro(index) {
        this.setState({[`openPro-${index}`]: true})
    }

    closePro(index) {
        this.setState({[`openPro-${index}`]: false})
    }

    validationRelationPro(id, value) {
        const {currentId} = this.state;
        axios.post('/particulier/validate/relation', {
            id,
            status: value,
            type:this.state.type,
        }).then(res => {
            let projets = res.data;
            let cp = projets.filter(r => r.id === currentId);
            this.setState({
                projets: projets,
                currentProjet: cp[0]
            },() => {
                let nom = this.state.currentRelation.pro.nom + " " + this.state.currentRelation.pro.prenom;
                if(value === "Accepter"){
                    let message = `Bravo ! Vous avez accepté la mise en relation avec ${nom} qui vous contactera prochainement.\n Nous vous souhaitons beaucoup de réussite dans votre projet de vente.`;
                    this.setState({messageFlash:message,openMessageFlash:true})
                }else{
                    let message = `Vous avez refusé la mise en relation avec ${nom}. Nous espérons que vous trouverez rapidement votre professionnel pour vous accompagner dans votre projet de vente.`;
                    this.setState({messageFlash:message,openMessageFlash:true})
                }
            });
        }).catch(error => {
            console.log(error)
        })
    }
    handleFlashCloseProjet(e){
        e.preventDefault();
        this.setState({messageFlash:"",openMessageFlash:false,openModalSee:false})
    }
    handleOpenChat(e, index) {
        e.preventDefault();
        this.setState({
            messages: this.state.currentProjet.relation[index].message,
            currentRelation: this.state.currentProjet.relation[index],
        }, () => {
            this.setState({[`openChat-${index}`]: true});
        })
    }

    handleSeeCloseChat(index) {
        this.setState({
            messages: [],
            [`openChat-${index}`]: false
        })
    }

    render() {
        return (
            <>
                {/* AJOUTER PROJET */}
                <div className="addProjet">
                    <a href="" onClick={(e) => this.handleAddProjet(e)}>
                        Ajouter un projet de vente
                    </a>
                </div>
                {/* AFFICHAGE PAGE */}
                <div className="blockProjets">
                    <div className="blockProjetsHeader">
                        <div className="block">Votre projet</div>
                        <div className="block">Votre contact</div>
                        <div className="block">Votre mise en relation</div>
                        <div className="block">Action</div>
                    </div>
                    {this.state.projets.map((value, index) => (
                        <div key={index} className="blockProjetsBody">
                            <div className="block">
                                {
                                    value.titre !== null &&
                                    <>
                                        {value.titre}
                                    </>
                                }
                            </div>

                            <div className="block">
                                {value.relation.map((v, i) => (
                                        <div key={i} className={value.relation.length > 1 ? "line multi" :"line"}>
                                    <p>
                                            {v.pro.nom} | {v.agences.agence}

                                    </p>
                                            <a
                                                href=""
                                                onClick={(e) =>
                                                    this.handleSeeOpenProjet(
                                                        e,
                                                        v.id,
                                                        index,
                                                        i
                                                    )
                                                }
                                            >
                                                <span>Dialoguer</span>
                                                <svg fill="#43a1db" xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 42 39">
                                                    <g id="chat" transform="translate(-3 -6)">
                                                        <path id="Tracé_349" data-name="Tracé 349" d="M26.61,45,24,43.5,30,33h9a3,3,0,0,0,3-3V12a3,3,0,0,0-3-3H9a3,3,0,0,0-3,3V30a3,3,0,0,0,3,3H22.5v3H9a6,6,0,0,1-6-6V12A6,6,0,0,1,9,6H39a6,6,0,0,1,6,6V30a6,6,0,0,1-6,6H31.74Z"/>
                                                        <path id="Tracé_350" data-name="Tracé 350" d="M12,15H36v3H12Z"/>
                                                        <path id="Tracé_351" data-name="Tracé 351" d="M12,24H27v3H12Z"/>
                                                    </g>
                                                </svg>
                                                <span className="dot"></span>
                                            </a>
                                        </div>
                                ))}
                            </div>
                            <div className="block">
                                {value.relation.map((v, i) => (
                                    <div key={i}>
                                        <div className={value.relation.length > 1 ? "blockRelation multi" : "blockRelation"}>
                                            <p key={i} className="accepter">
                                                {v.status == "Accepter"
                                                    ? `Validée`
                                                    : v.status == "Refuser"
                                                    ? `Refusé`
                                                    : v.status == "En attente"
                                                    ? `En attente de votre réponse`
                                                    : ""}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="block">
                                {value.status ? (
                                    <>
                                        <p>Projet actif</p>
                                        <a
                                            href=""
                                            onClick={(e) =>
                                                this.handleChangeStatusProjet(
                                                    e,
                                                    value.status,
                                                    value.id
                                                )
                                            }
                                        >
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="#2f2f2f"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M16 15.5C17.7849 15.5 19.2778 14.137 19.464 12.3681C19.4897 12.1245 19.4897 11.8755 19.464 11.6319C19.2778 9.86302 17.7849 8.5 16 8.5C14.2152 8.5 12.7222 9.86302 12.536 11.6319C12.5104 11.8755 12.5104 12.1245 12.536 12.3681C12.7222 14.137 14.2152 15.5 16 15.5Z" />
                                                <path d="M15.9257 18.75H8.07434C4.75284 18.75 1.92497 16.3336 1.40694 13.0527C1.29681 12.3552 1.29681 11.6448 1.40694 10.9473C1.92497 7.6664 4.75285 5.25 8.07434 5.25H15.9257C19.2472 5.25 22.075 7.6664 22.5931 10.9473C22.7032 11.6448 22.7032 12.3552 22.5931 13.0527C22.075 16.3336 19.2472 18.75 15.9257 18.75ZM15.9257 17.25C18.5091 17.25 20.7085 15.3706 21.1114 12.8188C21.1971 12.2763 21.1971 11.7237 21.1114 11.1812C20.7085 8.62942 18.5091 6.75 15.9257 6.75L8.07434 6.75C5.49096 6.75 3.2915 8.62942 2.88859 11.1812C2.80293 11.7237 2.80293 12.2763 2.88858 12.8188C3.2915 15.3706 5.49095 17.25 8.07434 17.25H15.9257Z" />
                                            </svg>
                                        </a>
                                    </>
                                ) : (
                                    <>
                                        <p>Projet mis en pause</p>
                                        <a
                                            href=""
                                            onClick={(e) =>
                                                this.handleChangeStatusProjet(
                                                    e,
                                                    value.status,
                                                    value.id
                                                )
                                            }
                                        >
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="#2f2f2f"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M8 15.5C6.21517 15.5 4.72218 14.137 4.53599 12.3681C4.51034 12.1245 4.51034 11.8755 4.53599 11.6319C4.72218 9.86302 6.21516 8.5 8 8.5C9.78483 8.5 11.2778 9.86302 11.464 11.6319C11.4896 11.8755 11.4896 12.1245 11.464 12.3681C11.2778 14.137 9.78483 15.5 8 15.5Z" />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M8.07434 18.75H15.9257C19.2472 18.75 22.075 16.3336 22.5931 13.0527C22.7032 12.3552 22.7032 11.6448 22.5931 10.9473C22.075 7.6664 19.2472 5.25 15.9257 5.25H8.07434C4.75284 5.25 1.92497 7.6664 1.40694 10.9473C1.29681 11.6448 1.29681 12.3552 1.40694 13.0527C1.92497 16.3336 4.75284 18.75 8.07434 18.75ZM8.07434 17.25C5.49095 17.25 3.2915 15.3706 2.88859 12.8188C2.80293 12.2763 2.80293 11.7237 2.88859 11.1812C3.2915 8.62942 5.49095 6.75 8.07434 6.75L15.9257 6.75C18.5091 6.75 20.7085 8.62942 21.1114 11.1812C21.1971 11.7237 21.1971 12.2763 21.1114 12.8188C20.7085 15.3706 18.5091 17.25 15.9257 17.25H8.07434Z"
                                                />
                                            </svg>
                                        </a>
                                    </>
                                )}
                                <a
                                    href=""
                                    onClick={(e) =>
                                        this.handleSeeProjet(e, value.id, index)
                                    }
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="#43A1DB"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12ZM12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75Z" />
                                        <path d="M4.32343 10.6464C3.90431 11.2503 3.75 11.7227 3.75 12C3.75 12.2773 3.90431 12.7497 4.32343 13.3536C4.72857 13.9374 5.33078 14.5703 6.09267 15.155C7.61978 16.3271 9.71345 17.25 12 17.25C14.2865 17.25 16.3802 16.3271 17.9073 15.155C18.6692 14.5703 19.2714 13.9374 19.6766 13.3536C20.0957 12.7497 20.25 12.2773 20.25 12C20.25 11.7227 20.0957 11.2503 19.6766 10.6464C19.2714 10.0626 18.6692 9.42972 17.9073 8.84497C16.3802 7.67292 14.2865 6.75 12 6.75C9.71345 6.75 7.61978 7.67292 6.09267 8.84497C5.33078 9.42972 4.72857 10.0626 4.32343 10.6464ZM5.17941 7.65503C6.90965 6.32708 9.31598 5.25 12 5.25C14.684 5.25 17.0903 6.32708 18.8206 7.65503C19.6874 8.32028 20.4032 9.06244 20.9089 9.79115C21.4006 10.4997 21.75 11.2773 21.75 12C21.75 12.7227 21.4006 13.5003 20.9089 14.2089C20.4032 14.9376 19.6874 15.6797 18.8206 16.345C17.0903 17.6729 14.684 18.75 12 18.75C9.31598 18.75 6.90965 17.6729 5.17941 16.345C4.31262 15.6797 3.59681 14.9376 3.0911 14.2089C2.59937 13.5003 2.25 12.7227 2.25 12C2.25 11.2773 2.59937 10.4997 3.0911 9.79115C3.59681 9.06244 4.31262 8.32028 5.17941 7.65503Z" />
                                    </svg>
                                </a>

                                <a
                                    href=""
                                    onClick={(e) =>
                                        this.handleEditOpenProjet(
                                            e,
                                            value.id,
                                            index
                                        )
                                    }
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="#3186BA"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M21.4549 5.41575C21.6471 5.70687 21.615 6.10248 21.3588 6.35876L12.1664 15.5511C12.0721 15.6454 11.9545 15.7128 11.8256 15.7465L7.99716 16.7465C7.87229 16.7791 7.74358 16.7784 7.62265 16.7476C7.49408 16.7149 7.37431 16.6482 7.27729 16.5511C7.08902 16.3629 7.01468 16.0889 7.08197 15.8313L8.08197 12.0028C8.11144 11.89 8.16673 11.7786 8.24322 11.6912L17.4697 2.46967C17.5504 2.38891 17.6477 2.32846 17.7536 2.29163C17.8321 2.26432 17.9153 2.25 18 2.25C18.1989 2.25 18.3897 2.32902 18.5303 2.46967L21.3588 5.2981C21.3954 5.33471 21.4274 5.37416 21.4549 5.41575ZM19.7678 5.82843L18 4.06066L9.48184 12.5788L8.85685 14.9716L11.2496 14.3466L19.7678 5.82843Z" />
                                        <path d="M19.6414 17.1603C19.9148 14.8227 20.0018 12.4688 19.9023 10.1208C19.8976 10.0084 19.9399 9.89898 20.0194 9.81942L21.0027 8.83609C21.1236 8.71519 21.3302 8.79194 21.3415 8.96254C21.5265 11.7522 21.4564 14.5545 21.1312 17.3346C20.8946 19.3571 19.2703 20.9421 17.2583 21.167C13.7917 21.5544 10.2083 21.5544 6.74177 21.167C4.72971 20.9421 3.10538 19.3571 2.86883 17.3346C2.45429 13.7903 2.45429 10.2097 2.86883 6.66543C3.10538 4.6429 4.72971 3.05789 6.74177 2.83301C9.37152 2.5391 12.0685 2.46815 14.7306 2.62016C14.9022 2.62996 14.9804 2.83757 14.8589 2.95909L13.8664 3.95165C13.7877 4.03034 13.6798 4.07261 13.5685 4.06885C11.3421 3.99376 9.10055 4.07872 6.90838 4.32373C5.57827 4.47239 4.51278 5.522 4.35867 6.83968C3.95767 10.2682 3.95767 13.7318 4.35867 17.1603C4.51278 18.478 5.57827 19.5276 6.90838 19.6763C10.2642 20.0513 13.7358 20.0513 17.0916 19.6763C18.4218 19.5276 19.4872 18.478 19.6414 17.1603Z" />
                                    </svg>
                                </a>
                                <a
                                    href=""
                                    onClick={(e) =>
                                        this.handleDeleteProjet(
                                            e,
                                            value.id,
                                            index
                                        )
                                    }
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="#2F2F2F"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V3.75H5C4.58579 3.75 4.25 4.08579 4.25 4.5C4.25 4.91421 4.58579 5.25 5 5.25H19C19.4142 5.25 19.75 4.91421 19.75 4.5C19.75 4.08579 19.4142 3.75 19 3.75H14.75V3C14.75 2.58579 14.4142 2.25 14 2.25H10Z" />
                                        <path d="M10 10.65C10.4142 10.65 10.75 10.9858 10.75 11.4L10.75 18.4C10.75 18.8142 10.4142 19.15 10 19.15C9.58579 19.15 9.25 18.8142 9.25 18.4L9.25 11.4C9.25 10.9858 9.58579 10.65 10 10.65Z" />
                                        <path d="M14.75 11.4C14.75 10.9858 14.4142 10.65 14 10.65C13.5858 10.65 13.25 10.9858 13.25 11.4V18.4C13.25 18.8142 13.5858 19.15 14 19.15C14.4142 19.15 14.75 18.8142 14.75 18.4V11.4Z" />
                                        <path d="M5.99142 7.91718C6.03363 7.53735 6.35468 7.25 6.73684 7.25H17.2632C17.6453 7.25 17.9664 7.53735 18.0086 7.91718L18.2087 9.71852C18.5715 12.9838 18.5715 16.2793 18.2087 19.5446L18.189 19.722C18.045 21.0181 17.0404 22.0517 15.7489 22.2325C13.2618 22.5807 10.7382 22.5807 8.25108 22.2325C6.95954 22.0517 5.955 21.0181 5.81098 19.722L5.79128 19.5446C5.42846 16.2793 5.42846 12.9838 5.79128 9.71852L5.99142 7.91718ZM7.40812 8.75L7.2821 9.88417C6.93152 13.0394 6.93152 16.2238 7.2821 19.379L7.3018 19.5563C7.37011 20.171 7.84652 20.6612 8.45905 20.747C10.8082 21.0758 13.1918 21.0758 15.5409 20.747C16.1535 20.6612 16.6299 20.171 16.6982 19.5563L16.7179 19.379C17.0685 16.2238 17.0685 13.0394 16.7179 9.88417L16.5919 8.75H7.40812Z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
                {/* MODAL SEE */}
                {this.state.modalSee && (
                    <div className="modal modalSee">
                        <div className="backgroundModal">
                            <div className="contentModal">
                                <div className="header">
                                    <div className="title">
                                        <h3>
                                            {this.state.currentProjet.titre}
                                        </h3>
                                    </div>
                                    <div
                                        className="close"
                                        onClick={this.handleCloseSeeProjet}
                                    >
                                        <svg
                                            width="40"
                                            height="40"
                                            viewBox="0 0 24 24"
                                            fill="#2f2f2f"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8.46445 15.5354L15.5355 8.46436"
                                                stroke="#2f2f2f"
                                            />
                                            <path
                                                d="M8.46446 8.46458L15.5355 15.5356"
                                                stroke="#2f2f2f"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div className="containerRecape">
                                    <div className="blockRecap">
                                        <h4>Type de bien :</h4>
                                        <p>{this.state.currentProjet.type}</p>
                                    </div>
                                    <div className="blockRecap">
                                        <h4>Localisation :</h4>
                                        <p>
                                            {
                                                this.state.currentProjet
                                                    .localisation
                                            }
                                        </p>
                                    </div>
                                    {this.state.currentProjet.type ===
                                        "Appartement" && (
                                        <div className="blockRecap">
                                            <h4>Étage :</h4>
                                            <p>
                                                {this.state.currentProjet.etage}{" "}
                                                /{" "}
                                                {
                                                    this.state.currentProjet
                                                        .etageTotal
                                                }
                                            </p>
                                        </div>
                                    )}
                                    {
                                        this.state.currentProjet.type !== "Terrain" ?
                                        <>
                                        <div className="blockRecap">
                                        <h4>Superficie :</h4>
                                        <p>
                                            {this.state.currentProjet.surfaceMin}
                                            {this.state.currentProjet.surfaceMax !== null ? this.state.currentProjet.surfaceMin !== this.state.currentProjet.surfaceMax ? ` / ${this.state.currentProjet.surfaceMax} m² habitable` : " m² habitable " : "m² habitable"}
                                            {this.state.currentProjet.terrainMin !== null ? ` | ${this.state.currentProjet.terrainMin}
    ${this.state.currentProjet.terrainMax !== null ? this.state.currentProjet.terrainMin !== this.state.currentProjet.terrainMax ? ` / ${this.state.currentProjet.terrainMax}` : "" :""} m² d'extérieur`
                                                : ""}
                                        </p>
                                    </div>
                                    <div className="blockRecap">
                                        <h4>Configuration :</h4>
                                        <p>
                                            {this.state.currentProjet.piece ===
                                            0
                                                ? "Studio"
                                                : `${
                                                      this.state.currentProjet
                                                          .piece
                                                  } pièce${
                                                      this.state.currentProjet
                                                          .piece > 1
                                                          ? "s"
                                                          : ""
                                                  }`}
                                            {this.state.currentProjet
                                                .chambre !== null
                                                ? ` | ${
                                                      this.state.currentProjet
                                                          .chambre
                                                  } chambre${
                                                      this.state.currentProjet
                                                          .chambre > 1
                                                          ? "s"
                                                          : ""
                                                  }`
                                                : ""}
                                            {this.state.currentProjet.sdb !==
                                            null
                                                ? ` | ${
                                                      this.state.currentProjet
                                                          .sdb
                                                  } Salle${
                                                      this.state.currentProjet
                                                          .sdb > 1
                                                          ? "s"
                                                          : ""
                                                  } de bain`
                                                : ""}
                                        </p>
                                    </div>
                                        </>
                                        :
                                        <div className="blockRecap">
                                        <h4>Superficie :</h4>
                                        <p>
                                            {this.state.currentProjet
                                                .terrainMin !== null
                                                ? ` ${
                                                      this.state.currentProjet
                                                          .terrainMin
                                                  }${
                                                      this.state.currentProjet
                                                          .terrainMin !==
                                                      this.state.currentProjet
                                                          .terrainMax
                                                          ? ` / ${this.state.currentProjet.terrainMax}`
                                                          : ""
                                                  } m² d'extérieur`
                                                : ""}
                                        </p>
                                    </div>
                                    }


                                    <div className="blockRecap">
                                        <h4>Caractéristiques :</h4>
                                        <p>
                                            {
                                                this.state.currentProjet
                                                    .caracteristique
                                            }
                                        </p>
                                    </div>
                                    <div className="blockRecap">
                                        <h4>Prix Net vendeur souhaité :</h4>
                                        <p>
                                            {this.state.currentProjet
                                                .budgetMin !== null
                                                ? this.state.currentProjet
                                                      .budgetMin ===
                                                  this.state.currentProjet
                                                      .budgetMax
                                                    ? this.state.currentProjet
                                                          .budgetMin + " €"
                                                    : this.state.currentProjet
                                                          .budgetMin +
                                                      "€ | " +
                                                      this.state.currentProjet
                                                          .budgetMax +
                                                      " €"
                                                : "non communiqué"}
                                        </p>
                                    </div>
                                    <div className="blockRecap">
                                        <h4>Délai de réalisation :</h4>
                                        <p>{this.state.currentProjet.delai}</p>
                                    </div>
                                    {this.state.currentProjet.presentation !==
                                        "" && (
                                        <div className="blockRecap">
                                            <h4>Descriptif :</h4>
                                            <p>
                                                {
                                                    this.state.currentProjet
                                                        .presentation
                                                }
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {this.state.openModalSee && (
                    <div className="modal modalSee">
                        <div className="backgroundModal">
                            <div className="contentModal modalRecap">
                                <div className="header">
                                    <div className="title">
                                        <p>
                                            {this.state.currentRelation.pro.nom}{" "}
                                            {
                                                this.state.currentRelation.pro
                                                    .prenom
                                            }
                                            {this.state.currentRelation.agences
                                                .agence !== null
                                                ? " | " +
                                                  this.state.currentRelation
                                                      .agences.agence
                                                : ""}
                                        </p>
                                    </div>
                                    <div
                                        className="close"
                                        onClick={this.handleSeeCloseProjet}
                                    >
                                        <svg
                                            width="40"
                                            height="40"
                                            viewBox="0 0 24 24"
                                            fill="#2f2f2f"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8.46445 15.5354L15.5355 8.46436"
                                                stroke="#2f2f2f"
                                            />
                                            <path
                                                d="M8.46446 8.46458L15.5355 15.5356"
                                                stroke="#2f2f2f"
                                            />
                                        </svg>
                                    </div>
                                </div>

                                <div
                                    id={`relationBlock`}
                                    className="relationBlock"
                                >
                                    <div className="blockLeft">
                                        <div className="linePro">
                                            <div className="img">
                                                <img
                                                    src={
                                                        this.state
                                                            .currentRelation.pro
                                                            .photo
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                            <div className="nom">
                                                {
                                                    this.state.currentRelation
                                                        .pro.nom
                                                }{" "}
                                                {
                                                    this.state.currentRelation
                                                        .pro.prenom
                                                }
                                            </div>

                                            <div className="voir">
                                                {
                                                    this.state.currentRelation.pro.card_ext !== null ?
                                                        <a
                                                            href={this.state.currentRelation.pro.card_ext}
                                                        >
                                                            <span>Voir le profil</span>
                                                            <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="#43A1DB"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path d="M8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12ZM12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75Z" />
                                                                <path d="M4.32343 10.6464C3.90431 11.2503 3.75 11.7227 3.75 12C3.75 12.2773 3.90431 12.7497 4.32343 13.3536C4.72857 13.9374 5.33078 14.5703 6.09267 15.155C7.61978 16.3271 9.71345 17.25 12 17.25C14.2865 17.25 16.3802 16.3271 17.9073 15.155C18.6692 14.5703 19.2714 13.9374 19.6766 13.3536C20.0957 12.7497 20.25 12.2773 20.25 12C20.25 11.7227 20.0957 11.2503 19.6766 10.6464C19.2714 10.0626 18.6692 9.42972 17.9073 8.84497C16.3802 7.67292 14.2865 6.75 12 6.75C9.71345 6.75 7.61978 7.67292 6.09267 8.84497C5.33078 9.42972 4.72857 10.0626 4.32343 10.6464ZM5.17941 7.65503C6.90965 6.32708 9.31598 5.25 12 5.25C14.684 5.25 17.0903 6.32708 18.8206 7.65503C19.6874 8.32028 20.4032 9.06244 20.9089 9.79115C21.4006 10.4997 21.75 11.2773 21.75 12C21.75 12.7227 21.4006 13.5003 20.9089 14.2089C20.4032 14.9376 19.6874 15.6797 18.8206 16.345C17.0903 17.6729 14.684 18.75 12 18.75C9.31598 18.75 6.90965 17.6729 5.17941 16.345C4.31262 15.6797 3.59681 14.9376 3.0911 14.2089C2.59937 13.5003 2.25 12.7227 2.25 12C2.25 11.2773 2.59937 10.4997 3.0911 9.79115C3.59681 9.06244 4.31262 8.32028 5.17941 7.65503Z" />
                                                            </svg>
                                                        </a>

                                                        :
                                                        <a
                                                            href={`/profil/pro/${this.state.proId}`}
                                                        >
                                                            <span>Voir le profil</span>
                                                            <svg
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="#43A1DB"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path d="M8.25 12C8.25 9.92893 9.92893 8.25 12 8.25C14.0711 8.25 15.75 9.92893 15.75 12C15.75 14.0711 14.0711 15.75 12 15.75C9.92893 15.75 8.25 14.0711 8.25 12ZM12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75Z" />
                                                                <path d="M4.32343 10.6464C3.90431 11.2503 3.75 11.7227 3.75 12C3.75 12.2773 3.90431 12.7497 4.32343 13.3536C4.72857 13.9374 5.33078 14.5703 6.09267 15.155C7.61978 16.3271 9.71345 17.25 12 17.25C14.2865 17.25 16.3802 16.3271 17.9073 15.155C18.6692 14.5703 19.2714 13.9374 19.6766 13.3536C20.0957 12.7497 20.25 12.2773 20.25 12C20.25 11.7227 20.0957 11.2503 19.6766 10.6464C19.2714 10.0626 18.6692 9.42972 17.9073 8.84497C16.3802 7.67292 14.2865 6.75 12 6.75C9.71345 6.75 7.61978 7.67292 6.09267 8.84497C5.33078 9.42972 4.72857 10.0626 4.32343 10.6464ZM5.17941 7.65503C6.90965 6.32708 9.31598 5.25 12 5.25C14.684 5.25 17.0903 6.32708 18.8206 7.65503C19.6874 8.32028 20.4032 9.06244 20.9089 9.79115C21.4006 10.4997 21.75 11.2773 21.75 12C21.75 12.7227 21.4006 13.5003 20.9089 14.2089C20.4032 14.9376 19.6874 15.6797 18.8206 16.345C17.0903 17.6729 14.684 18.75 12 18.75C9.31598 18.75 6.90965 17.6729 5.17941 16.345C4.31262 15.6797 3.59681 14.9376 3.0911 14.2089C2.59937 13.5003 2.25 12.7227 2.25 12C2.25 11.2773 2.59937 10.4997 3.0911 9.79115C3.59681 9.06244 4.31262 8.32028 5.17941 7.65503Z" />
                                                            </svg>
                                                        </a>

                                                }

                                            </div>
                                            </div>
                                            <div className="blockPro">
                                                <div className="status">
                                                    <h3>
                                                        Statut de la
                                                        <br /> mise en relation
                                                        :
                                                    </h3>
                                                    <p>
                                                        {this.state
                                                            .currentRelation
                                                            .status ==
                                                        "Accepter"
                                                            ? `Accepté`
                                                            : this.state
                                                                  .currentRelation
                                                                  .status ==
                                                              "Refuser"
                                                            ? `Refusé`
                                                            : this.state
                                                                  .currentRelation
                                                                  .status ==
                                                              "En attente"
                                                            ? `En attente`
                                                            : ""}
                                                    </p>
                                                </div>

                                            <div className="fonction">
                                                <h3>Fonction :</h3>
                                                {this.state.currentRelation.pro
                                                    .fonction === "Fonction" ||
                                                this.state.currentRelation.pro
                                                    .fonction === null ? (
                                                    <p>Non communiqué</p>
                                                ) : (
                                                    <p>
                                                        {
                                                            this.state
                                                                .currentRelation
                                                                .pro.fonction
                                                        }
                                                    </p>
                                                )}
                                            </div>
                                            <div className="agence">
                                                <h3>Agence :</h3>
                                                {this.state.currentRelation
                                                    .agences ? (
                                                    <p>
                                                        {
                                                            this.state
                                                                .currentRelation
                                                                .agences.agence
                                                        }
                                                    </p>
                                                ) : (
                                                    <p>Non communiqué</p>
                                                )}
                                            </div>
                                            <div className="message">
                                                <h3>Ville :</h3>
                                                <p>
                                                    {
                                                        this.state
                                                            .currentRelation
                                                            .agences.ville
                                                    }
                                                </p>
                                            </div>
                                            <div className="action">
                                                {this.state.currentRelation
                                                    .status === "Accepter" ? (
                                                    <button
                                                        className={`${
                                                            this.state
                                                                .currentRelation
                                                                .status ===
                                                            "Accepter"
                                                                ? "small"
                                                                : "normal"
                                                        }`}
                                                        onClick={() =>
                                                            this.validationRelationPro(
                                                                this.state
                                                                    .currentRelation
                                                                    .id,
                                                                "Accepter"
                                                            )
                                                        }
                                                        disabled
                                                    >
                                                        Accepter
                                                    </button>
                                                ) : (
                                                    <button
                                                        className={`${
                                                            this.state
                                                                .currentRelation
                                                                .status ===
                                                            "Accepter"
                                                                ? "small"
                                                                : "normal"
                                                        }`}
                                                        onClick={() =>
                                                            this.validationRelationPro(
                                                                this.state
                                                                    .currentRelation
                                                                    .id,
                                                                "Accepter"
                                                            )
                                                        }
                                                    >
                                                        Accepter
                                                    </button>
                                                )}
                                                <button
                                                    className={`${
                                                        this.state
                                                            .currentRelation
                                                            .status ===
                                                        "Accepter"
                                                            ? "small"
                                                            : "normal"
                                                    }`}
                                                    onClick={() =>
                                                        this.validationRelationPro(
                                                            this.state
                                                                .currentRelation
                                                                .id,
                                                            "Refuser"
                                                        )
                                                    }
                                                >
                                                    Refuser
                                                </button>
                                            </div>
                                            </div>
                                    </div>
                                    <div className="blockRight">
                                        {this.state.currentRelation.status !==
                                            "Accepter" && (
                                            <div className="firstMessage">
                                                <h3>Message</h3>
                                                {
                                                    this.state.currentRelation
                                                        .message[0].message
                                                }
                                            </div>
                                        )}

                                        <div className="right">
                                            {this.state.currentRelation
                                                .status === "Accepter" ||
                                            this.state.currentRelation
                                                .status === "En attente" ? (
                                                <>
                                                    <div className="chatBlock">
                                                        <ChatComponent
                                                            messages={
                                                                this.state
                                                                    .messages
                                                            }
                                                            pro={
                                                                this.state
                                                                    .currentRelation
                                                                    .user_pro_id
                                                            }
                                                            parti={
                                                                this.state
                                                                    .currentRelation
                                                                    .user_parti_id
                                                            }
                                                            relation={
                                                                this.state
                                                                    .currentRelation
                                                                    .id
                                                            }
                                                            user={
                                                                this.state
                                                                    .currentUserId
                                                            }
                                                            handleSeeCloseChat={this.handleSeeCloseChat}
                                                            pro={true}
                                                            type={this.state.type}
                                                        />
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* MODAL EDIT */}
                {this.state.openModalEdit && (
                    <div className="modal modalEdit">
                        <div className="backgroundModal">
                            <div className="contentModal modalRecap">
                                <div
                                    className="close"
                                    onClick={this.handleEditCloseProjet}
                                >
                                    <svg
                                        width="40"
                                        height="40"
                                        viewBox="0 0 24 24"
                                        fill="#2f2f2f"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.46445 15.5354L15.5355 8.46436"
                                            stroke="#2f2f2f"
                                        />
                                        <path
                                            d="M8.46446 8.46458L15.5355 15.5356"
                                            stroke="#2f2f2f"
                                        />
                                    </svg>
                                </div>
                                <FormProjetVenteModal
                                    caracs={this.props.caracs}
                                    delais={this.props.delais}
                                    types={this.props.types}
                                    projet={this.state.currentProjet}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {/* MODAL DELETE */}
                {this.state.openModalDelete && (
                    <div className="modal modalDelete">
                        <div className="backgroundModal">
                            <div className="contentModal">
                                <div className="message">
                                    <p>
                                        Voulez-vous vraiment supprimer le projet{" "}
                                        {this.state.currentProjet.titre}
                                    </p>
                                </div>
                                <div className="action">
                                    <a
                                        href=""
                                        onClick={(e) =>
                                            this.handleDeleteCloseProjet(e)
                                        }
                                    >
                                        Annuler
                                    </a>
                                    <a
                                        href=""
                                        onClick={(e) =>
                                            this.handleDeleteProjetBdd(e)
                                        }
                                    >
                                        Supprimer
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* MODAL ADD */}
                {this.state.openModalAdd && (
                    <div className="modal modalEdit">
                        <div className="contentModal modalRecap">
                            <div
                                className="close"
                                onClick={this.handleAddCloseProjet}
                            >
                                <svg
                                    width="40"
                                    height="40"
                                    viewBox="0 0 24 24"
                                    fill="#2f2f2f"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.46445 15.5354L15.5355 8.46436"
                                        stroke="#2f2f2f"
                                    />
                                    <path
                                        d="M8.46446 8.46458L15.5355 15.5356"
                                        stroke="#2f2f2f"
                                    />
                                </svg>
                            </div>
                            <FormProjetVenteModal
                                caracs={this.props.caracs}
                                delais={this.props.delais}
                                types={this.props.types}
                            />
                        </div>
                    </div>
                )}
                {/* MODAL Flash MESSAGE */}
                {this.state.openMessageFlash && (
                    <div className="modal modalDelete">
                        <div className="backgroundModal">
                            <div className="contentModal">
                                <div className="message">
                                    <p>{this.state.messageFlash}</p>
                                </div>
                                <div className="action blue">
                                    <a
                                        href=""
                                        onClick={(e) =>
                                            this.handleFlashCloseProjet(e)
                                        }
                                    >
                                        OK
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
