import React from "react";
import ReactDOM from "react-dom";
import ConsulteAchatPro from "./ConsulteAchatPro";
import ConsulteVentePro from "./ConsulteVentePro";

export default class ConsultePro extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            config:JSON.parse(this.props.config),
            abos:JSON.parse(this.props.abos),
            pro:JSON.parse(this.props.pro),
            delais: JSON.parse(this.props.delais),
            types: JSON.parse(this.props.types),
            caracs: JSON.parse(this.props.caracs),
            regions: JSON.parse(this.props.regions),
            listeAlerte: this.props.alerts !== "[]" ? JSON.parse(this.props.alerts) : [],
            listeAlerteFull: this.props.alerts !== "[]" ? JSON.parse(this.props.alerts) : [],
            listeAlertAchat: this.props.alertsachat !== "[]" ? JSON.parse(this.props.alertsachat) : [],
            listeAlertAchatFull: this.props.alertsachat !== "[]" ? JSON.parse(this.props.alertsachat) : [],
            achatVente:null
        }
        this.handleSelect = this.handleSelect.bind(this);
    }
    componentDidMount() {
        let url = window.location.href;
        url = url.split("?t=");
        if(url[1]){
            this.setState({achatVente:url[1]});
        }
    }

    handleSelect(value){
        this.setState({achatVente:value});
    }

    render(){
        return(
            <>
            {
                this.state.config.habitat === 1 || this.state.config.entreprise === 1 ?
                    <div className="form newform">
                        <div className="block">
                            <div className={this.state.achatVente !== null ? this.state.achatVente === "vente" ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}onClick={() => this.handleSelect("vente")}>Vente</div>
                            <div className={this.state.achatVente !== null ? this.state.achatVente === "achat" ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}onClick={() => this.handleSelect("achat")}>Recherche de bien</div>
                        </div>
                    </div>
                    :
                    <ConsulteVentePro
                        pro={this.state.pro}
                        delais={this.state.delais}
                        types={this.state.types}
                        caracs={this.state.caracs}
                        regions={this.state.regions}
                        listeAlerte={this.state.listeAlerte}
                        listeAlerteFull={this.state.listeAlerteFull}
                        abos={this.state.abos}
                        config={this.state.config}
                    />
            }
            {
                this.state.achatVente !== null ?
                this.state.achatVente === "vente" ?
                    <ConsulteVentePro
                        pro={this.state.pro}
                        delais={this.state.delais}
                        types={this.state.types}
                        caracs={this.state.caracs}
                        regions={this.state.regions}
                        listeAlerte={this.state.listeAlerte}
                        listeAlerteFull={this.state.listeAlerteFull}
                        abos={this.state.abos}
                        config={this.state.config}
                    />
                :
                    <ConsulteAchatPro
                        pro={this.state.pro}
                        delais={this.state.delais}
                        types={this.state.types}
                        caracs={this.state.caracs}
                        regions={this.state.regions}
                        listeAlerte={this.state.listeAlertAchat}
                        listeAlerteFull={this.state.listeAlertAchatFull}
                        config={this.state.config}
                    />
                    :null
            }
            </>
        )
    }
}
if (document.getElementById('consulteventepro')) {
    const element = document.getElementById('consulteventepro');
    let props = Object.assign({}, element.dataset);
    ReactDOM.render(<ConsultePro {...props}/>, document.getElementById('consulteventepro'));
}
