import React from "react";
import ReactDOM from 'react-dom';

import BlockProjetsPartiVente from "./BlockProjetsPartiVente";
import BlockProjetsPartiAchat from "./BlockProjetsPartiAchat";

export default class BlockProjetsParti extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projetsventes: JSON.parse(this.props.projetsventes),
            projetsachats: JSON.parse(this.props.projetsachats),
            config:JSON.parse(this.props.config),
            achatVente:"vente",
            user:this.props.user
        }
        this.handleSelect = this.handleSelect.bind(this);
    }
    async componentDidMount() {
        let url = window.location.href;
        url = url.split("?");
        if (url[1]) {
            url = url[1].split("&");
            let type = null;
            await url.map(v => {
                let newParam = v.split("=");
                if (newParam[0] == "type") {
                    type = newParam[1];
                }
            });
            this.setState({achatVente:type});
        }
    }

    handleSelect(value){
        this.setState({achatVente:value});
    }

    render() {
        return (
            <>
            {
                this.state.config.habitat === 1 || this.state.config.entreprise === 1 ?
                <div className="form newform">
                    <div className="block">
                        <div className={this.state.achatVente !== null ? this.state.achatVente === "vente" ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}onClick={() => this.handleSelect("vente")}>Vente de bien</div>
                        <div className={this.state.achatVente !== null ? this.state.achatVente === "achat" ? "selected btn formbtn" : "btn formbtn" : "btn formbtn"}onClick={() => this.handleSelect("achat")}>Recherche de bien</div>
                    </div>
                </div>
                :
                    <BlockProjetsPartiVente
                    projets={this.state.projetsventes}
                    type="vente"
                    caracs={this.props.caracs}
                    delais={this.props.delais}
                    types={this.props.types}
                    user={this.state.user}
                    />
            }
            {
                this.state.achatVente !== null ?
                this.state.achatVente === "vente" ?
                <BlockProjetsPartiVente
                    projets={this.state.projetsventes}
                    type="vente"
                    caracs={this.props.caracs}
                    delais={this.props.delais}
                    types={this.props.types}
                    user={this.state.user}
                    />
                    :
                    <BlockProjetsPartiAchat
                    projets={this.state.projetsachats}
                    type="achat"
                    config={this.state.config}
                    caracs={this.props.caracs}
                    delais={this.props.delais}
                    types={this.props.types}
                    travaux={this.props.travaux}
                    user={this.state.user}
                />
                :null
            }
            </>
        )
    }
}
if (document.getElementById('blockProjetsParti')) {
    const element = document.getElementById('blockProjetsParti');
    let props = Object.assign({}, element.dataset);
    ReactDOM.render(<BlockProjetsParti {...props}/>, document.getElementById('blockProjetsParti'));
}
