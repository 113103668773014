import React from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';

export default class Formcontactinde extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            user: this.props.user ? JSON.parse(this.props.user):null,
            nom:"",
            prenom:"",
            phone:"",
            email:"",
        }
    }
    componentDidMount(){

    }
    handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/save-contact-inde',{
            lastname:this.state.nom,
            firstname:this.state.prenom,
            phone:this.state.phone,
            email:this.state.email,
            userEmail:this.state.user !== null ? this.state.user.email : null ,
        }).then(res => {
            location.reload();
        }).catch(error => {
            console.log(error);
        })
    }
    handleClose(){
        let form = document.querySelector('#formcontactinde');
        form.style.display = "none";
    }

    render(){
        return(
            <form onSubmit={(e) => this.handleSubmit(e)}>
                <div className="close" onClick={this.handleClose}>
                    <p>X</p>
                </div>
                <div className='block'>
                    <input type="text" name="prenom" required placeholder='Prénom*' onChange={(e) => this.setState({prenom:e.target.value})} value={this.state.prenom} />
                    <input type="text" name="nom" required placeholder='Nom*' onChange={(e) => this.setState({nom:e.target.value})} value={this.state.nom} />
                    <input type="text" name="phone" required placeholder='Téléphone*' onChange={(e) => this.setState({phone:e.target.value})} value={this.state.phone} />
                    <input type="email" name="email" required placeholder='E-mail*' onChange={(e) => this.setState({email:e.target.value})} value={this.state.email} />
                </div>
                <input type="submit" value="Valider"/>
            </form>
        )
    }
}

if (document.getElementById('formcontactinde')) {
    const element = document.getElementById('formcontactinde');
    let props = Object.assign({}, element.dataset);
    ReactDOM.render(<Formcontactinde {...props}/>, document.getElementById('formcontactinde'));
}
